import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import { IconCopyButton, Input, InputGroup } from '@jeeves/new-components'

import { useAuth } from '@jeeves/components/Auth'
import { ConnectionTypesTabs } from '../../../../components'
import ConnectionFields, {
  Host,
  Port,
  Username,
  Password,
  Database,
  INPUT_ADDON_WIDTH,
} from '../../ConnectionInfoFields'

import getConnectionTypes from './connectionTypes'
import { getUsername, getHost, getPorts } from '../../../../utils'

const MongoAccordionContent = ({
  opaqueToken,
  repo,
  accessPortalSidecar,
  accessPortalSidecarBinding,
  userAccountEdge,
}) => {
  const [database, setDatabase] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const { user } = useAuth()

  const connectionInfo = {
    username: getUsername(user, repo, userAccountEdge),
    host: getHost(accessPortalSidecar),
    ports: getPorts(accessPortalSidecarBinding),
    password: opaqueToken,
    database,
    ...(repo?.__typename === 'MongoDBReplicaSetRepo' && {
      replicaSetName: repo.replicaSetName,
    }),
  }

  const connectionTypes = getConnectionTypes(connectionInfo, {
    showPassword,
  })

  return (
    <Stack spacing={3}>
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)">
        <Stack spacing={3}>
          <Stack spacing={0.5}>
            <Typography
              variant="h6"
              sx={{
                color: 'text.secondary',
              }}
            >
              Connection Info
            </Typography>

            <ConnectionFields connectionInfo={connectionInfo}>
              <Username />
              <Password showPassword={showPassword} setShowPassword={setShowPassword} />
              <Host />
              <Port />
              <Database setDatabase={setDatabase} />
            </ConnectionFields>
          </Stack>

          {connectionInfo.replicaSetName && (
            <Stack spacing={0.5}>
              <Typography
                variant="h6"
                sx={{
                  color: 'text.secondary',
                }}
              >
                Replica Set Info
              </Typography>

              <Stack spacing={0.5}>
                <ReplicaSetName name={connectionInfo.replicaSetName} />
              </Stack>
            </Stack>
          )}
        </Stack>
      </Box>

      <ConnectionTypesTabs connectionTypes={connectionTypes} />
    </Stack>
  )
}

const ReplicaSetName = ({ name }) => {
  return (
    <InputGroup>
      <InputGroup.Text
        sx={{
          minWidth: INPUT_ADDON_WIDTH,
        }}
      >
        Name
      </InputGroup.Text>
      <Input
        defaultValue={name}
        endAdornment={
          <InputAdornment position="end" sx={{ marginLeft: 0 }}>
            <IconCopyButton text={name} />
          </InputAdornment>
        }
        sx={{
          typography: 'code',
        }}
        fullWidth
        readOnly
      />
    </InputGroup>
  )
}

export default MongoAccordionContent
