import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Menu, MenuItem } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { v5Theme } from '@jeeves/theme'
import { Button } from '@jeeves/new-components'
import EditDialog from '@jeeves/pages/Wrappers/components/Edit'
import useWrappers from '@jeeves/pages/Wrappers/hooks/useWrappers'

import { CloneDialog } from '../CloneDialog'
import { SidecarDownloadDialog } from '../SidecarDownloadDialog'

const Actions = ({ showCloneDownload, wrapper, fetchData }) => {
  const { id } = useParams()
  const [menuEl, setMenuEl] = useState(null)
  const menuOpen = Boolean(menuEl)
  const [cloneDialogOpen, setCloneDialogOpen] = useState(false)
  const [sidecarDownloadDialogOpen, setSidecarDownloadDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [saving, setSaving] = useState(false)

  const { updateWrapper } = useWrappers()

  const handleClickMenu = e => {
    setMenuEl(e.currentTarget)
  }
  const handleCloseMenu = e => {
    setMenuEl(null)
  }

  const handleClickClone = () => {
    setCloneDialogOpen(true)
    handleCloseMenu()
  }

  const handleClickEdit = () => {
    setEditDialogOpen(true)
    handleCloseMenu()
  }

  const handleClickUpdateSidecar = () => {
    setSidecarDownloadDialogOpen(true)
    handleCloseMenu()
  }

  const updateSidecar = async ({ sidecarStatus, tags, userEndpoint }) => {
    setSaving(true)
    try {
      await updateWrapper(id, { sidecarStatus, tags, userEndpoint })
      setEditDialogOpen(false)
      fetchData()
    } catch (error) {
      console.log({ error })
    } finally {
      setSaving(false)
    }
  }

  return (
    <>
      <CloneDialog
        open={cloneDialogOpen}
        handleClose={() => setCloneDialogOpen(false)}
        wrapper={wrapper}
        onSave={fetchData}
      />

      <SidecarDownloadDialog
        open={sidecarDownloadDialogOpen}
        handleClose={() => setSidecarDownloadDialogOpen(false)}
        wrapper={wrapper}
        onSave={fetchData}
      />
      <EditDialog
        wrapper={wrapper}
        open={editDialogOpen}
        onSave={updateSidecar}
        onCancel={() => setEditDialogOpen(false)}
        loading={saving}
      />
      <ThemeProvider theme={v5Theme}>
        <Button
          color="secondary"
          endIcon={<KeyboardArrowDownIcon />}
          onClick={handleClickMenu}
          variant="outlined"
        >
          Actions
        </Button>
        <Menu
          open={menuOpen}
          anchorEl={menuEl}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {showCloneDownload && (
            <>
              <MenuItem onClick={handleClickClone}>Clone</MenuItem>
              <MenuItem onClick={handleClickUpdateSidecar}>Update</MenuItem>
            </>
          )}
          <MenuItem onClick={handleClickEdit}>Edit</MenuItem>
        </Menu>
      </ThemeProvider>
    </>
  )
}

export default Actions
