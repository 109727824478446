import React from 'react'
import { gql, useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Stack, Box, DialogContent, DialogTitle, Typography } from '@mui/material'
import CloseIcon from '@material-ui/icons/Close'

import { Dialog, Button, Input, DialogActions, IconButton, useToast } from '@jeeves/new-components'
import { WarningAlert } from '@jeeves/pages/RepositoryDetail/Tabs/NetworkShield/components'

import { useBindingsTableContext, sidecarBindingsConnectionFragment } from '../BindingsTableContext'

import { useRepositoriesContext } from '../../../../contexts/RepositoriesContext'

import { useBindingsTable } from '../useBindingsTable'
import { getGraphQLErrorMessage } from '@jeeves/pages/WrapperDetail/tabs/Repositories/utils'

const UNBIND_REPO_FROM_SIDECAR = gql`
  mutation unbindRepoFromSidecar(
    $sidecarId: ID!
    $bindingId: ID!
    $first: Int
    $after: String
    $filters: BindingsFilterInput
  ) {
    unbindRepoFromSidecar(sidecarId: $sidecarId, bindingId: $bindingId) {
      deletedBindingId
      sidecar {
        id
        bindings(first: $first, after: $after, filters: $filters) {
          ...sidecarBindingsConnectionFields
        }
      }
    }
  }
  ${sidecarBindingsConnectionFragment}
`

const UnbindRepoFromSidecarModal = ({ open, binding, closeModal }) => {
  const { id: sidecarId } = useParams()
  const { pageSize, currentPageEdges, filter, previousPageEndCursorStack, paginate } =
    useBindingsTableContext()
  const { refetch } = useRepositoriesContext()
  const { getPreviousPage } = useBindingsTable()
  const { toast } = useToast()
  const [unbindRepoFromSidecar, { loading }] = useMutation(UNBIND_REPO_FROM_SIDECAR, {
    // Figure out how to do below after onCompleted
    // update(cache, { data: { unbindRepoFromSidecar } }) {
    //   const normalizedId = cache.identify({
    //     id: unbindRepoFromSidecar?.deletedBindingId,
    //     __typename: binding.__typename,
    //   })

    //   cache.evict({ id: normalizedId })
    //   cache.gc()
    // },
    onError: error => {
      toast({
        variant: 'error',
        description: getGraphQLErrorMessage(error) || 'Failed to unbind repo from sidecar',
      })
      console.error(error)
    },
    onCompleted: async () => {
      refetch()
      // If the last item on the last page is deleted, we need to go back a page (unless we're on the first page)
      if (currentPageEdges.length === 0) {
        getPreviousPage()
      } else {
        paginate({ refetchCurrent: true })
      }
      handleClose()
    },
  })
  const { register, handleSubmit, watch, reset } = useForm({
    defaultValues: {
      confirmation: '',
    },
  })

  const handleClose = React.useCallback(() => {
    closeModal()
    reset()
  }, [closeModal, reset])

  const onSubmit = async () => {
    const previousPageEndCursor = previousPageEndCursorStack[previousPageEndCursorStack.length - 1]

    await unbindRepoFromSidecar({
      variables: {
        bindingId: binding?.id,
        sidecarId,
        first: pageSize,
        ...(previousPageEndCursor && { after: previousPageEndCursor }),
        filters: filter,
      },
    })
  }

  const confirmation = watch('confirmation')

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            Delete Binding
          </Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Stack spacing={2}>
            <WarningAlert>
              Deleting this binding will terminate existing client connections.
            </WarningAlert>
            <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
              Are you sure you want to delete the binding for the{' '}
              <Typography component="span" sx={{ color: 'primary.main' }} variant="h6">
                {binding?.repo?.name}
              </Typography>{' '}
              repo?
            </Typography>
            <Stack spacing={1}>
              <Typography sx={{ color: 'text.secondary' }} variant="h6">
                Delete confirmation
              </Typography>
              <Input inputProps={{ ...register('confirmation') }}></Input>
              <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
                Please type{' '}
                <Typography
                  component="span"
                  sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                  variant="body2"
                >
                  Delete
                </Typography>{' '}
                to confirm you want to delete this binding.
              </Typography>{' '}
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              loading={loading}
              variant="outlined"
              type="submit"
              disabled={confirmation.trim() !== 'DELETE'}
              color="error"
            >
              Delete
            </Button>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default UnbindRepoFromSidecarModal
