import React from 'react'
import { useQuery, gql } from '@apollo/client'
import { compare } from 'compare-versions'
import semver from 'semver'

import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'

export const SNAPSHOT = gql`
  query Snapshot {
    repos {
      id
    }
    datalabels {
      name
    }
    globalYAMLPolicies {
      id
    }
  }
`

export const SETUP = gql`
  query Setup {
    sidecars {
      id
    }
    repos {
      id
      type
      ... on RepoWithServiceAccountsField {
        serviceAccounts {
          id
        }
      }
    }
    identityProviders {
      name
      type
    }
    duoIntegrations {
      id
    }
    slackNotificationIntegrations {
      id
      name
    }
  }
`

export const NOTIFICATIONS = gql`
  query Notifications {
    datamapRecommendations {
      id
      repo {
        id
        name
      }
    }
    sidecars {
      id
      name
      activeInstances {
        id
        version
      }
      health
    }
  }
`
export const ACTIVITY = gql`
  query ActivityQuery($lastNumDays: Int!, $lastNumDays2: Int!) {
    numSSOSessions7d: numTotalSSOSessions(lastNumDays: $lastNumDays)
    numSSOSessions14d: numTotalSSOSessions(lastNumDays: $lastNumDays2)
    numQueries7d: numTotalQueries(lastNumDays: $lastNumDays)
    numQueries14d: numTotalQueries(lastNumDays: $lastNumDays2)
    numSSOUsers7d: numTotalSSOUsers(lastNumDays: $lastNumDays)
    numSSOUsers14d: numTotalSSOUsers(lastNumDays: $lastNumDays2)
    numAppsAndBIToolsUsers7d: numTotalAppsAndBIToolsUsers(lastNumDays: $lastNumDays)
    numAppsAndBIToolsUsers14d: numTotalAppsAndBIToolsUsers(lastNumDays: $lastNumDays2)
  }
`

const transformActivityData = activityData => {
  const numSSOSessionsPrecedingWeek =
    activityData?.numSSOSessions14d - activityData?.numSSOSessions7d
  const numQueriesPrecedingWeek = activityData?.numQueries14d - activityData?.numQueries7d
  const numSSOUsersPrecedingWeek = activityData?.numSSOUsers14d - activityData?.numSSOUsers7d
  const numAppsAndBIToolsUsersPrecedingWeek =
    activityData?.numAppsAndBIToolsUsers14d - activityData?.numAppsAndBIToolsUsers7d

  return {
    numSSOSessions: activityData?.numSSOSessions7d,
    numSSOSessionsDelta: activityData?.numSSOSessions7d - numSSOSessionsPrecedingWeek,
    numQueries: activityData?.numQueries7d,
    numQueriesDelta: activityData?.numQueries7d - numQueriesPrecedingWeek,
    numSSOUsers: activityData?.numSSOUsers7d,
    numSSOUsersDelta: activityData?.numSSOUsers7d - numSSOUsersPrecedingWeek,
    numAppsAndBIToolsUsers: activityData?.numAppsAndBIToolsUsers7d,
    numAppsAndBIToolsUsersDelta:
      activityData?.numAppsAndBIToolsUsers7d - numAppsAndBIToolsUsersPrecedingWeek,
  }
}

const transformNotificationsData = notificationsData => {
  const dataMapRecommendations = notificationsData?.datamapRecommendations
    .filter(recommendation => recommendation?.repo)
    .map(recommendation => recommendation?.repo)

  const getSidecarsNeedUpdates = () => {
    const isSidecarOutdated = activeInstances => {
      const isOutdated = activeInstance => {
        const cleanedActiveInstanceVersion = semver.valid(semver.coerce(activeInstance?.version))
        const cleanedLatestSidecarVersion = semver.valid(
          semver.coerce(window._env_.template_git_reference)
        )
        if (!cleanedActiveInstanceVersion || !cleanedLatestSidecarVersion) return false
        return !compare(cleanedActiveInstanceVersion, cleanedLatestSidecarVersion, '=')
      }
      return activeInstances.some(isOutdated)
    }

    return notificationsData?.sidecars?.filter(sidecar =>
      isSidecarOutdated(sidecar.activeInstances)
    )
  }

  const sidecarsNeedAttention = notificationsData?.sidecars?.filter(
    sidecar => sidecar.health !== 'online'
  )

  return {
    dataMapRecommendations,
    sidecarsNeedUpdates: getSidecarsNeedUpdates(),
    sidecarsNeedAttention,
  }
}

const transformSetupData = setupData => {
  const { sidecars, repos, identityProviders, duoIntegrations, slackNotificationIntegrations } =
    setupData

  const uniqueRepoTypes = [...new Set(repos?.map(repo => repo.type))]

  const isIdpConfigured = identityProviders.some(idp => idp.type !== 'cyral')

  const isApprovalsConfigured = slackNotificationIntegrations?.some(slackIntegration =>
    slackIntegration.name.startsWith('Access Requests:')
  )

  const isServiceAccountResolutionConfigured = repos?.some(
    repo => repo?.serviceAccounts?.length > 0
  )

  return {
    numSidecars: sidecars?.length,
    repoTypes: uniqueRepoTypes,
    idpConfigured: isIdpConfigured,
    mfaConfigured: duoIntegrations?.length > 0,
    approvalsConfigured: isApprovalsConfigured,
    serviceAccountsConfigured: isServiceAccountResolutionConfigured,
  }
}

const transformSnapshotData = snapshotData => {
  return {
    datalabels: snapshotData?.datalabels?.length,
    globalYAMLPolicies: snapshotData?.globalYAMLPolicies?.length,
    repos: snapshotData?.repos?.length,
  }
}

const useOverview = () => {
  const [updatesContent, setUpdatesContent] = React.useState(null)
  const { data: snapshotData, loading: snapshotLoading, error: snapshotError } = useQuery(SNAPSHOT)
  const { data: setupData, loading: setupLoading, error: setupError } = useQuery(SETUP)
  const {
    data: notificationsData,
    loading: notificationsLoading,
    error: notificationsError,
  } = useQuery(NOTIFICATIONS)

  const {
    data: activityData,
    loading: activityLoading,
    error: activityError,
  } = useQuery(ACTIVITY, {
    variables: {
      lastNumDays: 7,
      lastNumDays2: 14,
    },
  })

  const { getTokenSilently, user } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  React.useEffect(() => {
    const fetchUpdatesContent = async () => {
      try {
        const resp = await ec
          .get('/templates/_updatesContent')
          .then(res => res.data)
          .then(data => data.updates)
        setUpdatesContent(resp)
      } catch (e) {
        console.log('/templates/_updatesContent failed 1', e)
      }
    }
    fetchUpdatesContent()
  }, [])

  return {
    snapshot: {
      data: transformSnapshotData(snapshotData),
      loading: snapshotLoading,
    },
    setup: {
      data: setupData && transformSetupData(setupData),
      loading: setupLoading,
    },
    notifications: {
      data: transformNotificationsData(notificationsData),
      loading: notificationsLoading,
    },
    whatsNew: {
      data: updatesContent,
    },
    activity: {
      data: transformActivityData(activityData),
      loading: activityLoading,
    },
  }
}

export default useOverview
