import React from 'react'
import { Stack, Box, Typography, Divider } from '@mui/material'
import { SidecarIcon } from '@jeeves/components/Navigation/components/SidecarIcon'
import MapIcon from '@mui/icons-material/Map'
import MuiLink from '@mui/material/Link'
import { Link } from 'react-router-dom'

const NotificationItem = ({ title, data, icon }) => {
  return (
    <Stack>
      <Stack
        sx={{
          py: 4,
          ':hover': {
            bgcolor: 'cyralColors.grey.100',
          },
        }}
      >
        <Stack direction="row" sx={{ alignItems: 'center', pl: 4 }}>
          <Stack direction="row" spacing={1} sx={{ flex: 1, alignItems: 'center' }}>
            {icon}
            <Typography variant="body2" sx={{ color: 'text.primary' }}>
              {title}
            </Typography>
          </Stack>
          <Box sx={{ flex: 1 }}>
            <Typography variant="body2" sx={{ color: 'text.primary' }}>
              {data}
            </Typography>
          </Box>
        </Stack>
      </Stack>
      <Divider />
    </Stack>
  )
}

export const Notifications = ({ queryResponse }) => {
  const { data, loading } = queryResponse

  return (
    <Stack sx={{ maxHeight: '500px', overflow: 'auto' }}>
      <Stack spacing={2}>
        <Typography variant="h4" sx={{ color: 'text.primary' }}>
          Notifications
        </Typography>
        <Stack>
          <Stack
            direction="row"
            sx={{
              bgcolor: 'cyralColors.grey.100',
              borderColor: 'cyralColors.grey.200',
              borderWidth: '1px',
              borderRadius: '4px',
              borderStyle: 'solid',
              color: 'text.secondary',
              px: 4,
              py: 1.25,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography variant="h5">Notification</Typography>
            </Box>
            <Box sx={{ flex: 1, pl: 4 }}>
              <Typography variant="h5">Artifact</Typography>
            </Box>
          </Stack>

          {data?.sidecarsNeedUpdates?.map(sidecar => (
            <MuiLink component={Link} to={`/sidecars/${sidecar.id}`} underline="none">
              <NotificationItem
                title="Sidecar needs an update"
                data={sidecar.name}
                icon={<SidecarIcon style={{ color: '#31B237' }} />}
              />
            </MuiLink>
          ))}

          {data?.sidecarsNeedAttention?.map(sidecar => (
            <MuiLink component={Link} to={`/sidecars/${sidecar.id}`} underline="none">
              <NotificationItem
                title="Sidecar needs attention"
                data={sidecar.name}
                icon={<SidecarIcon style={{ color: '#F7C948' }} />}
              />
            </MuiLink>
          ))}

          {data?.dataMapRecommendations?.map(repo => (
            <MuiLink component={Link} to={`/repositories/${repo.id}/datamap`} underline="none">
              <NotificationItem
                title="New data map recommendations"
                data={repo.name}
                icon={<MapIcon color="primary" />}
              />
            </MuiLink>
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}
