import { Stack } from '@mui/material'

import PolicyRule from '../../../../PolicyRule'
import { useReadLimitVariation } from '../useReadLimitVariation'

const Variation1 = () => {
  const { action, rows, repoName, dataLabels } = useReadLimitVariation()

  const renderLastRule = () => {
    const hasDataLabels = dataLabels.length > 0
    if (hasDataLabels) {
      return <PolicyRule title="for any data labeled as" content={dataLabels} />
    }
    return <PolicyRule title="for the entire repository" content={repoName} />
  }

  return (
    <Stack spacing={1}>
      <PolicyRule title="This policy will" content={action} />
      <PolicyRule title="if the number of rows read per query exceed" content={rows} />
      {renderLastRule()}
    </Stack>
  )
}

export default Variation1
