/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useEffect, useState } from 'react'

import {
  IntegrationDialogContent,
  IntegrationList,
  IntegrationLoading,
  ZeroState,
} from '../../primitives'
import { useAuth } from '@jeeves/hooks'
import logo from './assets/adfs.png'

import Integration from './components/Integration'
import AddForm from './create/AddForm'
import useADFS from './hooks/useADFS'

const ADFS = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isCreatingIntegration, setIsCreatingIntegration] = useState(false)
  const { updateIntegrations, integrations, addIntegration, putIntegration, deleteIntegration } =
    useADFS()
  const { hasPermission } = useAuth()

  const fetchData = async () => {
    setIsLoading(true)
    await updateIntegrations()
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <IntegrationDialogContent>
      <IntegrationList>
        {isLoading && <IntegrationLoading />}
        {!isLoading && !isCreatingIntegration && integrations.length === 0 && <ZeroState />}
        {!isLoading &&
          integrations.length > 0 &&
          integrations.map(integration => (
            <Integration
              key={integration.id}
              integration={integration}
              putIntegration={putIntegration}
              deleteIntegration={deleteIntegration}
            />
          ))}
        {isCreatingIntegration && (
          <AddForm
            addIntegration={addIntegration}
            handleRemoveAddField={() => setIsCreatingIntegration(false)}
          />
        )}
      </IntegrationList>
    </IntegrationDialogContent>
  )
}

ADFS.metadata = {
  name: 'ADFS',
  summary: 'Leverage ADFS for identity management.',
  enabled: true,
  category: 'IDENTITY PROVIDER',
  slug: 'adfs',
  logo,
  isDeprecated: true,
}

export default ADFS
