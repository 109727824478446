import { InMemoryCache } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'

import generatedPossibleTypes from '@jeeves/graphql/fragment-matches'

export const cache = new InMemoryCache({
  possibleTypes: {
    ...generatedPossibleTypes.possibleTypes,
    DiscoveredAccount: [
      'NormalDiscoveredAccount',
      'OracleDiscoveredAccount',
      'SQLServerUserDiscoveredAccount',
      'SQLServerLoginDiscoveredAccount',
    ],
  },
  typePolicies: {
    ControlPlaneUser: {
      fields: {
        repos: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: ['accessibility'],

          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing = { edges: [] }, incoming, { args, readField }) {
            if (!args.after) {
              return incoming
            } else {
              let edgeDict =
                existing?.edges.reduce(
                  (prevObj, curEdge) => ({
                    ...prevObj,
                    [readField('id', curEdge.node)]: curEdge,
                  }),
                  {}
                ) || {}
              if (incoming?.edges) {
                incoming.edges.forEach(edge => (edgeDict[readField('id', edge.node)] = edge))
              }

              return { ...incoming, edges: Object.values(edgeDict) }
            }
          },
        },
      },
    },
    Query: {
      fields: {
        reposWithUtilities: {
          keyArgs: ['filters'],
          merge(existing = { edges: [] }, incoming, { args, readField }) {
            return incoming
          },
        },
      },
    },
    PostgreSQLRepo: {
      fields: {
        approvals: relayStylePagination(['filter']),
      },
    },
    MongoDBRepo: {
      fields: {
        approvals: relayStylePagination(['filter']),
      },
    },
    NormalRepo: {
      fields: {
        approvals: relayStylePagination(['filter']),
      },
    },
    OracleRepo: {
      fields: {
        approvals: relayStylePagination(['filter']),
      },
    },
    RedshiftRepo: {
      fields: {
        approvals: relayStylePagination(['filter']),
      },
    },
    SQLServerRepo: {
      fields: {
        approvals: relayStylePagination(['filter']),
      },
    },
    DenodoRepo: {
      fields: {
        approvals: relayStylePagination(['filter']),
      },
    },
    Repo: {
      fields: {
        enhancedAuditLogs: relayStylePagination(['filter']),
      },
    },
    Sidecar: {
      fields: {
        bindings: relayStylePagination(['filters']),
      },
    },
  },
})
