/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useState, useEffect, useRef, Fragment } from 'react'
import {
  withStyles,
  Tab,
  Tabs,
  Switch,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  MenuItem,
  Checkbox,
  Typography,
} from '@material-ui/core'
import { useAuth } from '@jeeves/hooks'
import ExpressClient from '@jeeves/clients/express'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import { DockerGenContents } from '@jeeves/pages/Wrappers/Instructions/components/DockerGen'
import { CloudFormGenContents } from '@jeeves/pages/Wrappers/Instructions/components/CloudFormGen'
import { Helm3GenContents } from '@jeeves/pages/Wrappers/Instructions/components/Helm3Gen'
import { HelmGenContents } from '@jeeves/pages/Wrappers/Instructions/components/HelmGen'
import { BinariesGenContents } from '@jeeves/pages/Wrappers/Instructions/components/BinariesGen'
import { SingleContainerGenContents } from '@jeeves/pages/Wrappers/Instructions/components/SingleContainerGen'
import { TerraformGenContents } from '@jeeves/pages/Wrappers/Instructions/components/TerraformGen'
import { CustomGenContents } from '@jeeves/pages/Wrappers/Instructions/components/CustomGen'
import { ExpressInstallerGenContents } from '@jeeves/pages/Wrappers/Instructions/components/ExpressInstallerGen'
import { cloneDownloadDeploymentArticlesThatExist } from '@jeeves/pages/WrapperDetail/WrapperDetail.js'

import { useAccounts } from '@jeeves/pages/WrapperDetail/components/Accounts/useAccounts'

import * as lodash from 'lodash'

const styles = theme => ({
  formControl: {
    margin: '12px 0',
  },
  first: {
    marginTop: '0',
  },
  last: {
    marginBottom: '0',
  },
  formControlInput: {
    marginTop: '12px',
  },
  descriptionFormLabel: {
    fontSize: '1em',
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  deleteColor: {
    color: theme.palette.error.main,
  },
})

// TO DO: combine this somehow with CloneDialog

export const SidecarDownloadDialog = withStyles(styles)(
  ({ open, handleClose: onClose, wrapper, onSave }) => {
    const { id: sidecarId, name: sidecarName, repos, sidecarAccounts } = wrapper
    const { createdOnComparator } = useAccounts(sidecarId)
    const { deploymentMethod: propertiesDeploymentMethod, ...sidecarProperties } = {
      ...wrapper.properties,
    }
    const [deploymentMethod, setDeploymentMethod] = useState(propertiesDeploymentMethod)
    const { getTokenSilently } = useAuth()
    const ec = new ExpressClient(getTokenSilently)
    const { showError } = usePopup()
    const [logsOptions, setLogsOptions] = useState([])
    const [metricsOptions, setMetricsOptions] = useState([])
    const [newDeploymentMethod, setNewDeploymentMethod] = useState('')
    const [saveProperties, setSaveProperties] = useState(true)
    const [finished, setFinished] = useState(false)

    const handleClose = () => {
      onClose()
    }

    const isValidDeploymentMethod = deploymentMethod => deploymentMethod in DEPLOYMENT_METHODS

    const DEPLOYMENT_METHODS = lodash.pick(
      {
        'docker-compose': {
          label: 'Deploy a sidecar using Docker Compose',
          component: (
            <DockerGenContents
              newTemplate={!deploymentMethod}
              saveProperties={saveProperties}
              setFinished={setFinished}
              onSave={onSave}
              alreadyCreated
              open={open}
              onClose={handleClose}
              logsOptions={[
                ...logsOptions,
                {
                  id: 'default',
                  label: 'None',
                  type: 'docker_default',
                  value: 'docker_default',
                },
              ]}
              metricsOptions={metricsOptions}
              sidecarName={sidecarName}
              sidecarId={sidecarId}
              repos={repos}
              {...sidecarProperties}
            ></DockerGenContents>
          ),
        },
        'cft-ec2': {
          label: 'Deploy a sidecar to AWS EC2 using CloudFormation',
          component: (
            <CloudFormGenContents
              newTemplate={!deploymentMethod}
              saveProperties={saveProperties}
              setFinished={setFinished}
              onSave={onSave}
              alreadyCreated
              open={open}
              onClose={handleClose}
              logsOptions={[
                ...logsOptions,
                {
                  id: 'cloudwatch',
                  label: 'CloudWatch',
                  value: 'cloudwatch',
                  type: 'cloudwatch',
                },
              ]}
              metricsOptions={metricsOptions}
              sidecarName={sidecarName}
              sidecarId={sidecarId}
              repos={repos}
              {...sidecarProperties}
            ></CloudFormGenContents>
          ),
        },
        'tf-aws-ec2': {
          label: 'Deploy a sidecar to AWS EC2 using Terraform',
          component: (
            <TerraformGenContents
              newTemplate={!deploymentMethod}
              saveProperties={saveProperties}
              setFinished={setFinished}
              onSave={onSave}
              alreadyCreated
              open={open}
              onClose={handleClose}
              logsOptions={[
                ...logsOptions,
                {
                  id: 'cloudwatch',
                  label: 'CloudWatch',
                  value: 'cloudwatch',
                  type: 'cloudwatch',
                },
              ]}
              metricsOptions={metricsOptions}
              sidecarName={sidecarName}
              sidecarId={sidecarId}
              repos={repos}
              {...sidecarProperties}
            ></TerraformGenContents>
          ),
        },
        helm: {
          label: 'Deploy a sidecar to Kubernetes cluster using Helm',
          component: (
            <Helm3GenContents
              newTemplate={!deploymentMethod}
              saveProperties={saveProperties}
              setFinished={setFinished}
              onSave={onSave}
              alreadyCreated
              open={open}
              onClose={handleClose}
              logsOptions={[...logsOptions, { id: 'default', label: 'None', type: '', value: '' }]}
              metricsOptions={metricsOptions}
              sidecarName={sidecarName}
              sidecarId={sidecarId}
              repos={repos}
              {...sidecarProperties}
            ></Helm3GenContents>
          ),
        },
        linux: {
          label: 'Deploy a sidecar using a Linux package',
          component: (
            <BinariesGenContents
              newTemplate={!deploymentMethod}
              saveProperties={saveProperties}
              setFinished={setFinished}
              onSave={onSave}
              alreadyCreated
              open={open}
              onClose={handleClose}
              logsOptions={[...logsOptions, { id: 'default', label: 'None', type: '', value: '' }]}
              metricsOptions={metricsOptions}
              sidecarName={sidecarName}
              sidecarId={sidecarId}
              repos={repos}
              {...sidecarProperties}
            ></BinariesGenContents>
          ),
        },
        'single-container': {
          label: 'Deploy a sidecar using a single container',
          component: (
            <SingleContainerGenContents
              newTemplate={!deploymentMethod}
              saveProperties={saveProperties}
              setFinished={setFinished}
              onSave={onSave}
              alreadyCreated
              open={open}
              onClose={handleClose}
              logsOptions={[...logsOptions, { id: 'default', label: 'None', type: '', value: '' }]}
              metricsOptions={metricsOptions}
              sidecarName={sidecarName}
              sidecarId={sidecarId}
              repos={repos}
              {...sidecarProperties}
            ></SingleContainerGenContents>
          ),
        },
        'express-installer': {
          label: 'Deploy a sidecar using an express installer',
          component: (
            <ExpressInstallerGenContents
              newTemplate={!deploymentMethod}
              saveProperties={saveProperties}
              setFinished={setFinished}
              onSave={onSave}
              alreadyCreated
              open={open}
              onClose={handleClose}
              logsOptions={[...logsOptions, { id: 'default', label: 'None', type: '', value: '' }]}
              metricsOptions={metricsOptions}
              sidecarName={sidecarName}
              sidecarId={sidecarId}
              repos={repos}
              {...sidecarProperties}
            ></ExpressInstallerGenContents>
          ),
        },
        custom: {
          label: 'Deploy a sidecar using a custom template',
          component: (
            <CustomGenContents
              newTemplate={!deploymentMethod}
              saveProperties={saveProperties}
              setFinished={setFinished}
              onSave={onSave}
              alreadyCreated
              open={open}
              onClose={handleClose}
              logsOptions={[...logsOptions, { id: 'default', label: 'None', type: '', value: '' }]}
              metricsOptions={metricsOptions}
              sidecarName={sidecarName}
              sidecarId={sidecarId}
              repos={repos}
              {...sidecarProperties}
            ></CustomGenContents>
          ),
        },
      },
      window._env_.sidecar_deployment_options.split(',')
    )

    useEffect(() => {
      const fetchIntegrationOptions = () => {
        try {
          ec.get('/integrations/logging').then(res => setLogsOptions(res.data))
          ec.get('/integrations/metrics').then(res => setMetricsOptions(res.data))
        } catch (e) {
          showError('Error while fetching integrations')
        }
      }
      fetchIntegrationOptions()
    }, [])

    useEffect(() => {
      if (open) {
        setNewDeploymentMethod('')
        setSaveProperties(true)
      }
    }, [open])

    useEffect(() => {
      if (finished && !open) {
        setFinished(false)
      }
    }, [finished, open])

    return (
      <Dialog
        fullWidth
        maxWidth={
          ['linux', 'single-container', 'custom','express-installer'].includes(
            isValidDeploymentMethod(deploymentMethod) ? deploymentMethod : newDeploymentMethod
          )
            ? 'md'
            : 'sm'
        }
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Update Sidecar</DialogTitle>
        <DialogContent>
          <div>
            {!isValidDeploymentMethod(deploymentMethod) && !newDeploymentMethod ? (
              <TextField
                select
                value={newDeploymentMethod}
                onChange={e => setNewDeploymentMethod(e.target.value)}
                label="Select a deployment method"
                fullWidth
                css={{ marginTop: '8px' }}
                variant="outlined"
              >
                {Object.entries(DEPLOYMENT_METHODS).map(([method, obj]) => (
                  <MenuItem value={method}>{obj.label}</MenuItem>
                ))}
              </TextField>
            ) : (
              <Fragment>
                {!['linux', 'single-container', 'custom','express-installer'].includes(
                  isValidDeploymentMethod(deploymentMethod) ? deploymentMethod : newDeploymentMethod
                ) &&
                  (!isValidDeploymentMethod(deploymentMethod) ? (
                    <Typography css={{ marginBottom: '12px' }}>
                      {finished
                        ? 'These settings have been saved.'
                        : 'These settings will be saved.'}
                    </Typography>
                  ) : (
                    <FormControlLabel
                      disabled={finished}
                      control={
                        <Checkbox
                          disabled={finished}
                          color="primary"
                          checked={saveProperties}
                          onChange={e => setSaveProperties(e.target.checked)}
                          name="saveProperties"
                        />
                      }
                      label="Save these new settings"
                    />
                  ))}
                {
                  DEPLOYMENT_METHODS[
                    isValidDeploymentMethod(deploymentMethod)
                      ? deploymentMethod
                      : newDeploymentMethod
                  ].component
                }
              </Fragment>
            )}
          </div>
        </DialogContent>
      </Dialog>
    )
  }
)
