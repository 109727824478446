/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useContext, useState, useEffect } from 'react'
import { stableSort, getSorting } from '@jeeves/utils'
import {
  Paper,
  Toolbar,
  Table,
  TableBody,
  TableFooter,
  TableRow,
  Typography,
} from '@material-ui/core'

import { TableHead, TablePagination } from '@jeeves/components/Table'

import { useAccounts } from './useAccounts'
import Row from './Row'
import { Add } from './Add'
import { getDocsURL } from '@jeeves/utils/docsURL'

const COLUMNS = [
  { id: 'clientId', numeric: false, disablePadding: false, label: 'Client ID' },
  { id: 'createdOn', numeric: false, disablePadding: false, label: 'Created On' },
]

const Accounts = ({ accounts, sidecar, onCreate }) => {
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [{ order, orderBy }, setOrderInfo] = useState({ order: 'desc', orderBy: 'createdOn' })
  const { getDate, getTimestamp, createdOnComparator } = useAccounts()

  const handleRequestSort = (event, property) => {
    setOrderInfo(({ order: prevOrder, orderBy: prevOrderBy }) =>
      prevOrderBy === property && prevOrder === 'asc'
        ? { order: 'desc', orderBy: prevOrderBy }
        : { order: 'asc', orderBy: property }
    )
  }
  const handleChangePage = (event, page) => {
    setPage(page)
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  const accountsCustomComparators = {
    createdOn: createdOnComparator,
  }

  return (
    <Paper
      elevation={0}
      css={t => css`
        margin-top: ${t.spacing.unit * 2}px;
        margin-bottom: ${t.spacing.unit * 2}px;
        background-color: transparent;
      `}
    >
      <Toolbar
        css={() => css`
          display: flex;
        `}
      >
        <div css={{ maxWidth: '85%' }}>
          <Typography css={{ fontSize: '14px' }}>
            The table below lists all sidecar identities registered with this Control Plane. Each
            Client ID is associated with a Client Secret which is provided only once — when the
            identity is generated.
            <a
              href={getDocsURL({
                docsPath: 'sidecars/secrets#rotate-the-client-secret-for-a-sidecar',
              })}
              css={{ textDecoration: 'none', color: '#26459e' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>{' Learn more about how this works.'}</strong>
            </a>
          </Typography>
        </div>
        <div css={{ flexGrow: 1 }}></div>

        <Add sidecarId={sidecar.id} onCreate={onCreate} />
      </Toolbar>

      <Table aria-labelledby="tableTitle">
        <TableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={COLUMNS}
          rowCount={accounts.length}
        />

        <TableBody>
          {(orderBy
            ? stableSort(accounts, getSorting(order, orderBy, accountsCustomComparators)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : accounts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          ).map(account => (
            <Row key={`${account.clientId}row`} account={account} getDate={getDate} />
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              count={accounts.length}
              colSpan={COLUMNS.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  )
}

export default Accounts
