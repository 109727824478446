import { v5Theme } from '@jeeves/theme'
import { IconCopyButton } from '@jeeves/new-components'
import { ThemeProvider } from '@mui/material/styles'
import { IconButton, Stack, Typography } from '@mui/material'
import { FileCopyOutlined as CopyIcon } from '@material-ui/icons'

export const SidecarCopyButton = ({ wrapper }) => {
  const sidecarHost = wrapper?.userEndpoint || wrapper?.endpoint || ''

  return (
    sidecarHost && (
      <ThemeProvider theme={v5Theme}>
        <Stack direction="row" sx={{ alignItems: 'center', marginBottom: '8px' }}>
          <Typography component="span" variant="code" sx={{ color: 'text.primary' }}>
            {sidecarHost}
          </Typography>
          <IconCopyButton text={sidecarHost}>
            <IconButton color="default">
              <CopyIcon fontSize="small" />
            </IconButton>
          </IconCopyButton>
        </Stack>
      </ThemeProvider>
    )
  )
}
