import React, { useState, useMemo, useEffect } from 'react'
import { useQuery, gql, NetworkStatus } from '@apollo/client'
import { parse, end } from 'iso8601-duration'
import * as _ from 'lodash'

import { useAuth } from '@jeeves/components/Auth'
import { ACCESS_PORTAL } from './useAccessPortal'

const useRequestableRepos = options => {
  const { data, loading, error, fetchMore, refetch, networkStatus } = useQuery(
    ACCESS_PORTAL,
    _.merge(options, { variables: { accessibility: 'ALL' } })
  )

  const hasAccessRequestIntegration = data?.slackNotificationIntegrations?.some(slackIntegration =>
    slackIntegration.name?.startsWith('Access Requests:')
  )

  return {
    loading,
    error,
    data: {
      repoEdgesUserCanRequest: data?.user?.repos?.edges,
      hasNextPage: data?.user?.repos?.pageInfo?.hasNextPage,
      hasAccessRequestIntegration,
    },
    getNextPage:
      data?.user?.repos?.pageInfo?.hasNextPage &&
      (() => fetchMore({ variables: { after: data?.user?.repos?.pageInfo?.endCursor } })),
    refetch,
    firstLoading: networkStatus === NetworkStatus.loading,
  }
}

export default useRequestableRepos
