import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Box, Stack, Typography, FormControlLabel, Switch, FormControl, Alert } from '@mui/material'
import { Button, Select, InputLabel } from '@jeeves/new-components'
import { LearnMore } from './LearnMore'
import { gql, useMutation } from '@apollo/client'

const UPDATE_AUTH = gql`
  mutation UpdateRepoAuthenticationConfig($repoId: ID!, $input: UpdateRepoAuthenticationConfigInput!) {
    updateRepoAuthenticationConfig(repoId: $repoId, input: $input) {
      repo {
        id
        name
        config {
          authentication {
            allowNativeAuthentication
            identityProvider {
              name
              displayName
            }
          }
        }
      }
    }
  }
`

export const Authentication = ({ repoId = '', authData, identityProviders }) => {
  const {
    control,
    reset,
    getValues,
    formState: { isDirty, isSubmitting, isSubmitSuccessful },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      allowNativeAuthentication: authData?.allowNativeAuthentication,
      idp: authData?.identityProvider?.name || null,
    },
  })

  const [updateAuth, { data, loading, error }] = useMutation(UPDATE_AUTH)

  const { allowNativeAuthentication, idp } = watch()

  const onSubmit = async ({ allowNativeAuthentication, idp }) => {
    try {
      await updateAuth({
        variables: {
          repoId,
          input: {
            allowNativeAuthentication,
            identityProviderName: idp,
          },
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  const handleCancel = () => {
    reset()
  }

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues())
    }
  }, [isSubmitSuccessful, reset, getValues])

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack sx={{ justifyContent: 'space-between' }}>
        <Stack spacing={3} sx={{ pb: 7 }}>
          {!allowNativeAuthentication && !idp && (
            <Alert
              severity="info"
              sx={{
                color: 'cyralColors.yellow.500',
                backgroundColor: 'cyralColors.yellow.100',

                '& .MuiAlert-icon': {
                  color: 'inherit',
                },
              }}
            >
              <Typography variant="body2" sx={{ color: 'cyralColors.yellow.500' }}>
                This configuration will cause this data repository to be inaccessible through the
                sidecar.
              </Typography>
            </Alert>
          )}
          <Box>
            <Controller
              name="allowNativeAuthentication"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormControlLabel
                  control={<Switch name={name} onChange={onChange} checked={value} />}
                  label={
                    <Typography variant="h4" sx={{ color: 'text.primary' }}>
                      Allow native authentication for this database.{' '}
                      <LearnMore docsPath="/manage-repositories/repo-advanced-settings/#authentication" />
                    </Typography>
                  }
                />
              )}
            />
          </Box>

          <Stack>
            <FormControl variant="standard">
              <InputLabel
                id="idp-select-label"
                htmlFor="idp-select"
                sx={{
                  typography: 'h4',
                  color: 'text.primary',
                }}
              >
                Select an Identity Provider
              </InputLabel>
              <Controller
                name="idp"
                control={control}
                render={({ field }) => (
                  <Select
                    labelId="idp-select-label"
                    id="idp-select"
                    options={identityProviders.map(idp => {
                      return {
                        label: idp.displayName,
                        value: idp.name,
                      }
                    })}
                    {...field}
                  />
                )}
              />
            </FormControl>
          </Stack>
        </Stack>

        <Stack direction="row" spacing={2.5} sx={{ justifyContent: 'flex-end' }}>
          <Button variant="text" onClick={handleCancel} disabled={!isDirty}>
            Cancel
          </Button>
          <Button variant="contained" type="submit" loading={isSubmitting} disabled={!isDirty}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}
