/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { createContext, useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '@jeeves/components/Auth'
import useInterval from '@jeeves/hooks/useInterval'

import { Table, TableBody, TableFooter, TableRow } from '@material-ui/core'

import { stableSort, getSorting, isLoading, isError } from '@jeeves/utils'
import { ActionButton, Paper } from '@jeeves/components/Primitives'
import { TableHead, TablePagination } from '@jeeves/components/Table'
import Toolbar from '@jeeves/components/Toolbar'
import Loading from '@jeeves/components/Loading'
import RowSpan from '@jeeves/components/RowSpan'

import SidecarRow from './components/SidecarRow'
import Filters from './components/Filters'
import { WrappersProvider, WrappersContext } from './contexts/WrappersContext'
import useWrappers from './hooks/useWrappers'
import { sortSidecarTags } from './WrapperFunctions'
import useFilter from './hooks/useFilters'

export const RepoTypesContext = createContext({})

const centered = { textAlign: 'center', padding: '4px 24px' }

const columns = [
  { id: 'health', numeric: false, label: null, disablePadding: true },
  { id: 'sidecar.name', numeric: false, label: 'Name' },
  { id: 'tags', numeric: false, label: 'Tags' },
  { id: 'sidecar.cloud', numeric: false, label: 'Platform', css: centered },
  { id: 'activeInstances', numeric: false, label: 'Active instances', css: centered },
  { id: 'sidecar.repoCount', numeric: false, label: 'Data Repositories', css: centered },
  // { id: 'metrics', numeric: false, label: 'Requests/Sec', css: centered },
]

const Wrappers = () => {
  const [state, setState] = useContext(WrappersContext)
  const [inFlight, setInFlight] = useState(false)
  const { hasPermission } = useAuth()

  const {
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    ec,
    lodashGet,
  } = useWrappers()

  const { rowsPerPage, page, order, orderBy, wrappers } = state
  const { filterName, filterCloud, filterTags, filterState } = useFilter()

  useEffect(() => {
    return () => {
      ec.cancel()
    }
  }, []) // eslint-disable-line

  const fetchData = async () => {
    setInFlight(true)
    try {
      const result = await ec.get('/sidecars/home')
      setState(state => ({
        ...state,
        wrappers: result.data,
      }))
    } catch (e) {
      if (!state.wrappers && isLoading(wrappers)) {
        setState(state => ({ ...state, wrappers: null }))
      }
    } finally {
      setInFlight(false)
    }
  }

  const refreshWrappers = () => {
    setState(state => ({ ...state, wrappers: undefined }))
    fetchData()
  }

  useInterval(() => {
    if (!inFlight) {
      fetchData()
    }
  }, 15000)

  if (!hasPermission('sidecar:read')) {
    return null
  }

  const getTableBody = () => {
    if (isLoading(wrappers)) {
      return (
        <RowSpan colSpan={columns.length}>
          <Loading />
        </RowSpan>
      )
    }

    if (isError(wrappers)) {
      return <RowSpan colSpan={columns.length}>There was an issue loading sidecars</RowSpan>
    }

    if (wrappers.length === 0) {
      return <RowSpan colSpan={columns.length}>There are no sidecars added yet</RowSpan>
    }

    return stableSort(wrappers, getSorting(order, orderBy, { tags: sortSidecarTags }))
      .filter(filterName)
      .filter(filterCloud)
      .filter(filterTags)
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map(wrapper => (
        <SidecarRow wrapper={wrapper} refreshWrappers={refreshWrappers} key={wrapper.id} />
      ))
  }

  return (
    <RepoTypesContext.Provider>
      <Paper>
        <Toolbar
          title="Sidecars"
          subtitle="A sidecar is an interception service that you deploy to monitor and protect a data repository."
          titleAction={
            <ActionButton
              component={Link}
              to="/sidecars/instructions"
              disabled={!hasPermission('sidecar:create')}
            />
          }
        >
          <Filters filterState={filterState} />
        </Toolbar>

        <Table css={{ borderCollapse: 'separate' }} aria-labelledby="tableTitle">
          <TableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            columns={columns}
            rowCount={lodashGet(wrappers, 'length', 0)}
          />
          <TableBody>{getTableBody()}</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={lodashGet(wrappers, 'length', 0)}
                colSpan={8}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </RepoTypesContext.Provider>
  )
}

const WrappersWrapper = props => (
  <WrappersProvider>
    <Wrappers {...props} />
  </WrappersProvider>
)

export default WrappersWrapper
