/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useContext, useState, useEffect } from 'react'
import { stableSort, getSorting } from '@jeeves/utils'
import { Paper, Toolbar, Table, TableBody, TableFooter, TableRow } from '@material-ui/core'

import { TableHead, TablePagination } from '@jeeves/components/Table'

import Row from './components/Row'

const COLUMNS = [
  { id: 'health', numeric: false, disablePadding: false, label: '', css: { paddingRight: '0' } },
  { id: 'asg_instance', numeric: false, disablePadding: false, label: 'Instance ID' },
  { id: 'sidecar_version', numeric: false, disablePadding: false, label: 'Sidecar Version' },
  { id: 'start_timestamp', numeric: false, disablePadding: false, label: 'Age' },
  { id: 'queriesPerSecond', numeric: true, disablePadding: false, label: 'Throughput (QPS)' },
  {
    id: 'activeConnectionCount',
    numeric: true,
    disablePadding: false,
    label: 'Active Connections',
  },
]

const SidecarInstances = ({ sidecarInstances }) => {
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [{ order, orderBy }, setOrderInfo] = useState({ order: 'asc', orderBy: 'asg_instance' })
  const handleRequestSort = (event, property) => {
    setOrderInfo(({ order: prevOrder, orderBy: prevOrderBy }) =>
      prevOrderBy === property && prevOrder === 'asc'
        ? { order: 'desc', orderBy: prevOrderBy }
        : { order: 'asc', orderBy: property }
    )
  }
  const handleChangePage = (event, page) => {
    setPage(page)
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  const sidecarInstanceCustomComparators = {
    start_timestamp: order => {
      const timestampValue = a => a || 0
      const desc = (a, b) => {
        if (timestampValue(a) === timestampValue(b)) return 0
        return timestampValue(a) < timestampValue(b) ? -1 : 1
      }
      return order === 'desc' ? desc : (a, b) => -desc(a, b)
    },
  }

  return (
    <Paper
      elevation={0}
      css={t => css`
        margin-top: ${t.spacing.unit * 2}px;
        margin-bottom: ${t.spacing.unit * 2}px;
        background-color: transparent;
      `}
    >
      <Table aria-labelledby="tableTitle">
        <TableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={COLUMNS}
          rowCount={sidecarInstances.length}
        />

        <TableBody>
          {(orderBy
            ? stableSort(
                sidecarInstances,
                getSorting(order, orderBy, sidecarInstanceCustomComparators)
              ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : sidecarInstances.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          ).map(sidecarInstance => (
            <Row key={`${sidecarInstance.asg_instance}row`} sidecarInstance={sidecarInstance} />
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              count={sidecarInstances.length}
              colSpan={COLUMNS.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  )
}

export default SidecarInstances
