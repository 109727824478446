import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useFragment_experimental, gql } from '@apollo/client'
import { repoSupportsPortMultiplexing } from './utils'

import { useRepositoriesContext } from '../../../contexts/RepositoriesContext'

import { useBindingsTableContext } from '../../Bindings/BindingsTable/BindingsTableContext'
import { isClusterRepo } from '@jeeves/graphql/utils'

const CONFIGURE_MULTIPLEXED_LISTENER_SETTINGS = gql`
  mutation ConfigureMultiplexedListenerSettings(
    $sidecarId: ID!
    $listenerId: ID!
    $input: UpsertMultiplexListenerSettingsInput!
  ) {
    upsertMultiplexedListenerConfig(sidecarId: $sidecarId, listenerId: $listenerId, input: $input) {
      listener {
        id
        ... on MySQLFamilyMultiplexedListener {
          config {
            advertisedVersion
          }
        }
      }
    }
  }
`

const BIND_REPO_TO_SIDECAR = gql`
  mutation BindRepoToSidecar($repoId: ID!, $sidecarId: ID!, $input: BindRepoToSidecarInput!) {
    bindRepoToSidecar(repoId: $repoId, sidecarId: $sidecarId, input: $input) {
      binding {
        id
        enabled
        ... on SingleListenerBinding {
          listener {
            id
            port
          }
        }
        ... on S3Binding {
          listenerSet {
            proxyListener {
              id
              port
            }
            browserListener {
              id
              port
            }
          }
        }
        ... on ClusterBinding {
          boundListenersRelationship {
            edges {
              node {
                id
                port
              }
            }
          }
        }
      }
    }
  }
`

const useBindDataRepoDialog_queryFragment = gql`
  fragment useBindDataRepoDialog_query on Query {
    repos {
      id
      type
      ... on MongoDBReplicaSetRepo {
        numNodes
      }
    }
  }
`

const useBindDataRepoDialog = (query, refreshCurrentTableViewCallbackRef) => {
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [snackBarVariant, setSnackBarVariant] = useState('success')

  const { id: sidecarId } = useParams()
  const { data } = useFragment_experimental({
    fragment: useBindDataRepoDialog_queryFragment,
    fragmentName: 'useBindDataRepoDialog_query',
    from: query,
  })

  const [bindRepoToSidecarMutation, { reset, error }] = useMutation(BIND_REPO_TO_SIDECAR, {
    onError: error => {
      setSnackBarOpen(true)
      setSnackBarMessage(error.message)
      setSnackBarVariant('error')
      reset()
    },
  })
  const [configureMultiplexedListenerSettings] = useMutation(
    CONFIGURE_MULTIPLEXED_LISTENER_SETTINGS
  )

  const { refetch } = useRepositoriesContext()

  const handleCloseSnackbar = () => {
    setSnackBarOpen(false)
  }

  const getInput = submittedData => {
    const selectedRepo = data.repos.find(repo => repo.id === submittedData.selectedRepo)
    const isSelectClusterRepo = isClusterRepo(selectedRepo.__typename)

    if (selectedRepo.type === 's3') {
      const port = Number(submittedData.proxyPort)
      const browserPort = submittedData?.browserPort

      return {
        s3Repo: {
          port,
          ...(browserPort && { browserPort: Number(browserPort) }),
        },
      }
    } else if (isSelectClusterRepo) {
      const sidecarPorts = submittedData.ports.map(port => Number(port))

      return {
        clusterRepo: {
          sidecarPorts,
        },
      }
    } else {
      const submittedPort = repoSupportsPortMultiplexing(selectedRepo.type)
        ? submittedData.port.port
        : submittedData.port
      const port = Number(submittedPort)

      return {
        standaloneRepo: {
          port,
        },
      }
    }
  }

  const onSubmit = async submittedData => {
    try {
      const variables = {
        repoId: submittedData.selectedRepo,
        sidecarId,
        input: getInput(submittedData),
      }

      const response = await bindRepoToSidecarMutation({
        variables,
      })

      const hasErrors = response?.errors?.graphQLErrors?.length > 0

      if (hasErrors) {
        throw new Error()
      }

      if (submittedData.mysqlFamilyConfig) {
        await configureMultiplexedListenerSettings({
          variables: {
            sidecarId,
            listenerId: submittedData.port.id,
            input: {
              mysqlFamilyConfig: {
                ...submittedData.mysqlFamilyConfig,
              },
            },
          },
        })
      }

      refetch()
      if (refreshCurrentTableViewCallbackRef.current) {
        refreshCurrentTableViewCallbackRef.current()
      }
    } catch (e) {
      throw e
    }
  }

  return {
    onSubmit,
    openSnackbar: snackBarOpen,
    handleCloseSnackbar,
    snackBarMessage,
    snackBarVariant,
  }
}

useBindDataRepoDialog.fragments = {
  useBindDataRepoDialog_queryFragment,
}

export default useBindDataRepoDialog
