import { useState } from 'react'
import ExpressClient from '@jeeves/clients/express'
import { useAuth, usePopup, useMount } from '@jeeves/hooks'
import { get as lodashGet } from 'lodash'

const useDatadog = () => {
  const { showError } = usePopup()
  const { getTokenSilently, user } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [integrations, setIntegrations] = useState([])

  const updateIntegrations = async () => {
    setLoading(true)
    try {
      const { data } = await ec.get('/integrations/datadog')
      setIntegrations(data)
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setError(true)
      showError(popupMessage)
    } finally {
      setLoading(false)
    }
  }

  const addIntegration = async input => {
    try {
      const integration = await ec.post('/integrations/datadog', input).then(res => res.data)
      updateIntegrations()
      return integration
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      showError(popupMessage)
      throw e
    }
  }

  const putIntegration = async (input, integrationID) => {
    try {
      const integration = await ec
        .put(`/integrations/datadog/${integrationID}`, input)
        .then(res => res.data)
      updateIntegrations()
      return integration
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      showError(popupMessage)
    }
  }

  const deleteIntegration = async integrationID => {
    try {
      await ec.delete(`/integrations/datadog/${integrationID}`)

      updateIntegrations()
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      showError(popupMessage)
    }
  }

  useMount(updateIntegrations)

  return {
    loading,
    error,
    updateIntegrations,
    integrations,
    addIntegration,
    putIntegration,
    deleteIntegration,
    user,
    lodashGet,
  }
}

export default useDatadog
