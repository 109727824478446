/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Paper } from '@jeeves/components/Primitives'
import { Breadcrumbs } from './Breadcrumbs'
import Toolbar from '@jeeves/components/Toolbar'

export const WrapperError = () => (
  <Paper>
    <Breadcrumbs />
    <Toolbar
      title={'There was an error while fetching the Sidecar'}
      css={() => css`
        padding-top: 0;
        margin-top: -8px;
      `}
    />
  </Paper>
)
