import { isAfter, parseISO, isFuture } from 'date-fns'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { Box, FormControl, FormHelperText, Stack, Typography } from '@mui/material'
import { DatePicker, InputLabel } from '@jeeves/new-components'

const futureDateMessage = 'Please enter a date in the past'

const isFutureValidation = (date: Date) => !isFuture(date) || futureDateMessage

const DateRange = () => {
  const { control, formState } = useFormContext()
  const { errors } = formState

  const dateRangeFrom = useWatch({
    name: 'dateRangeFrom',
  })

  return (
    <Stack spacing={1}>
      <InputLabel>Date Range</InputLabel>

      <Stack spacing={1} direction="row" sx={{ alignItems: 'baseline' }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          From
        </Typography>

        <FormControl variant="standard">
          <Controller
            name="dateRangeFrom"
            control={control}
            render={({ field: { onChange, value } }) => {
              return <DatePicker onChange={onChange} value={value} selectTime />
            }}
            rules={{
              validate: dateRangeFrom => {
                const dateFrom = parseISO(dateRangeFrom)
                return isFutureValidation(dateFrom)
              },
            }}
          />
        </FormControl>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          to
        </Typography>

        <FormControl error={Boolean(errors?.dateRangeTo)} variant="standard">
          <Controller
            name="dateRangeTo"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => {
              return (
                <Box>
                  <DatePicker
                    onChange={onChange}
                    value={value}
                    error={Boolean(errors?.dateRangeTo)}
                    selectTime
                  />
                  {errors?.dateRangeTo && (
                    <FormHelperText
                      sx={{
                        typography: 'body2',
                      }}
                    >
                      {errors.dateRangeTo.message}
                    </FormHelperText>
                  )}
                </Box>
              )
            }}
            rules={{
              validate: dateRangeTo => {
                const dateFrom = parseISO(dateRangeFrom)
                const dateTo = parseISO(dateRangeTo)
                if (isAfter(dateFrom, dateTo)) {
                  return 'End date must be after start date.'
                }
                return isFutureValidation(dateTo)
              },
            }}
          />
        </FormControl>
      </Stack>
    </Stack>
  )
}

export default DateRange
