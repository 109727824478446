import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  Switch,
  FormGroup,
} from '@mui/material'
import { gql, useMutation } from '@apollo/client'

import { Button } from '@jeeves/new-components'
import { LearnMore } from './LearnMore'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

const UPDATE_ENFORCEMENT = gql`
  mutation UpdateRepoEnforcementConfig($repoId: ID!, $input: UpdateRepoEnforcementConfigInput!) {
    updateRepoEnforcementConfig(repoId: $repoId, input: $input) {
      repo {
        id
        name
        config {
          enforcement {
            blockOnViolations
            enableDataMasking
            enableDatasetRewrites
          }
        }
      }
    }
  }
`

const POLICY_ENFORCEMENT_TYPES = {
  block: 'block',
  rewrite: 'rewrite',
  mask: 'mask',
}

// POLICY_ENFORCEMENT_MATRIX indicates the policy enforcement types
// that are supported by each repo type. This is based on the sidecar
// feature compatibility matrix, for more info see:
// - https://cyralinc.atlassian.net/wiki/spaces/EN/pages/827949196/Sidecar+Feature+Compatibility+Matrix
export const POLICY_ENFORCEMENT_MATRIX = {
  s3: [POLICY_ENFORCEMENT_TYPES.block],
  rest: [],
  denodo: [
    POLICY_ENFORCEMENT_TYPES.block,
    POLICY_ENFORCEMENT_TYPES.rewrite,
    POLICY_ENFORCEMENT_TYPES.mask,
  ],
  dremio: [POLICY_ENFORCEMENT_TYPES.block],
  dynamodb: [POLICY_ENFORCEMENT_TYPES.block],
  dynamodbstreams: [],
  galera: [POLICY_ENFORCEMENT_TYPES.block],
  mariadb: [
    POLICY_ENFORCEMENT_TYPES.block,
    POLICY_ENFORCEMENT_TYPES.mask,
    POLICY_ENFORCEMENT_TYPES.rewrite,
  ],
  mongodb: [POLICY_ENFORCEMENT_TYPES.block],
  mysql: [
    POLICY_ENFORCEMENT_TYPES.block,
    POLICY_ENFORCEMENT_TYPES.mask,
    POLICY_ENFORCEMENT_TYPES.rewrite,
  ],
  oracle: [POLICY_ENFORCEMENT_TYPES.block, POLICY_ENFORCEMENT_TYPES.rewrite],
  postgresql: [
    POLICY_ENFORCEMENT_TYPES.block,
    POLICY_ENFORCEMENT_TYPES.rewrite,
    POLICY_ENFORCEMENT_TYPES.mask,
  ],
  redshift: [
    POLICY_ENFORCEMENT_TYPES.block,
    POLICY_ENFORCEMENT_TYPES.rewrite,
    POLICY_ENFORCEMENT_TYPES.mask,
  ],
  snowflake: [
    POLICY_ENFORCEMENT_TYPES.block,
    POLICY_ENFORCEMENT_TYPES.rewrite,
    POLICY_ENFORCEMENT_TYPES.mask,
  ],
  sqlserver: [
    POLICY_ENFORCEMENT_TYPES.block,
    POLICY_ENFORCEMENT_TYPES.rewrite,
    POLICY_ENFORCEMENT_TYPES.mask,
  ],
}

const FORM_FIELD_NAMES = {
  enablePolicyEnforcement: 'enablePolicyEnforcement',
  blockOnViolations: 'blockOnViolations',
  enableDataMasking: 'enableDataMasking',
  enableDatasetRewrites: 'enableDatasetRewrites',
}

export const PolicyEnforcement = ({ enforcementData }) => {
  const { repoId, repoType } = useRepositoryDetailContext()

  const {
    control,
    reset,
    formState: { isDirty, isSubmitting, isSubmitSuccessful },
    handleSubmit,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      enablePolicyEnforcement:
        enforcementData?.blockOnViolations ||
        enforcementData?.enableDataMasking ||
        enforcementData?.enableDatasetRewrites,
      blockOnViolations: enforcementData?.blockOnViolations,
      enableDataMasking: enforcementData?.enableDataMasking,
      enableDatasetRewrites: enforcementData?.enableDatasetRewrites,
    },
  })

  const [updateEnforcement, { data, loading, error }] = useMutation(UPDATE_ENFORCEMENT)

  const onSubmit = async ({ blockOnViolations, enableDataMasking, enableDatasetRewrites }) => {
    try {
      await updateEnforcement({
        variables: {
          repoId,
          input: {
            blockOnViolations,
            enableDataMasking,
            enableDatasetRewrites,
          },
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  const handleCancel = () => {
    reset()
  }

  const handleEnforcementToggle = () => {
    const enablePolicyEnforcement = getValues(FORM_FIELD_NAMES.enablePolicyEnforcement)
    if (enablePolicyEnforcement) {
      setValue(FORM_FIELD_NAMES.blockOnViolations, false)
      setValue(FORM_FIELD_NAMES.enableDataMasking, false)
      setValue(FORM_FIELD_NAMES.enableDatasetRewrites, false)
    }
  }

  const enablePolicyEnforcement = watch(FORM_FIELD_NAMES.enablePolicyEnforcement)
  const blockOnViolations = watch(FORM_FIELD_NAMES.blockOnViolations)
  const enableDataMasking = watch(FORM_FIELD_NAMES.enableDataMasking)
  const enableDatasetRewrites = watch(FORM_FIELD_NAMES.enableDatasetRewrites)

  const isAnyPolicyEnforcementOptionSelected =
    blockOnViolations || enableDataMasking || enableDatasetRewrites

  const onlyEnablePolicyEnforcementIsSelected =
    enablePolicyEnforcement && !isAnyPolicyEnforcementOptionSelected

  const hasPolicyEnforcementType = policyEnforcementType => {
    return POLICY_ENFORCEMENT_MATRIX[repoType]?.includes(policyEnforcementType)
  }

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues())
    }
  }, [isSubmitSuccessful, reset, getValues])

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack sx={{ justifyContent: 'space-between' }}>
        <Stack spacing={2} sx={{ pb: 7 }}>
          <Stack spacing={1}>
            <Box>
              <Controller
                name={FORM_FIELD_NAMES.enablePolicyEnforcement}
                control={control}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormControlLabel
                    sx={{ ml: '-11px' }}
                    control={
                      <Switch
                        name={name}
                        onChange={e => {
                          handleEnforcementToggle()
                          onChange(e)
                        }}
                        checked={value}
                      />
                    }
                    label={
                      <Typography variant="h4" sx={{ color: 'text.primary' }}>
                        Enable policy enforcement.
                      </Typography>
                    }
                  />
                )}
              />
            </Box>
            <Box sx={{ maxWidth: '75%' }}>
              <Typography variant="body1" sx={{ color: 'text.secondary', ml: 6 }}>
                When disabled, Cyral will only log and alert on policy violations.{' '}
                <LearnMore docsPath="repo-advanced-settings#alert-on-policy-violations" />
              </Typography>
            </Box>
            {hasPolicyEnforcementType(POLICY_ENFORCEMENT_TYPES.block) && (
              <Box>
                <Controller
                  name={FORM_FIELD_NAMES.blockOnViolations}
                  control={control}
                  render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <FormGroup sx={{ ml: 6 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!enablePolicyEnforcement}
                            name={name}
                            onChange={onChange}
                            checked={value}
                          />
                        }
                        label="Block on violation"
                      />
                    </FormGroup>
                  )}
                />
              </Box>
            )}
            {hasPolicyEnforcementType(POLICY_ENFORCEMENT_TYPES.mask) && (
              <Box>
                <Controller
                  name={FORM_FIELD_NAMES.enableDataMasking}
                  control={control}
                  render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <FormGroup sx={{ ml: 6 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!enablePolicyEnforcement}
                            name={name}
                            onChange={onChange}
                            checked={value}
                          />
                        }
                        label="Enable data masking"
                      />
                    </FormGroup>
                  )}
                />
              </Box>
            )}
            {hasPolicyEnforcementType(POLICY_ENFORCEMENT_TYPES.rewrite) && (
              <Box>
                <Controller
                  name={FORM_FIELD_NAMES.enableDatasetRewrites}
                  control={control}
                  render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <FormGroup sx={{ ml: 6 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!enablePolicyEnforcement}
                            name={name}
                            onChange={onChange}
                            checked={value}
                          />
                        }
                        label="Enable dataset rewrites"
                      />
                    </FormGroup>
                  )}
                />
              </Box>
            )}
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2.5} sx={{ justifyContent: 'flex-end' }}>
          <Button variant="text" onClick={handleCancel} disabled={!isDirty}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            loading={isSubmitting}
            disabled={!isDirty || onlyEnablePolicyEnforcementIsSelected}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}
