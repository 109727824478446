import React from 'react'
import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import { get as lodashGet } from 'lodash'

const useAuditLogs = () => {
  const { setPopup, popupTypes } = usePopup()

  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const getTimeSpan = timeSpan => {
    const SECOND = 1000
    const MINUTE = SECOND * 60
    const HOUR = MINUTE * 60
    const DAY = HOUR * 24
    const WEEK = DAY * 7
    const MONTH = WEEK * 4

    const timeSpanMap = new Map([
      ['15_MINS', { value: MINUTE * 15, label: 'Last 15 minutes' }],
      ['HOUR', { value: HOUR, label: 'Last hour' }],
      ['DAY', { value: DAY, label: 'Last day' }],
      ['WEEK', { value: WEEK, label: 'Last week' }],
      ['2_WEEKS', { value: WEEK * 2, label: 'Last 2 weeks' }],
      ['MONTH', { value: MONTH * 3, label: 'Last month' }],
    ])

    return timeSpanMap.get(timeSpan)
  }

  const generateAuditLogsFilter = timeSpan => {
    const startDateTimeStamp = Date.now() - timeSpan
    const endDateTimeStamp = Date.now()

    const startDate = new Date(startDateTimeStamp).toISOString()
    const endDate = new Date(endDateTimeStamp).toISOString()

    return `{ \"timestamp\": { \"$gte\": {\"$date\": \"${startDate}\"}, \"$lte\": {\"$date\": \"${endDate}\" }}}`
  }

  const fetchUserConnections = async () => {
    try {
      const userConnections = await ec.get('/users/connections/names').then(res => res.data)
      return userConnections
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  const fetchAuditLogs = async (timeSpan, desiredPage) => {
    try {
      const timeSpanValue = getTimeSpan(timeSpan).value
      const auditLogsFilter = generateAuditLogsFilter(timeSpanValue)
      const auditLogs = await ec
        // parse pageToken into string
        .post('/audit/query', {
          filter: auditLogsFilter,
          pageSize: 25,
          pageToken: desiredPage.toString(),
        })
        .then(res => res.data)
      return auditLogs
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  const getIDPDisplayName = (IDPAlias, IDPSet) => {
    if (!IDPAlias) {
      return ''
    }
    if (IDPAlias === 'cyral-auth0') {
      return 'Cyral Provider'
    }

    const matchedIDP = IDPSet.find(IDP => IDP.name === IDPAlias)

    return matchedIDP?.displayName || IDPAlias
  }

  const getDescriptivePhraseForAction = (action, metadata) => {
    switch (action) {
      case 'login':
      case 'authentication.login':
        return `Logged in with ${getIDPDisplayName(metadata.IDPAlias, metadata.IDPset)}`
      case 'logout':
      case 'authentication.logout':
        return 'Logged out'
      case 'opaque-token.create':
        return 'Viewed access token'
      case 'data-access.request':
        return 'Requested data access'
      case 'data-access.admin-approval':
        return 'Approved a data access request'
      case 'data-access.auto-approval':
        return 'Obtained data access automatically'
      case 'data-access.denial':
        return 'Denied a data access request'
      case 'data-access.revoke':
        return 'Revoked data access'
      case 'datamap.upsert':
        return 'Data map upserted'
      case 'datamap.delete':
        return 'Data map deleted'
      case 'datamap-attribute.create':
        return 'Data map attribute created'
      case 'datamap-attribute.delete':
        return 'Data map attribute deleted'
      case 'datamap-recommendation.create':
        return 'Data map recommendation created'
      case 'datamap-recommendation.create-many':
        return 'Data map recommendations created'
      case 'datamap-recommendation.update-status':
        return 'Data map recommendation status updated'
      case 'data-label.upsert':
        return 'Data label upserted'
      case 'data-label.delete':
        return 'Data label deleted'
      case 'service-account.create':
        return 'Service account created'
      case 'service-account.update':
        return 'Service account updated'
      case 'service-account.delete':
        return 'Service account deleted'
      case 'repo.create':
        return 'Data repository created'
      case 'repo.update':
        return 'Data repository updated'
      case 'repo.delete':
        return 'Data repository deleted'
      case 'repo-account.create':
        return 'Data repository account created'
      case 'repo-account.update':
        return 'Data repository account updated'
      case 'repo-account.delete':
        return 'Data repository account deleted'
      case 'repo-binding.create':
        return 'Data repository binding created'
      case 'repo-binding.update':
        return 'Data repository binding updated'
      case 'repo-binding.delete':
        return 'Data repository binding deleted'
      case 'network-access-policy.create':
        return 'Network access policy created/replaced'
      case 'network-access-policy.delete':
        return 'Network access policy deleted'
      case 'network-access-policy.update':
        return 'Network access policy updated'
      case 'user-account.create':
        return 'Database account created'
      case 'user-account.update':
        return 'Database account updated'
      case 'user-account.delete':
        return 'Database account deleted'
      case 'access-rules.create':
        return 'Access rules created'
      case 'access-rules.update':
        return 'Access rules updated'
      case 'access-rules.delete':
        return 'Access rules deleted'
      case 'access-rules.edit':
        return 'Access rules modified'
      case 'approval.request':
        return 'Approval requested'
      case 'approval.modify':
        return 'Approval request modified'
      case 'approval.manage':
        return 'Approval admin action'
      case 'approval.grant':
        return 'Approval granted'
      case 'approval.reject':
        return 'Approval denied'
      case 'approval.revoke':
        return 'Approval revoked'
      case 'approval.delete':
        return 'Approval deleted'
      case 'binding.create':
        return 'Binding created'
      case 'binding.update':
        return 'Binding updated'
      case 'binding.delete':
        return 'Binding deleted'
      case 'listener.create':
        return 'Listener created'
      case 'listener.update':
        return 'Listener updated'
      case 'listener.delete':
        return 'Listener deleted'
      case 'policy-instance.create':
        return 'Policy created'
      case 'policy-instance.update':
        return 'Policy updated'
      case 'policy-instance.delete':
        return 'Policy deleted'
      case 'sidecar.create':
        return 'Sidecar created'
      case 'sidecar.update':
        return 'Sidecar updated'
      case 'sidecar.patch':
        return 'Sidecar patched'
      case 'sidecar.delete':
        return 'Sidecar deleted'
      default:
        return ''
    }
  }

  return {
    fetchAuditLogs,
    fetchUserConnections,
    getDescriptivePhraseForAction,
    getTimeSpan,
  }
}

export default useAuditLogs
