import { REPO_TYPES_THAT_SUPPORT_PORT_MULTIPLEXING } from '@jeeves/constants'

export const repoSupportsPortMultiplexing = (repoType: string) => {
  return REPO_TYPES_THAT_SUPPORT_PORT_MULTIPLEXING.some(repo => repo.typeName === repoType)
}

export const MYSQL_FAMILY_DEFAULT_ADVERTISED_VERSIONS = {
  mysql: '8.0.4',
  mariadb: '10.6.7',
}
