import { gql, useQuery } from '@apollo/client'

const SMART_PORTS = gql(
  `
    query SmartPorts($sidecarId: String!, $firstBindings: Int) {
      sidecar(id: $sidecarId) {
        id
        name
        listeners {
          id
          port
          repoType
          ... on MultiplexedListener {
            bindings(first: $firstBindings) {
              totalCount
            }
            ... on MySQLFamilyMultiplexedListener {
              config {
                advertisedVersion
                characterSet
              }
            }
          }
        }
      }
    }
  `
)

const useSmartPorts = options => {
  const { data, loading } = useQuery(SMART_PORTS, {
    ...options,
  })

  const getSmartPorts = () => {
    if (!data) return []

    let multiplexedPorts = data?.sidecar.listeners.filter(listener =>
      listener.hasOwnProperty('bindings')
    )
    multiplexedPorts = multiplexedPorts.map(multiplexedPort => ({
      ...multiplexedPort,
      numBindings: multiplexedPort.bindings.totalCount,
    }))

    return multiplexedPorts
  }

  return {
    loading,
    data: {
      smartPorts: getSmartPorts(),
    },
  }
}

export default useSmartPorts
