/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, Fragment } from 'react'
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/react'
import { useForm } from 'react-hook-form'
import { usePopup } from '@jeeves/hooks'
import { grey } from '@material-ui/core/colors'
import { getDocsURL } from '@jeeves/utils/docsURL'

import {
  Button,
  CardActions,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Typography,
  MenuItem,
  IconButton,
  Tooltip,
} from '@material-ui/core/'
import GetAppIcon from '@material-ui/icons/GetApp'
import IntegrationPanel from './AddFormPanel'
import { TextFieldWithToolTipController, UploadZone } from '../components'

const FullWidth = styled.div`
  width: 100%;
`

const Divider = styled(MuiDivider)`
  margin-top: -15px;
  margin-bottom: 15px;
  width: 100%;
`

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`

const ActionsContainer = styled(Grid)`
  justify-content: flex-end;
`

const SaveButtonContainer = styled.div`
  position: relative;
`

const Link = styled.a`
  text-decoration: none;
  color: #26459e;
  font-weight: 600;
`

const UploadZoneWrapper = styled.div`
  margin-top: 0.25rem;
  margin-bottom 1rem;
  display: flex;
  justify-content: center;
  padding: 1.25rem 1.5rem 1.5rem;
  border: 2px dashed ${grey[400]};
  border-radius: 0.375rem;
`

const createbase64SamlMetadataDocument = async files => {
  const samlMetadataFile = files?.[0]
  const samlMetaDataFileContent = await samlMetadataFile.text()

  return btoa(samlMetaDataFileContent)
}

const AddForm = ({ addIntegration, handleRemoveAddField }) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm()
  const { setPopup, popupTypes } = usePopup()
  const [displayDownload, setDisplayDownload] = useState(false)

  const samlMetadata = watch('samlMetadata')

  const onSubmit = async ({ displayName, samlMetadata }) => {
    try {
      const base64SamlMetadataDocument = await createbase64SamlMetadataDocument(samlMetadata)

      const integration = {
        identityProvider: 'adfs-2016',
        displayName,
        base64SamlMetadataDocument,
      }

      await handleOnAddIntegration(integration)
      setPopup(popupTypes.SUCCESS, 'Success!', {
        displayTime: 4000,
      })
      setDisplayDownload(true)
      // handleRemoveAddField()
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  const handleDisplayDownload = () => {
    setDisplayDownload(false)
    handleRemoveAddField()
  }

  const handleOnAddIntegration = async integration => {
    try {
      await addIntegration(integration)
    } catch {
    } finally {
    }
  }

  const DownloadForm = () => {
    return (
      <React.Fragment>
        <Typography
          css={{
            marginTop: '2.5rem',
            marginBottom: '1rem',
            textAlign: 'center',
          }}
        >
          Download the SAML certificate, then configure in ADFS.
        </Typography>
        <UploadZoneWrapper>
          <a href="/app/download/deploy/saml/cert.cer" target="_blank">
            <IconButton>
              <GetAppIcon fontSize="large"> </GetAppIcon>
            </IconButton>
          </a>
        </UploadZoneWrapper>
        <ActionsContainer item container xs={12}>
          <CardActions>
            <Button color="primary" size="small" onClick={handleDisplayDownload}>
              Cancel
            </Button>
            <SaveButtonContainer>
              <Button
                variant="contained"
                onClick={handleDisplayDownload}
                size="small"
                color="primary"
              >
                Done
              </Button>
            </SaveButtonContainer>
          </CardActions>
        </ActionsContainer>
      </React.Fragment>
    )
  }

  const CreateForm = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          css={{
            marginBottom: '1rem',
          }}
        >
          First, set up a new SAML app inside ADFS. See more instructions on this{' '}
          <Link href={getDocsURL()} target="_blank" rel="noopener noreferrer">
            here
          </Link>
          .
        </Typography>
        <Typography
          css={{
            marginBottom: '1rem',
          }}
        >
          Give your integration a display name.
        </Typography>

        <TextFieldWithToolTipController
          label="Display Name"
          tooltipText="Name for the integration."
          name="displayName"
          control={control}
        />
        <Typography
          css={{
            marginBottom: '1rem',
          }}
        >
          Select the version of ADFS you're using.
        </Typography>

        <TextFieldWithToolTipController
          select
          label="ADFS version"
          tooltipText="ADFS version."
          name="ADFSVersion"
          control={control}
        >
          {['Microsoft Server 2016 Datacenter'].map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextFieldWithToolTipController>

        <Typography
          css={{
            marginBottom: '1rem',
          }}
        >
          Upload the SAML metadata document you obtained from ADFS.
        </Typography>

        <UploadZone samlMetadata={samlMetadata} register={register} setValue={setValue} />

        <ActionsContainer item container xs={12}>
          <CardActions>
            <Button color="primary" size="small" onClick={handleRemoveAddField}>
              Cancel
            </Button>
            <SaveButtonContainer>
              <Button
                variant="contained"
                type="submit"
                size="small"
                color="primary"
                disabled={isSubmitting}
              >
                Save
              </Button>
              {isSubmitting && <StyledCircularProgress size={24} />}
            </SaveButtonContainer>
          </CardActions>
        </ActionsContainer>
      </form>
    )
  }

  return (
    <FullWidth>
      <Divider />
      {displayDownload ? <DownloadForm></DownloadForm> : <CreateForm></CreateForm>}
    </FullWidth>
  )
}

const AddModal = props => (
  <IntegrationPanel title={props.name} expanded={true}>
    <AddForm {...props} />
  </IntegrationPanel>
)

export default AddModal
