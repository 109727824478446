/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Collapse as Animation,
  Switch,
  TextField,
  Tooltip,
  Typography,
  Checkbox,
} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { TagList } from '@jeeves/components/TagList'

import DeleteDialog from '@jeeves/components/Delete'
import { ButtonLoading } from '@jeeves/components/Loading'
import { useMount } from '@jeeves/hooks'
import { Button, FlexGrow } from '@jeeves/components/Primitives'

const StyledButton = styled(Button)`
  margin-left: 10px;
`

const Text = styled(Typography)`
  font-size: 1rem;
  color: ${grey[700]};
  margin-bottom: 15px;
`

Text.defaultProps = {
  variant: 'caption',
}

export default ({ open, wrapper, onCancel, onSave, loading = false }) => {
  const [tags, setTags] = useState([])
  const [aliasEnabled, setAliasEnabled] = useState(wrapper.userEndpoint && true)
  const [userEndpoint, setUserEndpoint] = useState(wrapper.userEndpoint) // eslint-disable-line
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const inputRef = useRef()
  const history = useHistory()

  useMount(() => {
    setTags(wrapper.labels || [])
  })

  useEffect(() => {
    try {
      if (aliasEnabled) {
        inputRef.current.focus()
      }
    } catch (e) {}
  }, [aliasEnabled])

  const handleTagDelete = tag => setTags(tags.filter(t => t !== tag))

  const handleAddTag = tag => setTags([...tags, tag])

  const handleSave = () => {
    if (aliasEnabled) {
      onSave({ tags, userEndpoint })
    } else {
      onSave({ tags, userEndpoint: '' })
    }
  }

  const handleCancel = () => {
    onCancel()

    setTags(wrapper.labels || [])

    if (wrapper && wrapper.userEndpoint) {
      setAliasEnabled(true)
      setUserEndpoint(wrapper.userEndpoint)
    } else {
      setAliasEnabled(false)
      setUserEndpoint('')
    }
  }

  const passingHealthChecks = wrapper.health === 'online'

  return (
    <Dialog open={open} onClose={handleCancel} css={{ minWidth: '350px' }} fullWidth>
      <Grid
        container
        css={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '22px' }}
      >
        <DialogTitle>{'Edit Sidecar'}</DialogTitle>
      </Grid>

      <DialogContent>
        <FormGroup row>
          <FormControlLabel
            label="Endpoint alias"
            control={
              <Checkbox
                checked={aliasEnabled}
                onChange={e => setAliasEnabled(e.target.checked)}
                color="primary"
              />
            }
          />
        </FormGroup>
        <Animation in={aliasEnabled}>
          <TextField
            inputRef={inputRef}
            disabled={!aliasEnabled}
            onChange={e => setUserEndpoint(e.target.value)}
            value={userEndpoint}
            css={{ marginBottom: '16px', marginLeft: '2rem' }}
            placeholder="Enter endpoint alias"
          />
        </Animation>

        <TagList tags={tags} onAdd={handleAddTag} onDelete={handleTagDelete} />
        <Text css={{ marginTop: 0 }}>
          Tags cannot have empty spaces or commas. Use <code>Enter</code> to add tags.
        </Text>
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between" css={{ padding: '15px' }}>
          {passingHealthChecks ? (
            <Tooltip title={`Cannot delete, the sidecar is ${wrapper.health}`}>
              <div>
                <StyledButton color="secondary" variant="outlined" disabled={true}>
                  Delete
                </StyledButton>
              </div>
            </Tooltip>
          ) : (
            <StyledButton
              color="secondary"
              variant="outlined"
              onClick={() => setDeleteDialogOpen(true)}
            >
              Delete
            </StyledButton>
          )}

          <FlexGrow />

          <StyledButton color="primary" onClick={handleCancel}>
            Cancel
          </StyledButton>
          <div css={{ position: 'relative' }}>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={loading}
              css={{ marginLeft: 0 }}
            >
              Save
            </StyledButton>
            {loading && <ButtonLoading />}
          </div>
        </Grid>

        <DeleteDialog
          open={deleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          element={wrapper}
          onDelete={() => {
            history.push('/sidecars')
          }}
          elementMeta={{
            name: get(wrapper, 'name'),
            type: 'wrapper',
          }}
        />
      </DialogActions>
    </Dialog>
  )
}
