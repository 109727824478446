import React from 'react'

import {
  CyralIcon,
  DenodoIcon,
  DremioIcon,
  DuoIcon,
  DynamoDBIcon,
  DynamoDBStreamsIcon,
  GaleraIcon,
  LookerIcon,
  MariaDBIcon,
  MongoDBIcon,
  MySQLIcon,
  OracleIcon,
  PagerdutyIcon,
  PagerdutyLetterIcon,
  PostgreSQLIcon,
  RedshiftIcon,
  RestIcon,
  S3Icon,
  SnowflakeIcon,
  SQLServerIcon,
  TableauIcon,
} from '@jeeves/new-components'

const ServiceIcon = ({ type, ...rest }) => {
  let Icon

  switch (type) {
    case 'cyral':
      Icon = CyralIcon
      break
    case 'looker':
      Icon = LookerIcon
      break
    case 'tableau':
      Icon = TableauIcon
      break
    case 'denodo':
      Icon = DenodoIcon
      break
    case 'dremio':
      Icon = DremioIcon
      break
    case 'duo':
      Icon = DuoIcon
      break
    case 'dynamodb':
      Icon = DynamoDBIcon
      break
    case 'dynamodbstreams':
      Icon = DynamoDBStreamsIcon
      break
    case 'galera':
      Icon = GaleraIcon
      break
    case 'pagerduty':
      Icon = PagerdutyIcon
      break
    case 'pagerduty-letter':
      Icon = PagerdutyLetterIcon
      break
    case 'postgresql':
      Icon = PostgreSQLIcon
      break
    case 'mongodb':
      Icon = MongoDBIcon
      break
    case 'redshift':
      Icon = RedshiftIcon
      break
    case 'rest':
      Icon = RestIcon
      break
    case 'mariadb':
      Icon = MariaDBIcon
      break
    case 'mysql':
      Icon = MySQLIcon
      break
    case 'oracle':
      Icon = OracleIcon
      break
    case 's3':
      Icon = S3Icon
      break
    case 'snowflake':
      Icon = SnowflakeIcon
      break
    case 'sqlserver':
      Icon = SQLServerIcon
      break
    default:
      throw new Error(`Unable to find logo for type: ${type}`)
  }

  return <Icon {...rest} />
}

export default ServiceIcon
