import React from 'react'
import { css } from '@emotion/react'
import { TableRow } from '@material-ui/core'

import { TableCell } from '@jeeves/components/Table'

const Row = ({ account, getDate }) => {
  return (
    <>
      <TableRow hover>
        <TableCell preserveHeight>{account.clientId}</TableCell>
        <TableCell preserveHeight>{getDate(account.clientId)}</TableCell>
      </TableRow>
    </>
  )
}

export default Row
