export const categoryTitleMap: { [key: string]: string } = {
  access_rules: 'Access rule',
  approval: 'Approval',
  network_access: 'Network shield rule',
  datamap: 'Data mapping',
}

export const typeTitleMap: { [key: string]: string } = {
  create: 'created',
  update: 'updated',
  update_priority: 'priority updated',
  delete: 'deleted',
  request: 'requested',
  grant: 'granted',
  reject: 'rejected',
  revoke: 'revoked',
  enable: 'enabled',
  disable: 'disabled',
}
