import { EnhancedAuditLog } from '@jeeves/graphql/graphql'
import { Box, Typography } from '@mui/material'
import { LogItem } from '../LogItem'

interface LogListProps {
  logs: EnhancedAuditLog[]
}

const EmptyState = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        No logs found
      </Typography>
    </Box>
  )
}

const LogList = ({ logs }: LogListProps) => {
  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'cyralColors.grey.200',
        backgroundColor: 'cyralColors.white',
        borderRadius: theme => theme.radii.base,
      }}
    >
      <Box
        sx={{
          padding: '1rem 2rem',
          backgroundColor: 'cyralColors.grey.100',
        }}
      >
        <Typography variant="h4" sx={{ color: 'cyralColors.grey.500' }}>
          Audit Logs
        </Typography>
      </Box>
      <Box>
        {logs.length > 0 ? (
          logs.map(enhancedAuditLog => {
            const { id, action, description, timestamp } = enhancedAuditLog
            return (
              <LogItem
                key={id}
                action={action}
                markdownText={description?.textWithMarkdown || ''}
                timestamp={timestamp}
              />
            )
          })
        ) : (
          <EmptyState />
        )}
      </Box>
    </Box>
  )
}

export default LogList
