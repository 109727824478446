import React, { useState } from 'react'

import { Box, Stack, Typography, Collapse } from '@mui/material'

import { ExpandButton } from '@jeeves/components/Table'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { Alerts, TLS, AccessPortal, Authentication, Logs, PolicyEnforcement } from './components'
import { REPO_CONFIGS, hasRepoConfig } from './helpers/repoConfigs'

const ConfigContainer = ({ configName, expanded, setExpanded, children }) => {
  return (
    <Box
      sx={{
        border: 1,
        borderColor: 'cyralColors.grey.200',
        borderRadius: theme => theme.radii.base,
        p: 3,
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ color: 'text.primary' }} variant="h3">
            {configName}
          </Typography>
          <ExpandButton expanded={expanded} onClick={() => setExpanded(!expanded)} />
        </Stack>
        <Collapse in={expanded} unmountOnExit>
          {children}
        </Collapse>
      </Stack>
    </Box>
  )
}

const Config = ({ config, identityProviders, repo }) => {
  const [accessPortalExpanded, setAccessPortalExpanded] = useState(false)
  const [authenticationExpanded, setAuthenticationExpanded] = useState(false)
  const [activityLogsExpanded, setActivityLogsExpanded] = useState(false)
  const [alertsExpanded, setAlertsExpanded] = useState(false)
  const [policyEnforcementExpanded, setPolicyEnforcementExpanded] = useState(false)
  const [TLSExpanded, setTLSExpanded] = useState(false)

  const { repoId, repoType } = useRepositoryDetailContext()

  return (
    <Box sx={{ mx: 8, my: 4 }}>
      <Stack spacing={2}>
        <ConfigContainer
          configName={REPO_CONFIGS.accessPortal.displayName}
          expanded={accessPortalExpanded}
          setExpanded={setAccessPortalExpanded}
        >
          <AccessPortal accessPortalData={config?.accessPortal} repo={repo} />
        </ConfigContainer>

        <ConfigContainer
          configName={REPO_CONFIGS.authentication.displayName}
          expanded={authenticationExpanded}
          setExpanded={setAuthenticationExpanded}
        >
          <Authentication
            repoId={repoId}
            authData={config?.authentication}
            identityProviders={identityProviders}
          ></Authentication>
        </ConfigContainer>

        <ConfigContainer
          configName={REPO_CONFIGS.dataActivityLogs.displayName}
          expanded={activityLogsExpanded}
          setExpanded={setActivityLogsExpanded}
        >
          <Logs repoId={repoId} repoType={repoType} logsData={config?.dataActivityLogs} />
        </ConfigContainer>

        <ConfigContainer
          configName={REPO_CONFIGS.alerts.displayName}
          expanded={alertsExpanded}
          setExpanded={setAlertsExpanded}
        >
          <Alerts repoId={repoId} alertsData={config?.alerts} />
        </ConfigContainer>

        {hasRepoConfig(REPO_CONFIGS.policyEnforcement.id, repoType) && (
          <ConfigContainer
            configName={REPO_CONFIGS.policyEnforcement.displayName}
            expanded={policyEnforcementExpanded}
            setExpanded={setPolicyEnforcementExpanded}
          >
            <PolicyEnforcement enforcementData={config?.enforcement} />
          </ConfigContainer>
        )}

        <ConfigContainer
          configName={REPO_CONFIGS.tls.displayName}
          expanded={TLSExpanded}
          setExpanded={setTLSExpanded}
        >
          <TLS repoId={repoId} TLSData={config?.TLS} />
        </ConfigContainer>
      </Stack>
    </Box>
  )
}

export default Config
