import { useQuery, gql } from '@apollo/client'

const APPROVAL_CONNECTION_FIELDS = gql`
  fragment approvalConnectionFields on ApprovalConnection {
    edges {
      cursor
      node {
        id
        createdDate
        validFrom
        validUntil
        status
        requester {
          type
          name
        }
        userAccount {
          id
          name
        }
        ... on GrantedApproval {
          sensitiveFields
          effectiveStartDate
          granter {
            type
            name
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
`

const FETCH_APPROVALS = gql`
  query FetchApprovals(
    $repoId: String!
    $filter: ApprovalFilterInput
    $first: Int
    $after: String
  ) {
    repo(id: $repoId) {
      id
      ... on PostgreSQLRepo {
        approvals(filter: $filter, first: $first, after: $after) {
          ...approvalConnectionFields
        }
      }
      ... on DenodoRepo {
        approvals(filter: $filter, first: $first, after: $after) {
          ...approvalConnectionFields
        }
      }
      ... on MongoDBRepo {
        approvals(filter: $filter, first: $first, after: $after) {
          ...approvalConnectionFields
        }
      }
      ... on NormalRepo {
        approvals(filter: $filter, first: $first, after: $after) {
          ...approvalConnectionFields
        }
      }
      ... on OracleRepo {
        approvals(filter: $filter, first: $first, after: $after) {
          ...approvalConnectionFields
        }
      }
      ... on RedshiftRepo {
        approvals(filter: $filter, first: $first, after: $after) {
          ...approvalConnectionFields
        }
      }
      ... on SQLServerRepo {
        approvals(filter: $filter, first: $first, after: $after) {
          ...approvalConnectionFields
        }
      }
    }
  }
  ${APPROVAL_CONNECTION_FIELDS}
`
const useApprovals = ({ variables }) => {
  const query = useQuery(FETCH_APPROVALS, {
    notifyOnNetworkStatusChange: true,
    variables,
  })

  return query
}

export default useApprovals
