import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  Switch,
  FormGroup,
} from '@mui/material'
import { Button } from '@jeeves/new-components'
import { LearnMore } from './LearnMore'

import { gql, useMutation } from '@apollo/client'

const UPDATE_TLS = gql`
  mutation UpdateRepoTLSConfig($repoId: ID!, $input: UpdateRepoTLSConfigInput!) {
    updateRepoTLSConfig(repoId: $repoId, input: $input) {
      repo {
        id
        name
        config {
          TLS {
            enableClientSidecarTLS
            enableSidecarRepoTLS
          }
        }
      }
    }
  }
`

export const TLS = ({ repoId = '', TLSData }) => {
  const {
    control,
    reset,
    formState: { isDirty, isSubmitting, isSubmitSuccessful },
    handleSubmit,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      enableClientSidecarTLS: TLSData?.enableClientSidecarTLS ?? false,
      enableSidecarRepoTLS:
        TLSData?.enableSidecarRepoTLS === 'enable' ||
        TLSData?.enableSidecarRepoTLS === 'enableAndVerifyCert'
          ? true
          : false,
      verifyServerCertificate:
        TLSData?.enableSidecarRepoTLS === 'enableAndVerifyCert' ? true : false,
    },
  })

  const [updateTLS, { data, loading, error }] = useMutation(UPDATE_TLS)

  const onSubmit = async ({
    enableClientSidecarTLS,
    enableSidecarRepoTLS,
    verifyServerCertificate,
  }) => {
    try {
      await updateTLS({
        variables: {
          repoId,
          input: {
            enableClientSidecarTLS,
            enableSidecarRepoTLS: !enableSidecarRepoTLS
              ? 'disable'
              : verifyServerCertificate
              ? 'enableAndVerifyCert'
              : 'enable',
          },
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  const handleCancel = () => {
    reset()
  }

  const handleEnableSidecarRepoTLSToggle = () => {
    const enableSidecarRepoTLS = getValues('enableSidecarRepoTLS')
    if (enableSidecarRepoTLS === true) {
      setValue('verifyServerCertificate', false)
    }
  }

  const enableSidecarRepoTLS = watch('enableSidecarRepoTLS')

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues())
    }
  }, [isSubmitSuccessful, reset, getValues])
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack sx={{ justifyContent: 'space-between' }}>
        <Stack spacing={2} sx={{ pb: 7 }}>
          <Box>
            <Controller
              name="enableClientSidecarTLS"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormControlLabel
                  control={<Switch name={name} onChange={onChange} checked={value} />}
                  label={
                    <Typography variant="h4" sx={{ color: 'text.primary' }}>
                      Enable TLS between the client and sidecar.{' '}
                      <LearnMore docsPath="/repo-advanced-settings#client-tls" />
                    </Typography>
                  }
                />
              )}
            />
          </Box>
          <Stack spacing={1}>
            <Box>
              <Controller
                name="enableSidecarRepoTLS"
                control={control}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormControlLabel
                    sx={{ ml: '-11px' }}
                    control={
                      <Switch
                        name={name}
                        onChange={e => {
                          handleEnableSidecarRepoTLSToggle()
                          onChange(e)
                        }}
                        checked={value}
                      />
                    }
                    label={
                      <Typography variant="h4" sx={{ color: 'text.primary' }}>
                        Enable TLS between the sidecar and repository.{' '}
                        <LearnMore docsPath="/repo-advanced-settings#repository-tls" />
                      </Typography>
                    }
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                name="verifyServerCertificate"
                control={control}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <FormGroup sx={{ ml: 6 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!enableSidecarRepoTLS}
                          name={name}
                          onChange={onChange}
                          checked={value}
                        />
                      }
                      label="Verify server certificate"
                    />
                  </FormGroup>
                )}
              />
            </Box>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2.5} sx={{ justifyContent: 'flex-end' }}>
          <Button variant="text" onClick={handleCancel} disabled={!isDirty}>
            Cancel
          </Button>
          <Button variant="contained" type="submit" loading={isSubmitting} disabled={!isDirty}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}
