/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useEffect, useState } from 'react'

import useSplunk from './hooks/useSplunk'
import { useAuth, useMount } from '@jeeves/hooks'
import {
  ErrorState,
  ZeroState,
  AddIntegrationButton,
  IntegrationDialogContent,
  IntegrationLoading,
  IntegrationList,
} from '../../primitives'
import { IntegrationForm } from './IntegrationForm'
import logo from './splunk.png'

const Splunk = () => {
  const {
    updateIntegrations,
    integrations,
    addIntegration,
    putIntegration,
    deleteIntegration,
  } = useSplunk()

  const [adding, setAdding] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const { hasPermission } = useAuth()

  const fetchIntegrations = async () => {
    setLoading(true)
    try {
      await updateIntegrations()
    } catch (e) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  useMount(fetchIntegrations)

  const createIntegration = async payload => {
    await addIntegration({
      name: payload.name,
      host: payload.host,
      useTLS: payload.useTLS,
      hecPort: payload.hecPort,
      accessToken: payload.accessToken,
      index: payload.index,
    })

    setAdding(false)
  }

  const saveIntegration = async (id, payload) => {
    await putIntegration(payload, id)
  }

  const _deleteIntegration = async id => {
    await deleteIntegration(id)
  }

  return (
    <IntegrationDialogContent>
      <IntegrationList>
        {loading && <IntegrationLoading />}
        {!loading && error && <ErrorState />}
        {!loading && !error && integrations.length === 0 && <ZeroState />}
        {integrations.map(integration => (
          <IntegrationForm
            key={integration.id}
            integration={integration}
            onSave={saveIntegration}
            onDelete={_deleteIntegration}
          />
        ))}
      </IntegrationList>
      {adding && (
        <IntegrationForm
          integration={null}
          creating={true}
          onSave={createIntegration}
          onCancel={() => setAdding(false)}
        />
      )}
      <AddIntegrationButton
        isOpen={adding}
        setOpen={setAdding}
        disabled={!hasPermission('integrations:create') || adding}
      />
    </IntegrationDialogContent>
  )
}

Splunk.metadata = {
  name: 'Splunk',
  summary: 'Send logs to Splunk.',
  enabled: true,
  category: 'Logging',
  slug: 'splunk',
  logo,
}

export default Splunk
