import React, { useState } from 'react'
import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'
import { get as lodashGet } from 'lodash'

import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'

const useSumoLogic = () => {
  const { setPopup, popupTypes } = usePopup()
  const { getTokenSilently, user } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const [integrations, setIntegrations] = useState([
    // {
    //   name: 'prod',
    //   URL: 'es.healthyheartsis.us:9243',
    //   severity: { low: true, med: true, high: true, operations: false },
    // },
    // {
    //   name: 'dev',
    //   URL: 'dev.es.healthyheartsis.us:9243',
    //   severity: { low: true, med: true, high: true, operations: false },
    // },
  ])

  const [addField, setAddField] = useState(integrations.length === 0 ? 1 : 0)

  const handleCreateAddField = () => setAddField(1)
  const handleRemoveAddField = () => setAddField(0)

  const addIntegration = async input => {
    // console.log(`integration.inputs: ${JSON.stringify(input)}`)
    // setIntegrations([
    //   ...integrations,
    //   { name: input.name, URL: input.URL, severity: input.severity },
    // ])
    try {
      const integration = await ec.post('/integrations/sumologic', input).then(res => res.data)
      updateIntegrations()
      return integration
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  const putIntegration = async (input, integrationID) => {
    try {
      const integration = await ec
        .put(`/integrations/sumologic/${integrationID}`, input)
        .then(res => res.data)
      updateIntegrations()
      return integration
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
  }

  const deleteIntegration = async integrationID => {
    try {
      const integration = await ec
        .delete(`/integrations/sumologic/${integrationID}`)
        .then(res => res.data)
      updateIntegrations()
      return integration
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
  }

  const updateIntegrations = async () => {
    // setIntegrations([
    //   { name: 'test', webhook: 'https://abc.com' },
    //   { name: 'test3', webhook: 'https://def.com' },
    // ])
    try {
      const integrations = await ec.get('/integrations/sumologic').then(res => {
        // console.log(res.data)
        setIntegrations(res.data)
      })
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
  }

  return {
    handleCreateAddField,
    handleRemoveAddField,
    updateIntegrations,
    integrations,
    addIntegration,
    putIntegration,
    deleteIntegration,
    addField,
    user,
    lodashGet,
  }
}

export default useSumoLogic
