import { Box, Stack, Typography } from '@mui/material'
import { formatLocalDate, formatLocalTimeWithSeconds } from '@jeeves/utils/datetime'
import { Markdown } from '../Markdown'
import { categoryTitleMap, typeTitleMap } from './constants'
import { EnhancedAuditLogActionCategory } from '@jeeves/graphql/graphql'

interface LogItemProps {
  action: {
    category: string
    type: string
  }
  timestamp: string
  markdownText: string
}

const getTitle = ({ category, type }: { category: string; type: string }) => {
  const isToggle = type === 'enable' || type === 'disable'

  if (category === EnhancedAuditLogActionCategory.NetworkAccess && isToggle) {
    return `Network shield ${typeTitleMap[type]}`
  }

  return `${categoryTitleMap[category]} ${typeTitleMap[type]}`
}

const LogItem = ({ action, timestamp, markdownText }: LogItemProps) => {
  const formattedTimeStampLocalDate = formatLocalDate(timestamp)
  const formattedTimeStampLocalTime = formatLocalTimeWithSeconds(timestamp)

  return (
    <Box
      sx={{
        p: '1rem 2rem',
        borderTop: '1px solid',
        borderColor: 'cyralColors.grey.200',
      }}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '2rem' }}
      >
        <Stack spacing={2} sx={{ flexGrow: 1 }}>
          <Typography variant="h6" sx={{ color: 'cyralColors.grey.500' }}>
            {getTitle(action)}
          </Typography>
          <Markdown text={markdownText} />
        </Stack>
        <Stack spacing={1} sx={{ justifyContent: 'center', textAlign: 'end', flexShrink: 0 }}>
          <Typography variant="h6" sx={{ color: 'cyralColors.grey.400' }}>
            {formattedTimeStampLocalDate}
          </Typography>
          <Typography variant="body2" sx={{ color: 'cyralColors.grey.400' }}>
            {formattedTimeStampLocalTime}
          </Typography>
        </Stack>
      </Box>
    </Box>
  )
}

export default LogItem
