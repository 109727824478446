import * as sv from 'semver'
import * as path from 'path'

// getDocsURL returns the documentation URL corresponding to the version of the control plane
// The function takes an optional argument object with the following properties:
//   `docsPath`: used to link to a specific page/section in the documentation
//     (e.g. '/policy/datamap#add-or-edit-a-data-map')
//   `useVersionedDocsURL`: if true, the version of the control plane will be used to determine 
//     the version of the documentation to link to -- otherwise, the unversioned docs URL will be returned
export const getDocsURL = ({ docsPath = '', useVersionedDocsURL = true } = {}) => {
  const docsBaseURL = _getDocsBaseURL(useVersionedDocsURL)
  return new URL(path.join(docsBaseURL, docsPath)).toString()
}

// _getDocsVersion returns the version of the public docs corresponding to the given control plane version
//   This entails trimming the given CP version down to its minor version.
//   If the given CP version not a valid semver, the function returns ''.
export const _getDocsVersion = controlPlaneVersion => {
  const parsedCPVersion = sv.parse(controlPlaneVersion)
  return parsedCPVersion ? `v${sv.major(parsedCPVersion)}.${sv.minor(parsedCPVersion)}` : ''
}

const _getDocsBaseURL = useVersionedDocsURL => {
  const unversionedDocsBaseURL = window?._env_?.documentation_base_url || 'https://cyral.com/docs'
  const controlPlaneVersion = window?._env_?.control_plane_version || ''
  const docsVersion = useVersionedDocsURL ? _getDocsVersion(controlPlaneVersion) : ''
  return new URL(path.join(unversionedDocsBaseURL, docsVersion)).toString()
}
