import React, { useState, useMemo, useEffect } from 'react'
import { useQuery, gql, NetworkStatus } from '@apollo/client'
import { parse, end } from 'iso8601-duration'
import * as _ from 'lodash'

import { useAuth } from '@jeeves/components/Auth'

const OPAQUE_TOKEN = gql`
  query OpaqueToken($sessionId: String!) {
    opaqueToken(sessionId: $sessionId)
  }
`

export const ACCESS_PORTAL = gql`
  query User(
    $first: Int
    $after: String
    $filters: IdentityReposFilters
    $accessibility: ReposAccessibilityState!
  ) {
    user {
      ... on ControlPlaneUser {
        id
        repos(first: $first, after: $after, filters: $filters, accessibility: $accessibility) {
          edges {
            node {
              id
              name
              type
              tags
              config {
                authentication {
                  allowNativeAuthentication
                  otpDbuser
                }
                TLS {
                  enableClientSidecarTLS
                }
              }
              ... on MongoDBReplicaSetRepo {
                replicaSetName
              }
            }
            accessibleUserAccounts {
              edges {
                node {
                  id
                  name
                  credentials {
                    ... on AWSIAMUserAccountCredentials {
                      roleARN
                    }
                  }
                  ... on MongoDBUserAccount {
                    authenticationDatabase
                  }
                }
                validUntil
                accessRestrictionSummaries {
                  name
                  type
                }
              }
            }
            accessPortalBindingRelationship {
              edge {
                node {
                  id
                  ... on ClusterBinding {
                    boundListenersRelationship {
                      edges {
                        node {
                          id
                          port
                        }
                      }
                    }
                  }
                  ... on S3Binding {
                    listenerSet {
                      proxyListener {
                        id
                        port
                      }
                      browserListener {
                        id
                        port
                      }
                    }
                  }
                  ... on SingleListenerBinding {
                    listener {
                      id
                      port
                    }
                  }
                }
                sidecar {
                  name
                  endpoint
                  userEndpoint
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
    slackNotificationIntegrations {
      id
      name
    }
  }
`

const useAccessPortal = options => {
  const { data, loading, error, fetchMore, refetch, networkStatus } = useQuery(
    ACCESS_PORTAL,
    options
  )
  const { user } = useAuth()
  const { data: opaqueTokenData, loading: opaqueTokenLoading } = useQuery(OPAQUE_TOKEN, {
    variables: { sessionId: user.session_state },
  })

  return {
    loading: loading || opaqueTokenLoading,
    error,
    data: {
      opaqueToken: opaqueTokenData?.opaqueToken,
      repoEdgesUserCanAccess: data?.user?.repos?.edges,
      hasNextPage: data?.user?.repos?.pageInfo?.hasNextPage,
    },
    getNextPage:
      data?.user?.repos?.pageInfo?.hasNextPage &&
      (() => fetchMore({ variables: { after: data?.user?.repos?.pageInfo?.endCursor } })),
    refetch,
    fetchingMore: networkStatus === NetworkStatus.fetchMore,
    firstLoading: networkStatus === NetworkStatus.loading,
  }
}

export default useAccessPortal
