export const supportedAlertTypes = [
  'authenticationFailure',
  'portScan',
  'dql',
  'ddl',
  'dcl',
  'privileged_commands',
  'full_scan',
  'policyViolation',
]
