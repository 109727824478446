import React from 'react'
import { Box, Stack, Typography, CircularProgress } from '@mui/material'
import useSmartPorts from '@jeeves/pages/WrapperDetail/hooks/useSmartPorts'
import { useSidecarDetailContext } from '@jeeves/pages/WrapperDetail/contexts/SidecarDetailContext'
import SmartPort from './SmartPort'

const SmartPorts = () => {
  const { sidecarId } = useSidecarDetailContext()
  const {
    loading,
    data: { smartPorts },
  } = useSmartPorts({
    variables: {
      sidecarId,
      firstBindings: 1,
    },
  })

  if (loading)
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )

  return (
    <Stack spacing={2}>
      <Typography variant="h3" sx={{ color: 'text.secondary' }}>
        Smart Ports
      </Typography>
      {smartPorts.length === 0 ? (
        <EmptyState />
      ) : (
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
          {smartPorts.map(smartPort => (
            <Box gridColumn="span 4" key={smartPort.id}>
              <SmartPort smartPort={smartPort} />
            </Box>
          ))}
        </Box>
      )}
    </Stack>
  )
}

const EmptyState = () => (
  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
    No Smart Ports have been configured.
  </Typography>
)

export default SmartPorts
