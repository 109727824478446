import * as React from 'react'
import { Controller, useForm, FormProvider } from 'react-hook-form'
import { useQuery, gql } from '@apollo/client'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Divider from '@mui/material/Divider'

import Radio from '@mui/material/Radio'
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'

import {
  Dialog,
  Button,
  Input,
  InputLabel,
  DialogActions,
  IconButton,
} from '@jeeves/new-components'

import {
  credentialStorageTypes,
  authenticationMethods,
  getAuthenticationMethods,
} from '@jeeves/pages/RepositoryDetail/Tabs/UserAuthentication/helpers'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

const SelectCredentialType = ({
  nextStep,
  initialSelectedCredentialType,
  setDatabaseAccountInfo,
  footer,
}) => {
  const { repoType } = useRepositoryDetailContext()

  const methods = useForm({
    defaultValues: {
      credentialType: initialSelectedCredentialType,
    },
  })

  const { control, handleSubmit, formState } = methods

  const authenticationMethods = getAuthenticationMethods(repoType)

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      nextStep()
    }
  }, [formState, nextStep])

  const onSubmit = ({ credentialType }) => {
    setDatabaseAccountInfo(info => {
      return {
        ...info,
        credentials: {
          ...info.credentials,
          type: credentialType,
          // ...(credentialType === initialSelectedCredentialType && databaseAccountInfo.credentials),
        },
      }
    })
  }

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <FormControl component="fieldset" fullWidth>
            {/* <FormControl variant="standard"> */}
            {/* <FormLabel id="demo-radio-buttons-group-label">Select a connection driver</FormLabel> */}
            {/* <InputLabel id="demo-radio-buttons-group-label">Select a connection driver</InputLabel> */}

            <Controller
              name="credentialType"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field} aria-labelledby="demo-radio-buttons-group-label">
                  <Stack spacing={3}>
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                        gap: 2,
                      }}
                    >
                      {credentialStorageTypes.map(item => {
                        const { label, value, avatar } = item

                        return (
                          <CredentialTypeCard
                            key={value}
                            value={value}
                            label={label}
                            avatar={avatar}
                          />
                        )
                      })}
                    </Box>

                    {authenticationMethods.length > 0 && (
                      <>
                        <Divider
                          sx={{
                            typography: 'h4',
                            color: 'cyralColors.grey.300',
                            '&:before, &:after': {
                              borderTopColor: 'cyralColors.grey.300',
                            },
                          }}
                        >
                          OR
                        </Divider>

                        <Box
                          sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(3, 1fr)',
                            gap: 2,
                          }}
                        >
                          {authenticationMethods.map(item => {
                            const { label, value, avatar } = item

                            return (
                              <CredentialTypeCard
                                key={value}
                                value={value}
                                label={label}
                                avatar={avatar}
                              />
                            )
                          })}
                        </Box>
                      </>
                    )}
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>

          {footer}
        </Stack>
      </Box>
    </FormProvider>
  )
}

const CredentialTypeCard = ({ label, value, avatar }) => {
  const radioGroup = useRadioGroup()
  const isSelected = value === radioGroup.value

  const Avatar = avatar

  return (
    <Card
      component="label"
      variant="outlined"
      role="button"
      sx={{
        cursor: 'pointer',
        transition: theme =>
          theme.transitions.create(['border-color', 'background-color', 'box-shadow'], {
            duration: theme.transitions.duration.shorter,
          }),

        ...(isSelected && {
          borderColor: 'primary.main',
          boxShadow: theme => `${theme.palette.primary.main} 0 0 0 1px`,
        }),
      }}
    >
      <Radio
        value={value}
        inputProps={{
          'aria-label': 'A',
        }}
        sx={{
          opacity: 0,
          position: 'absolute',
        }}
      />
      <CardHeader
        // action={'action'}
        // subheader={'subheader'}
        avatar={<Avatar sx={{ fontSize: 40 }} />}
        title={
          <Typography variant="h4" sx={{ color: 'text.primary' }}>
            {label}
          </Typography>
        }
        disableTypography
        sx={{
          flexDirection: 'column',

          '& .MuiCardHeader-avatar': {
            marginRight: 0,
            marginBottom: 2,
          },
        }}
      />
    </Card>
  )
}

export default SelectCredentialType
