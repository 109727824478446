/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled'
import { jsx, css } from '@emotion/react'
import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@material-ui/core'
import { ActionButton, Button } from '@jeeves/components/Primitives'
import { useAuth } from '@jeeves/hooks'
import { useAccounts } from './useAccounts'
import { IconCopyButton } from '@jeeves/components/CopyButton'

const Label = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: bold;
  width: 30%;
  min-width: 160px;
`

const Value = styled(Typography)`
  flex-grow: 1;
  overflow-wrap: anywhere;
`

const LabelValuePair = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`

export const Add = ({ sidecarId, onCreate }) => {
  const [open, setOpen] = useState(false)
  const [account, setAccount] = useState({})
  const { hasPermission } = useAuth()
  const { createSidecarAccount } = useAccounts()

  const handleOpen = async () => {
    try {
      const resp = await createSidecarAccount(sidecarId)
      setAccount(resp)
      setOpen(true)
    } catch (e) {}
  }

  const handleClose = () => {
    onCreate()
    setAccount({})
    setOpen(false)
  }

  return (
    <React.Fragment>
      <ActionButton onClick={handleOpen} disabled={!hasPermission('sidecar:update')} />
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="h6">New Client Secret Generated</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography css={{ marginBottom: '1rem' }} variant="subtitle2">
            Make sure to copy your new client secret. You won't be able to see it again.
          </Typography>
          <LabelValuePair>
            <Label>Client ID: </Label>
            <Value>{account.clientId}</Value>
            <IconCopyButton copyText={account.clientId}></IconCopyButton>
          </LabelValuePair>
          <LabelValuePair>
            <Label>Client Secret: </Label>
            <Value>{account.clientSecret}</Value>
            <IconCopyButton copyText={account.clientSecret}></IconCopyButton>
          </LabelValuePair>
          {/* <Typography variant="subtitle2">{`Client ID: ${account.clientId}`}</Typography>
          <Typography variant="subtitle2">{`Client Secret: ${account.clientSecret}`}</Typography> */}
        </DialogContent>
        <DialogActions
          css={t => css`
            padding-bottom: ${t.spacing.unit * 3};
            padding-left: ${t.spacing.unit * 2};
            padding-right: ${t.spacing.unit * 2};
            padding-top: 0;
            justify-content: flex-end;
          `}
        >
          <Button color="primary" onClick={handleClose}>
            Done
          </Button>

          {/* <div css={{ position: 'relative' }}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={hasError || loading}
            >
              Track
            </Button>
            {loading && <ButtonLoading />}
          </div> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
