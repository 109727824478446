import React from 'react'
import { renderRoutes } from 'react-router-config'
import { ThemeProvider } from '@mui/material/styles'
import { v5Theme } from '@jeeves/theme'

// Dashboard layout as Private Route
import Dashboard from '../components/Dashboard'
import ContentViewer from '../components/ContentViewer'
import ContentDownloader from '../components/ContentDownloader'
import PrivateRoute from '../components/PrivateRoute'
import RootPath from '../components/RootPath'

// Pages/Routes
import { Account, Users } from './Account'

import AccessRequests from './AccessRequests'
import NewAccessRequests from './AccessRequests/NewAccessRequests'
import Profile from './Profile'
import NotFound from './NotFound'
import Unauthorized from './Unauthorized'
import Help from './Help'
import Repositories from './Repositories'
import RepositoryDetail from './RepositoryDetail'
import AdminProfile from './Admin/Profile'
import Integrations from './Integrations'
import AccessPortal from './AccessPortal'
import { Wrappers, WrappersInstructions } from './Wrappers'
import WrapperDetail from './WrapperDetail'
import Policies from './Policies'
import NewPolicies from './NewPolicies'
import Threats from './Threats'
import Alerts from './Alerts'
import Identities from './Identities'
import Home from './Home'
import EtcdViewer from './EtcdViewer'
import CLIHome from './CLIHome'
import Plans from './Plans'
import Setup from './Setup'
import Sandbox from './Sandbox'
import AuditLogs from './AuditLogs'
import ServiceAccounts from './ServiceAccounts'
import DataLabels from './DataLabels'
import S3Browser from './S3Browser'
import Overview from './Overview'

import Logout from './Logout'
const Root = ({ route }) => {
  return renderRoutes(route.routes)
}

export default [
  {
    render: props => <PrivateRoute component={Root} path="/" {...props} />,
    routes: [
      // Dashboard
      {
        path: '/',
        exact: true,
        component: RootPath,
      },
      {
        path: '/view/*',
        component: ContentViewer,
      },
      {
        path: '/download/*',
        component: ContentDownloader,
      },
      {
        path: '/approveGrant',
        exact: false,
        component: NewAccessRequests,
        render: props => (
          <PrivateRoute
            component={NewAccessRequests}
            path="/approveGrant"
            requiredPermission={'repo:update'}
            {...props}
          />
        ),
      },
      {
        path: '/revokeGrant',
        exact: false,
        render: props => (
          <PrivateRoute
            component={() => <NewAccessRequests isRevoke {...props}></NewAccessRequests>}
            path="/revokeGrant"
            requiredPermission={'repo:update'}
            {...props}
          />
        ),
      },
      // Setup
      {
        path: '/setup',
        render: props => (
          <PrivateRoute
            component={Setup}
            path="/setup"
            roles={['Admin', 'Super Admin']}
            {...props}
          />
        ),
      },
      {
        path: '/cli/:publicKey',
        component: CLIHome,
      },
      {
        path: '/s3Browser/:path*',
        render: props => (
          <ThemeProvider theme={v5Theme}>
            <PrivateRoute component={S3Browser} path="/s3Browser/:path*" {...props} />
          </ThemeProvider>
        ),
      },
      // {
      //   path: '/access-portal',
      //   //   exact: false,
      //   render: props => (
      //     <PrivateRoute
      //       component={AccessPortal}
      //       path="/access-portal"
      //       requiredPermission={'token:read'}
      //       {...props}
      //     />
      //   ),
      // },
      // Dashboard
      {
        component: Dashboard,
        routes: [
          {
            path: '/access-portal',
            render: props => (
              <PrivateRoute
                component={AccessPortal}
                path="/access-portal"
                requiredPermission={'token:read'}
                {...props}
              />
            ),
          },
          {
            path: '/profile',
            component: Profile,
          },
          {
            path: '/repositories/:id',
            // component: RepositoryDetail,
            render: props => (
              // <ThemeProvider theme={v5Theme}>
              <PrivateRoute
                component={RepositoryDetail}
                path="/repositories/:id"
                requiredPermission={'repo:read'}
                {...props}
              />
              // </ThemeProvider>
            ),
          },
          {
            path: '/repositories',
            render: props => (
              <ThemeProvider theme={v5Theme}>
                <PrivateRoute
                  component={Repositories}
                  path="/repositories"
                  requiredPermission={'repo:read'}
                  {...props}
                />
              </ThemeProvider>
            ),
          },
          {
            path: '/admin/profile',
            render: props => (
              <PrivateRoute
                component={AdminProfile}
                path="/admin/profile"
                requiredPermission={['admin:profile:read', 'profile:read']}
                {...props}
              />
            ),
          },
          {
            path: '/admin/etcd',
            render: props => (
              <PrivateRoute
                component={EtcdViewer}
                path="/admin/etcd"
                requiredPermission={['admin:etcd:read', 'etcd:read']}
                {...props}
              />
            ),
          },
          {
            path: '/policies',
            render: props => (
              <PrivateRoute
                component={Policies}
                path="/policies"
                requiredPermission={'policies:read'}
                {...props}
              />
            ),
          },
          {
            path: '/newpolicies',
            render: props => (
              <ThemeProvider theme={v5Theme}>
                <PrivateRoute
                  component={NewPolicies}
                  path="/newpolicies"
                  requiredPermission={'policies:read'}
                  {...props}
                />
              </ThemeProvider>
            ),
          },
          {
            path: '/DataLabels',
            render: props => (
              <ThemeProvider theme={v5Theme}>
                <PrivateRoute
                  component={DataLabels}
                  path="/DataLabels"
                  requiredPermission={'policies:read'}
                  {...props}
                />
              </ThemeProvider>
            ),
          },

          {
            path: '/alerts',
            component: Alerts,
          },
          {
            path: '/threats',
            component: Threats,
          },
          {
            path: '/home',
            component: Home,
          },
          {
            path: '/overview',
            component: Overview,
          },
          {
            path: '/cyralActivityLogs',
            component: AuditLogs,
          },
          {
            path: '/serviceAccounts',
            component: ServiceAccounts,
          },
          // {
          //   path: '/plan',
          //   component: Plans,
          // },
          {
            path: '/sandbox',
            component: Sandbox,
          },
          {
            path: '/identityMap',
            component: Identities,
          },
          {
            path: '/logout',
            component: Logout,
          },
          {
            path: '/integrations',
            render: props => (
              <PrivateRoute
                component={Integrations}
                path="/integrations"
                requiredPermission={'integrations:read'}
                {...props}
              />
            ),
          },
          {
            path: '/help',
            component: Help,
          },

          // Wrappers
          {
            path: '/sidecars/instructions',
            exact: true,
            render: props => (
              <PrivateRoute
                component={WrappersInstructions}
                path="/sidecars/instructions"
                requiredPermission={'sidecar:read'}
                {...props}
              />
            ),
          },
          {
            path: '/sidecars/:id',
            render: props => (
              <PrivateRoute
                component={WrapperDetail}
                path="/sidecars/:id"
                requiredPermission={'repo:read'}
                {...props}
              />
            ),
          },
          {
            path: '/sidecars',
            exact: true,
            render: props => (
              <PrivateRoute
                component={Wrappers}
                path="/sidecars"
                requiredPermission={'sidecar:read'}
                {...props}
              />
            ),
          },
          // {
          //   path: '/approveGrant',
          //   exact: false,
          //   render: props => (
          //     <PrivateRoute
          //       component={AccessRequests}
          //       path="/approveGrant"
          //       requiredPermission={'repo:update'}
          //       {...props}
          //     />
          //   ),
          // },
          // {
          //   path: '/revokeGrant',
          //   exact: false,
          //   render: props => (
          //     <PrivateRoute
          //       component={AccessRequests}
          //       path="/revokeGrant"
          //       requiredPermission={'repo:update'}
          //       {...props}
          //     />
          //   ),
          // },

          // Account
          {
            path: '/account',
            render: props => (
              <PrivateRoute
                component={Account}
                path="/account"
                roles={['Admin', 'Super Admin']}
                {...props}
              />
            ),
            routes: [
              {
                path: '/account/users',
                render: props => (
                  <PrivateRoute
                    component={Users}
                    path="/account/users"
                    requiredPermission={'users:read'}
                    {...props}
                  />
                ),
              },
            ],
          },
          {
            path: '/unauthorized',
            component: Unauthorized,
          },
          {
            path: '*',
            component: NotFound,
          },
        ],
      },
    ],
  },
]
