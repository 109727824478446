import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useApolloClient } from '@apollo/client'
import Box from '@mui/material/Box'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

import { InputLabel, Input, Tag, LearnMore, ServiceIcon } from '@jeeves/new-components'
import { MIN_PORT_NUMBER, MAX_PORT_NUMBER } from '@jeeves/utils/helpers'

import { useMultiplexedRepoPortView } from './MultiplexedRepoPortViewContext'

const filter = createFilterOptions()

const MultiplexedRepoListenerAutocomplete = () => {
  const { listeners, selectedListener, selectedListenerWillBecomeMultiplexed } =
    useMultiplexedRepoPortView()
  const client = useApolloClient()
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext()

  const [overrideInputValue, setOverrideInputValue] = React.useState()

  const error = errors?.port
  const autocompleteOptions = listeners.map(listener => {
    const { id, port, __typename } = listener

    return {
      id,
      port: String(port),
      __typename,
    }
  })

  const renderOption = (props, option) => {
    const multiplexedListenerTypes = client.cache.config.possibleTypes.MultiplexedListener
    const isMultiplexed = multiplexedListenerTypes.includes(option.__typename)

    return (
      <Box component="li" {...props}>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Box component="span">{option.port}</Box>
          {isMultiplexed && <Tag>Smart Port</Tag>}
        </Stack>
      </Box>
    )
  }

  const invalidPortMessage = `Please enter a valid port number (${MIN_PORT_NUMBER} - ${MAX_PORT_NUMBER}).`

  const bindingAlreadyExists = Boolean(watch('bindingId'))

  React.useEffect(() => {
    if (bindingAlreadyExists) {
      setOverrideInputValue(true)
    }
  }, [bindingAlreadyExists])

  return (
    <Controller
      name="port"
      control={control}
      defaultValue=""
      render={({ field: { onChange, ...rest } }) => {
        return (
          <Autocomplete
            id="sidecar-port-select"
            onChange={(_, newValue) => {
              if (newValue?.inputValue) {
                // Create a new value from the user input
                onChange({ port: newValue.inputValue })
              } else {
                onChange(newValue)
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params)

              const { inputValue } = params
              // // Suggest the creation of a new value
              const isExisting = options.some(option => option.port === inputValue)
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  port: `Use port "${inputValue}"`,
                })
              }

              return filtered
            }}
            getOptionLabel={option => option.inputValue ?? option.port ?? ''}
            renderOption={renderOption}
            options={autocompleteOptions}
            renderInput={params => {
              const helperText = error ? (
                error.message
              ) : selectedListenerWillBecomeMultiplexed ? (
                <React.Fragment>
                  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    This port is currently in use by&nbsp;
                    <ServiceIcon type={selectedListener.repoType} sx={{ fontSize: '16px' }} />
                    &nbsp;
                    <Typography component="span" variant="h6" sx={{ color: 'text.primary' }}>
                      {selectedListener.binding.repo.name}
                    </Typography>
                    .
                  </Box>{' '}
                  This port will now be configured as a Smart Port, so it can be used by multiple
                  repositories.{' '}<LearnMore docsPath="sidecars/sidecar-bind-repo#smart-ports" />
                </React.Fragment>
              ) : (
                ''
              )

              return (
                <FormControl variant="standard" error={Boolean(error)} fullWidth={params.fullWidth}>
                  <InputLabel {...params.InputLabelProps}>Sidecar Port</InputLabel>
                  <Input
                    type="number"
                    ref={params.InputProps.ref}
                    inputProps={{
                      ...params.inputProps,
                      onChange: (...args) => {
                        setOverrideInputValue(false)
                        params.inputProps.onChange(...args)
                      },
                      ...(overrideInputValue ? { value: watch('port')?.port } : {}),
                    }}
                  />
                  {helperText && (
                    <FormHelperText
                      sx={{
                        typography: 'body2',
                      }}
                    >
                      {helperText}
                    </FormHelperText>
                  )}
                </FormControl>
              )
            }}
            freeSolo
            autoSelect
            autoHighlight
            blurOnSelect
            openOnFocus
            selectOnFocus
            fullWidth
            {...rest}
          />
        )
      }}
      rules={{
        required: 'This field is required.',
        validate: {
          min: value => Number(value?.port) >= MIN_PORT_NUMBER || invalidPortMessage,
          max: value => Number(value?.port) <= MAX_PORT_NUMBER || invalidPortMessage,
          isInteger: value => Number.isInteger(Number(value?.port)) || invalidPortMessage,
        },
      }}
    />
  )
}

export default MultiplexedRepoListenerAutocomplete
