/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { useState, useEffect } from 'react'
import { useParams, useRouteMatch, Switch, Route, Redirect, Link } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { useQuery, gql, NetworkStatus } from '@apollo/client'
import styled from '@emotion/styled'

import useInterval from '@jeeves/hooks/useInterval'
import Loading from '@jeeves/components/Loading'
import { Paper, Tabs, Tab } from '@jeeves/components/Primitives'
import useWrappers from '@jeeves/pages/Wrappers/hooks/useWrappers'
import { WrappersProvider } from '@jeeves/pages/Wrappers/contexts/WrappersContext'
import SidecarInstances from './components/SidecarInstances'
import Accounts from './components/Accounts'
import { Breadcrumbs, WrapperError, WrapperHeader } from '.'
import { useFetch } from '@jeeves/support/hooks'
import { DEPLOYMENT_MAP } from '@jeeves/pages/Wrappers/Instructions'
import Advanced from './components/Advanced'
import { IntermediateLoading } from '@jeeves/components/Loading'
import { SidecarDetailProvider } from './contexts/SidecarDetailContext'

import { v5Theme } from '@jeeves/theme'

import { Repositories, RepositoriesProvider } from './tabs'

const TabContainer = styled.div`
  padding: 24px 0;
`

const getTabs = wrapper => {
  const allTabs = [
    {
      label: 'Repositories',
      value: 'repositories',
      shouldBeRendered: true,
    },
    {
      label: 'Instances',
      value: 'instances',
      shouldBeRendered: true,
    },
    {
      label: 'Sidecar Registration',
      value: 'registration',
      shouldBeRendered: wrapper?.sidecarAccounts?.length > 0,
    },
    {
      label: 'Advanced',
      value: 'advanced',
      shouldBeRendered: true,
    },
  ]

  return allTabs.filter(tab => tab.shouldBeRendered)
}

const SIDECAR_DETAILS = gql`
  query SidecarDetails($sidecarId: String!) {
    ...Repositories_query
  }
  ${Repositories.fragments.Repositories_queryFragment}
`

const WrapperDetail = () => {
  const { id } = useParams()
  const {
    data: sidecarDetailsData,
    loading: sidecarDetailsLoading,
    error: sidecarDetailsError,
    fetchMore,
    refetch,
    networkStatus,
  } = useQuery(SIDECAR_DETAILS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      sidecarId: id,
    },
  })
  const [wrapper, setWrapper] = useState(null)
  const [loading, setLoading] = useState(true)
  const [intermediateLoading, setIntermediateLoading] = useState(false)
  const [inFlight, setInFlight] = useState(false)
  const [error, setError] = useState(false)
  const { path, url } = useRouteMatch()

  const tabs = getTabs(wrapper)
  const routeMatch = useRouteMatch(tabs.map(tab => `${path}/${tab.value}`))

  const { ec } = useWrappers()

  const [{ data: fetchedInstructions }] = useFetch('/freshdesk/articles')
  const [showCloneDownload, setShowCloneDownload] = useState(false)

  useEffect(() => {
    // TO DO: place this in combined clone/download component when it is created
    const cloneDownloadDeploymentTypes = [
      'custom',
      'helm',
      'tf-aws-ec2',
      'cft-ec2',
      'docker-compose',
      'linux',
      'single-container',
      'express-installer',
    ]
    if (fetchedInstructions) {
      const cloneDownloadDeploymentArticlesThatExist = cloneDownloadDeploymentTypes.filter(
        deploymentMethod =>
          fetchedInstructions.find(
            instruction => DEPLOYMENT_MAP.get(instruction.id)?.deploymentMethod === deploymentMethod
          )
      )
      if (cloneDownloadDeploymentArticlesThatExist.length > 0) {
        setShowCloneDownload(true)
      }
    }
  }, [fetchedInstructions])

  const fetchData = async (silently = false) => {
    if (!silently) {
      if (!wrapper) {
        setLoading(true)
      } else {
        setIntermediateLoading(true)
      }
    }
    setInFlight(true)
    try {
      const result = await ec.get(`/sidecars/${id}/details`)
      if (result && result.data && result.data !== {}) {
        setWrapper(result.data)
        setError(false)
      }
    } catch (e) {
      setError(true)
    } finally {
      setLoading(false)
      setIntermediateLoading(false)
      setInFlight(false)
    }
  }

  useInterval(async () => {
    if (!inFlight) {
      fetchData(true)
    }
  }, 30000)

  if (loading || networkStatus === NetworkStatus.loading) {
    return (
      <Paper css={{ minHeight: '80px' }}>
        <Loading />
      </Paper>
    )
  }

  if (error || sidecarDetailsError) {
    return <WrapperError />
  }

  return (
    <SidecarDetailProvider sidecarId={id}>
      <Paper>
        <IntermediateLoading loading={intermediateLoading} />
        <Breadcrumbs />
        <WrapperHeader
          wrapper={wrapper}
          showCloneDownload={showCloneDownload}
          fetchData={fetchData}
        />

        <Tabs value={routeMatch?.url}>
          {tabs.map(tab => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={`${url}/${tab.value}`}
              component={Link}
              to={`${url}/${tab.value}`}
            />
          ))}
        </Tabs>

        <Switch>
          <Route path={`${path}/repositories`}>
            <ThemeProvider theme={v5Theme}>
              <RepositoriesProvider
                fetchMore={fetchMore}
                refetch={refetch}
                networkStatus={networkStatus}
              >
                <Repositories query={sidecarDetailsData} />
              </RepositoriesProvider>
            </ThemeProvider>
          </Route>

          <Route path={`${path}/instances`}>
            <TabContainer>
              {wrapper && <SidecarInstances sidecarInstances={wrapper.instances} />}
            </TabContainer>
          </Route>

          {tabs.some(tab => tab.value === 'registration') && (
            <Route path={`${path}/registration`}>
              <TabContainer>
                {wrapper && (
                  <Accounts
                    onCreate={() => {
                      fetchData()
                    }}
                    sidecar={wrapper}
                    accounts={wrapper.sidecarAccounts}
                  />
                )}
              </TabContainer>
            </Route>
          )}

          <Route path={`${path}/advanced`}>
            <TabContainer>
              {wrapper && <Advanced sidecar={wrapper} onSave={() => fetchData()} />}
            </TabContainer>
          </Route>

          <Redirect to={`${path}/${tabs?.[0].value}`} />
        </Switch>
      </Paper>
    </SidecarDetailProvider>
  )
}

export default () => (
  <WrappersProvider>
    <WrapperDetail />
  </WrappersProvider>
)
