export const REPO_METADATA = {
  REDSHIFT: {
    displayName: 'Amazon Redshift',
    typeName: 'redshift',
  },
  S3: {
    displayName: 'Amazon S3',
    typeName: 's3',
    defaults: {
      port: 443,
      hostname: 's3.amazonaws.com',
    },
  },
  SNOWFLAKE: {
    displayName: 'Snowflake',
    typeName: 'snowflake',
  },
  DENODO: {
    displayName: 'Denodo',
    typeName: 'denodo',
  },
  DREMIO: {
    displayName: 'Dremio',
    typeName: 'dremio',
  },
  DYNAMODB: {
    displayName: 'Amazon DynamoDB',
    typeName: 'dynamodb',
  },
  DYNAMODBSTREAMS: {
    displayName: 'Amazon DynamoDB Streams',
    typeName: 'dynamodbstreams',
  },
  GALERA: {
    displayName: 'Galera',
    typeName: 'galera',
  },
  MARIADB: {
    displayName: 'MariaDB',
    typeName: 'mariadb',
  },
  MONGODB: {
    displayName: 'MongoDB',
    typeName: 'mongodb',
    defaults: {
      port: 27017,
    },
  },
  MYSQL: {
    displayName: 'MySQL',
    typeName: 'mysql',
  },
  ORACLE: {
    displayName: 'Oracle',
    typeName: 'oracle',
  },
  POSTGRESQL: {
    displayName: 'PostgreSQL',
    typeName: 'postgresql',
  },
  SQLSERVER: {
    displayName: 'SQLServer',
    typeName: 'sqlserver',
  },
}

export const ALL_REPO_TYPES_WITH_DISPLAY_NAME = Object.values(REPO_METADATA)

export const REPO_TYPES_THAT_SUPPORT_PORT_MULTIPLEXING = [
  REPO_METADATA.MYSQL,
  REPO_METADATA.MARIADB,
  REPO_METADATA.POSTGRESQL,
  REPO_METADATA.DENODO,
  REPO_METADATA.REDSHIFT,
]
