/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState } from 'react'
import { IconButton, Fade, InputAdornment } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import { ButtonLoading } from '@jeeves/components/Loading'
import { useAuth } from '@jeeves/components/Auth'
import { Button } from '@jeeves/components/Primitives'
import {
  DeleteIntegrationButton,
  IntegrationCard,
  IntegrationEditActions,
  IntegrationFieldList,
  IntegrationHeader,
  IntegrationTitle,
  Label,
  LabelValuePair,
  TextField,
  Value,
} from '../../../primitives'

const IntegrationForm = ({ integration, onSave, onDelete, onCancel, creating = false }) => {
  const [name, setName] = useState(integration?.integration?.name || '')
  const [URL, setURL] = useState(integration?.integration?.esUrl || '')
  const [kibanaURL, setKibanaURL] = useState(integration?.integration?.kibanaUrl || '')
  const [esUser, setESUser] = useState(integration?.integration?.esUid || '')
  const [esPassword, setESPassword] = useState(integration?.integration?.esPwd || '')
  const [showEsPassword, setShowEsPassword] = useState(false)
  const [editing, setEditing] = useState(creating)
  const [saving, setSaving] = useState(false)
  const { hasPermission } = useAuth()

  const redactedEsPassword = '*'.repeat(esPassword.length)

  const toggleShowPassword = () => setShowEsPassword(!showEsPassword)

  const handleCancelEditing = () => {
    setEditing(false)

    if (onCancel) {
      onCancel()
    }
  }

  const handleOnSave = async () => {
    setSaving(true)
    try {
      await onSave({
        name: name,
        esUrl: URL,
        kibanaUrl: kibanaURL,
        esUid: esUser,
        esPwd: esPassword,
      })
    } catch (e) {
      console.log({ e })
    } finally {
      setSaving(false)
      setEditing(false)
    }
  }

  return (
    <IntegrationCard>
      {!editing && (
        <Fade in={!editing}>
          <div>
            <IntegrationHeader>
              <IntegrationTitle gutterBottom component="h2">
                {name}
              </IntegrationTitle>
              <div css={{ flexGrow: 1 }} />
              <IconButton
                onClick={() => setEditing(true)}
                disabled={!hasPermission('integrations:update')}
              >
                <EditIcon />
              </IconButton>
              <DeleteIntegrationButton
                onDelete={onDelete}
                disabled={!hasPermission('integrations:delete')}
              />
            </IntegrationHeader>
            <LabelValuePair>
              <Label>Kibana URL</Label>
              <Value>{kibanaURL}</Value>
            </LabelValuePair>
            <LabelValuePair>
              <Label>Elasticsearch URL</Label>
              <Value>{URL}</Value>
            </LabelValuePair>
            <LabelValuePair>
              <Label>Elasticsearch User</Label>
              <Value>{esUser}</Value>
            </LabelValuePair>
            <LabelValuePair>
              <Label>Elasticsearch Password</Label>
              <Value>{redactedEsPassword}</Value>
            </LabelValuePair>
          </div>
        </Fade>
      )}
      {editing && (
        <Fade in={editing}>
          <div>
            <IntegrationFieldList>
              <TextField
                value={name}
                label="Integration Name"
                onChange={e => setName(e.target.value)}
                autoFocus
                required
              />
              <TextField
                value={kibanaURL}
                label="Kibana URL"
                onChange={e => setKibanaURL(e.target.value)}
              />
              <TextField
                value={URL}
                label="Elasticsearch URL"
                onChange={e => setURL(e.target.value)}
                required
              />
              <TextField
                value={esUser}
                label="Elasticsearch User"
                onChange={e => setESUser(e.target.value)}
              />
              <TextField
                type={showEsPassword ? 'text' : 'password'}
                value={esPassword}
                label="Elasticsearch Password"
                onChange={e => setESPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleShowPassword}>
                        {showEsPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </IntegrationFieldList>
            <IntegrationEditActions>
              <Button onClick={handleCancelEditing} color="primary" disabled={saving}>
                Cancel
              </Button>
              <div css={theme => ({ position: 'relative', marginLeft: theme.spacing[5] })}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={saving}
                  onClick={handleOnSave}
                >
                  Save
                </Button>
                {saving && <ButtonLoading />}
              </div>
            </IntegrationEditActions>
          </div>
        </Fade>
      )}
    </IntegrationCard>
  )
}

export default IntegrationForm
