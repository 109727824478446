/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import { get } from 'lodash'

import { WrapperInfo } from '.'
import DetailHeader from '@jeeves/components/DetailHeader'
import { SidecarCopyButton } from './components/SidecarCopyButton'
import Actions from './components/Actions'

export const WrapperHeader = ({ wrapper, showCloneDownload, fetchData }) => {
  return (
    <DetailHeader
      service={get(wrapper, 'cloud')}
      name={get(wrapper, 'name', 'Error resolving Sidecar name')}
      status={wrapper.passThroughEnabled}
      isSidecar
      wrapper={wrapper}
      actions={
        <Actions showCloneDownload={showCloneDownload} wrapper={wrapper} fetchData={fetchData} />
      }
    >
      {wrapper && (
        <>
          <SidecarCopyButton wrapper={wrapper} />
          <WrapperInfo wrapper={wrapper} />
        </>
      )}
    </DetailHeader>
  )
}
