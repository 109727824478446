/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useEffect, useState, Fragment } from 'react'

import logo from './assets/saml.png'
import { ThemeProvider } from '@mui/material/styles'
import { v5Theme } from '@jeeves/theme'

import {
  AddIntegrationButton,
  IntegrationDialogContent,
  IntegrationList,
  IntegrationLoading,
  ZeroState,
} from '../../primitives'
import { useAuth } from '@jeeves/hooks'

import Integration from './components/Integration'
import IntegrationDraft from './components/IntegrationDraft'

import NameDialog from './NameDialog'

import useSAML from './hooks/useSAML'

import { gql, useMutation, useQuery } from '@apollo/client'

export const FETCH_INTEGRATIONS = gql`
  query GenericSAMLIntegrations {
    genericSAMLIntegrations {
      id
      displayName
      scimBaseURL
      scimIntegration {
        IDPID
        scimBaseURL
        clientId
        enabled
        authMode
        authSecretExpiry
      }
      firstName
      lastName
      email
      groups
      isDraft
      spMetadataXMLDocument
      spMetadataURL
      ... on GenericSAMLIntegrationConfigured {
        singleSignOnServiceURL
        signingCertificate
        enableForceAuthentication
        singleLogoutServiceURL
        enabled
      }
      ... on GenericSAMLIntegrationDraft {
        enableIdpInitiatedLogin
      }
    }
  }
`

const FETCH_DEFAULT_ATTRIBUTES = gql`
  query GenericSAMLIntegrationDefaultAttributes {
    genericSAMLIntegrationDefaultAttributes {
      firstName
      lastName
      email
      groups
    }
  }
`

const GenericSAML = () => {
  const [open, setOpen] = useState(false)
  const [isCreatingIntegration, setIsCreatingIntegration] = useState(false)
  const [openIntegration, setOpenIntegration] = useState({})
  const [newIntegrationId, setNewIntegrationId] = useState(null)
  const {
    createSCIMIntegration,
    updateSCIMIntegrationStatus,
    createSCIMIntegrationData,
    generateSCIMIntegrationCreds,
  } = useSAML()
  const { hasPermission } = useAuth()

  const {
    loading: integrationsLoading,
    error: integrationsError,
    data: integrationsData,
    refetch: refetchIntegrations,
  } = useQuery(FETCH_INTEGRATIONS)

  const {
    loading: defaultAttributesLoading,
    error: defaultAttributesError,
    data: defaultAttributesData,
  } = useQuery(FETCH_DEFAULT_ATTRIBUTES)

  const configuredGenericSAMLIntegrations =
    integrationsData?.genericSAMLIntegrations.filter(integration => !integration.isDraft) ?? []

  const draftGenericSAMLIntegrations =
    integrationsData?.genericSAMLIntegrations.filter(integration => integration.isDraft) ?? []

  if (integrationsLoading) return <IntegrationLoading />
  return (
    <Fragment>
      <IntegrationDialogContent>
        <IntegrationList css={{ marginBottom: '0' }}>
          {!integrationsLoading &&
            !isCreatingIntegration &&
            configuredGenericSAMLIntegrations.length === 0 && <ZeroState />}
          {!integrationsLoading &&
            configuredGenericSAMLIntegrations.length > 0 &&
            configuredGenericSAMLIntegrations.map((integration, index) => (
              <Integration
                refetchIntegrations={refetchIntegrations}
                generateSCIMIntegrationCreds={generateSCIMIntegrationCreds}
                createSCIMIntegrationData={createSCIMIntegrationData}
                createSCIMIntegration={createSCIMIntegration}
                updateSCIMIntegrationStatus={updateSCIMIntegrationStatus}
                key={integration.id}
                integration={integration}
                last={
                  draftGenericSAMLIntegrations.length === 0 &&
                  index === configuredGenericSAMLIntegrations.length - 1
                }
              />
            ))}
          {!integrationsLoading &&
            draftGenericSAMLIntegrations.length > 0 &&
            draftGenericSAMLIntegrations.map((integration, index) => (
              <IntegrationDraft
                setOpenIntegration={setOpenIntegration}
                openIntegration={openIntegration}
                setIsCreatingIntegration={setIsCreatingIntegration}
                key={integration.id}
                integration={integration}
                isCreatingIntegration={isCreatingIntegration}
                last={index === draftGenericSAMLIntegrations.length - 1}
                scrollToAndOpen={integration.id === newIntegrationId}
              />
            ))}
        </IntegrationList>

        <ThemeProvider theme={v5Theme}>
          <NameDialog
            defaultAttributes={defaultAttributesData?.genericSAMLIntegrationDefaultAttributes}
            isOpen={open}
            handleClose={() => {
              setOpen(false)
            }}
            setNewIntegrationId={setNewIntegrationId}
          />
        </ThemeProvider>
      </IntegrationDialogContent>
      <div
        css={{
          padding: '16px 24px',
          backgroundColor: 'white',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <AddIntegrationButton
          isOpen={open}
          disabled={!hasPermission('integrations:create') || isCreatingIntegration}
          setOpen={() => setOpen(true)}
        />
      </div>
    </Fragment>
  )
}

GenericSAML.metadata = {
  name: 'SAML',
  summary: 'Set up SAML single sign-on to authenticate Cyral and data source users.',
  enabled: true,
  category: 'IDENTITY PROVIDER',
  slug: 'saml',
  logo,
}

export default GenericSAML
