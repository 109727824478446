import React, { useState, useContext, useEffect } from 'react'
import Keycloak from 'keycloak-js'
import createAuth0Client from '@auth0/auth0-spa-js'
import { get as lodashGet } from 'lodash'
import qs from 'qs'
import { useHistory } from 'react-router-dom'

import { useMount } from '@jeeves/hooks'
import axios from 'axios'

const AuthContext = React.createContext()
const useAuth = () => useContext(AuthContext)

class Auth0Provider {
  constructor(initOptions, meta, onRedirectCallback) {
    this.initOptions = initOptions
    this.meta = meta
    this.onRedirectCallback = onRedirectCallback
  }

  init = async (setIsAuthenticated, setUser, setLoading, setAuthClient) => {
    const auth0FromHook = await createAuth0Client({
      // authorizeTimeoutInSeconds Reduces "Loading..."" screen time when Allowed Web Origin doesn't include the domain, but the user is authenticated.
      authorizeTimeoutInSeconds: 5,
      ignoreCache: false,
      useRefreshTokens: true,
      ...this.initOptions,
    })

    setAuthClient(auth0FromHook)
    this.authClient = auth0FromHook
    if (window.location.search.includes('code=')) {
      const { appState } = await auth0FromHook.handleRedirectCallback()
      this.onRedirectCallback(appState)
    }

    const _isAuthenticated = await auth0FromHook.isAuthenticated().catch(error => {
      console.log(`auth0FromHook.isAuthenticated().error: ${JSON.stringify(error)}`)
      return false
    })

    setIsAuthenticated(_isAuthenticated)

    if (_isAuthenticated) {
      await auth0FromHook
        .getTokenSilently({ ignoreCache: true })
        .catch(error => console.error(`Failed to get token silently: ${error}`) && [])
      const user = await auth0FromHook.getUser()
      const permissions = await this.getPermissions(auth0FromHook).catch(error => {
        console.error(`Failed to get user permissions: ${error}`)
        return []
      })

      // Enrich user with meta information
      const enrichedUser = {
        ...user,
        app_metadata: user[`${this.meta}app_metadata`],
        user_metadata: user[`${this.meta}user_metadata`],
        client: user[`${this.meta}client`],
        user_id: user[`${this.meta}user_id`],
        permissions: permissions,
      }

      delete enrichedUser[`${this.meta}user_metadata`]
      delete enrichedUser[`${this.meta}app_metadata`]
      delete enrichedUser[`${this.meta}client`]
      delete enrichedUser[`${this.meta}user_id`]

      setUser(enrichedUser)

      this.user = enrichedUser
    }

    setLoading(false)
  }

  getPermissions = async auth0FromHook => {
    return auth0FromHook
      .getTokenSilently({ ignoreCache: true })
      .catch(error => console.error(`Failed to get token silently: ${error}`) && [])
      .then(token => token.split('.')[1]) // Get the payload part of the token
      .catch(error => console.error(`Failed to payload from token: ${error}`) && [])
      .then(payload => new Buffer(payload, 'base64')) // eslint-disable-line
      .then(payload => payload.toString('ascii'))
      .catch(error => console.error(`Failed to decode payload: ${error}`) && [])
      .then(payload => JSON.parse(payload))
      .catch(error => console.error(`Failed to parse payload: ${error}`) && [])
      .then(payload => payload.permissions)
      .catch(error => console.error(`Failed to get permissions from token: ${error}`) && [])
  }

  hasPermission = permission => {
    // add a conditional check for Keycloak
    return lodashGet(this.user, 'app_metadata.authorization.permissions', []).includes(permission)
  }

  getTokenSilently = async (...p) => {
    return this.authClient.getTokenSilently(...p)
  }

  getTokenWithPopup = async (...p) => {
    return this.authClient.getTokenWithPopup(...p)
  }

  logout = async (...p) => {
    return this.authClient.logout(...p)
  }

  loginWithRedirect = async (...p) => {
    return this.authClient.loginWithRedirect(...p)
  }
}

class KeycloakProvider {
  constructor(initOptions, meta, onRedirectCallback) {
    this.initOptions = initOptions
    this.meta = meta
    this.onRedirectCallback = onRedirectCallback
  }

  /* Functions used to emit logs */
  logLoginRequest = token => {
    const config = {
      method: 'post',
      url: '/audit/login',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
    axios(config)
  }

  logLogoutRequest = token => {
    const config = {
      method: 'post',
      url: '/audit/logout',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
    axios(config)
  }

  getIDPPreference = () => {
    const storedIDP = window.localStorage.getItem('idpPreference')
    const IDPHint = new URLSearchParams(window.location.search).get('idp')
    return IDPHint || storedIDP
  }

  init = async (setIsAuthenticated, setUser, setLoading, setAuthClient) => {
    const keycloakPublicPort = this.initOptions.keycloak_frontend_port
    const keycloakHost = `${this.initOptions.domain}${
      keycloakPublicPort && keycloakPublicPort !== '0' ? `:${keycloakPublicPort}` : ''
    }`
    const keycloakConfig = {
      onLoad: 'login-required',
      url: `https://${keycloakHost}/auth`,
      realm: this.initOptions.auth_tenant,
      clientId: this.initOptions.client_id,
    }

    const keycloak = new Keycloak(keycloakConfig)

    keycloak.onAuthSuccess = () => {
      this.logLoginRequest(keycloak.token)
    }

    this.authClient = keycloak
    setAuthClient(keycloak)

    const auth = await keycloak.init({ checkLoginIframe: false })
    if (!auth) {
      const idpHint = this.getIDPPreference()
      keycloak.login({ idpHint })
    } else {
      setIsAuthenticated(true)
      const permissions = await this.getUserPermissions(this.authClient.token)

      // this can probably be written much better using a reduce function
      const formatUserPermissions = permissions => {
        const formattedPermissions = permissions.map(permission => {
          if (permission.scopes) {
            const permissionScopes = permission.scopes.map(permissionScope => {
              return `${permission.rsname}:${permissionScope}`
            })
            return permissionScopes
          } else {
            return []
          }
        })
        return Array.prototype.concat.apply([], formattedPermissions) // this is a function to flatten formattedPermissions
      }

      const formattedPermissions = formatUserPermissions(permissions)

      const enrichedUser = {
        ...keycloak.idTokenParsed,
        app_metadata: { authorization: { permissions: formattedPermissions } },
        client: { tenant: this.initOptions.auth_tenant },
      }

      setUser(enrichedUser)
      this.user = enrichedUser

      setTimeout(() => {
        keycloak
          .updateToken(180)
          .then(refreshed => {
            if (refreshed) {
              console.debug('Token refreshed' + refreshed)
            } else {
              console.warn(
                'Token not refreshed, valid for ' +
                  Math.round(
                    keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000
                  ) +
                  ' seconds'
              )
            }
          })
          .catch(() => {
            console.error('Failed to refresh token')
          })
      }, 60000)
      setLoading(false)
    }
  }

  hasPermission = permission => {
    // add a conditional check for Keycloak
    return lodashGet(this.user, 'app_metadata.authorization.permissions', []).includes(permission)
  }

  // I realized this function needs to be made in express and have react call it because rn i'm getting a cross origin request error
  getUserPermissions = async token => {
    const data = qs.stringify({
      grant_type: 'urn:ietf:params:oauth:grant-type:uma-ticket',
      response_mode: 'permissions',
      audience: 'cyral-api',
    })

    const keycloakPublicPort = this.initOptions.keycloak_frontend_port
    const keycloakHost = `${this.initOptions.domain}${
      keycloakPublicPort && keycloakPublicPort !== '0' ? `:${keycloakPublicPort}` : ''
    }`

    const config = {
      method: 'post',
      url: `https://${keycloakHost}/auth/realms/${this.initOptions.auth_tenant}/protocol/openid-connect/token`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    }

    let permissions = []
    try {
      permissions = await axios(config).then(res => res.data)
    } catch (e) {
      console.error(e)
    }

    return permissions
  }

  logout = async (...p) => {
    this.logLogoutRequest(this.authClient.token)
    this.authClient.logout(...p)
  }

  getTokenSilently = async (params = {}) => {
    await this.authClient
      .updateToken(180)
      .then(refreshed => {
        if (refreshed) {
          console.debug('Token refreshed' + refreshed)
        } else {
          console.warn(
            'Token not refreshed, valid for ' +
              Math.round(
                this.authClient.tokenParsed.exp +
                  this.authClient.timeSkew -
                  new Date().getTime() / 1000
              ) +
              ' seconds'
          )
        }
      })
      .catch(() => {
        console.error('Failed to refresh token')
      })
    if (params.audience && params.audience.length === 0) {
      const payload = {
        sessionID: this.authClient.tokenParsed.session_state,
      }
      const opaqueToken = await axios
        .post('/api/opaqueToken', payload, {
          headers: { Authorization: `Bearer ${this.authClient.token}` },
        })
        .then(res => res.data)
      return opaqueToken
    } else {
      return this.authClient.token
    }
  }

  getTokenWithPopup = async () => {
    return this.authClient.token
  }

  loginWithRedirect = async () => {}
}

const AuthProvider = ({ children, meta, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [isDbUser, setIsDbUser] = useState()
  const [provider, setProvider] = useState()
  const [loading, setLoading] = useState(true)
  const [authClient, setAuthClient] = useState()
  const history = useHistory()

  const onRedirectCallback = appState => {
    history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
  }

  useMount(() => {
    const discoverProvider = async () => {
      const providerType = initOptions.auth_provider
      if (providerType === 'keycloak') {
        const kp = new KeycloakProvider(initOptions, meta, onRedirectCallback)
        setProvider(kp)
        await kp.init(setIsAuthenticated, setUser, setLoading, setAuthClient)
      } else if (providerType === 'auth0') {
        const ap = new Auth0Provider(initOptions, meta, onRedirectCallback)
        setProvider(ap)
        await ap.init(setIsAuthenticated, setUser, setLoading, setAuthClient)
      }
    }
    discoverProvider()
  })

  useEffect(() => {
    if (!!user) {
      const getIsDbUser = () => {
        const permissions = lodashGet(user, 'app_metadata.authorization.permissions', [])
        const dbPermissions = [
          'apidocs:read',
          'logs:read',
          'token:read',
          'repo:read',
          'sidecar:read',
          'metrics:read',
          'adminguide:read',
          'support:read',
          'access:read',
          'datamap:read',
          'approvals:request',
        ]

        const diff = permissions.filter(permission => !dbPermissions.includes(permission))
        if (diff.length > 0) return false

        return true
      }

      setIsDbUser(getIsDbUser())

      const getFirstName = () => {
        if (!user) return ''

        if (user.given_name) {
          return user.given_name
        } else {
          if (user.name) return user.name
          if (user.email) return user.email
          return ''
        }
      }

      const getLastName = () => {
        if (!user || !user.given_name || !user.family_name) return ''
        if (!!user.family_name) return user.family_name
        return ''
      }

      const getUserEmail = () => {
        if (!user) return ''
        if (user.email) return user.email
        return ''
      }

      var refreshId = setInterval(function () {
        if (window.fcWidget) {
          window.fcWidget.setExternalId(
            `${window.location.hostname} - ${getFirstName()} ${getLastName(getFirstName())}`
          )
          window.fcWidget.user.setFirstName(getFirstName())
          window.fcWidget.user.setEmail(getUserEmail())
          window.fcWidget.user.setProperties({
            // plan: 'Estate',
            status: 'Active',
          })
          clearInterval(refreshId)
        }
      }, 1000)
    }
  }, [user])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        isDbUser,
        loading,
        authClient,
        hasPermission: provider && provider.hasPermission,
        getIdTokenClaims: provider && provider.getIdTokenClaims,
        loginWithRedirect: provider && provider.loginWithRedirect,
        getTokenSilently: provider && provider.getTokenSilently,
        getTokenWithPopup: provider && provider.getTokenWithPopup,
        logout: provider && provider.logout,
        reinitialize: provider
          ? () => provider.init(setIsAuthenticated, setUser, setLoading, setAuthClient)
          : undefined,
      }}
    >
      <React.Fragment>{children}</React.Fragment>
    </AuthContext.Provider>
  )
}
export { AuthProvider, AuthContext, useAuth }
