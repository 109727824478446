import pluralize from 'pluralize'
import { usePolicySummary } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/PolicySummary/PolicySummaryContext'
import {
  getDisplayedPolicyIdentity,
  PolicyIdentityType,
} from '@jeeves/pages/RepositoryDetail/Tabs/Policies/helpers/policyIdentities'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

export const useReadLimitVariation = () => {
  const { policy } = usePolicySummary()
  const { repoName } = useRepositoryDetailContext()

  const { action, readLimitDataLabels, databaseAccountsAppliedTo, identitiesAppliedTo, rowLimit } =
    policy.parameters

  const actionMap = {
    alert: 'ALERT',
    alertAndBlock: 'ENFORCE',
  }

  const getIdentities = () => {
    const identities = identitiesAppliedTo.identities?.map(
      (identity: { __typename: PolicyIdentityType; name: string }) => {
        return getDisplayedPolicyIdentity(identity)
      }
    )
    return identities ?? []
  }

  const getRows = () => {
    return `${rowLimit} ${pluralize('row', Number(rowLimit))}`
  }

  return {
    action: actionMap[action as keyof typeof actionMap],
    dataLabels: readLimitDataLabels ?? [],
    databaseAccounts: databaseAccountsAppliedTo.databaseAccounts,
    identities: getIdentities(),
    rows: getRows(),
    repoName,
  }
}
