/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useEffect, useState } from 'react'

import {
  IntegrationDialogContent,
  IntegrationList,
  IntegrationLoading,
  ZeroState,
} from '../../primitives'
import { useAuth } from '@jeeves/hooks'

import Integration from './components/Integration'
import AddForm from './create/AddForm'
import useAzureAD from './hooks/useAzureAD'
import logo from './assets/azure-ad.png'

const AzureAD = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isCreatingIntegration, setIsCreatingIntegration] = useState(false)
  const {
    updateIntegrations,
    integrations,
    addIntegration,
    putIntegration,
    deleteIntegration,
    createSCIMIntegration,
    updateSCIMIntegrationStatus,
    createSCIMIntegrationData,
    generateSCIMIntegrationCreds,
  } = useAzureAD({ refresh: async () => await fetchData() })
  const { hasPermission } = useAuth()

  const fetchData = async () => {
    setIsLoading(true)
    await updateIntegrations()
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <IntegrationDialogContent>
      <IntegrationList>
        {isLoading && <IntegrationLoading />}
        {!isLoading && !isCreatingIntegration && integrations.length === 0 && <ZeroState />}
        {!isLoading &&
          integrations.length > 0 &&
          integrations.map(integration => (
            <Integration
              generateSCIMIntegrationCreds={generateSCIMIntegrationCreds}
              createSCIMIntegrationData={createSCIMIntegrationData}
              createSCIMIntegration={createSCIMIntegration}
              updateSCIMIntegrationStatus={updateSCIMIntegrationStatus}
              updateIntegrations={updateIntegrations}
              key={integration.id}
              integration={integration}
              putIntegration={putIntegration}
              deleteIntegration={deleteIntegration}
            />
          ))}
        {isCreatingIntegration && (
          <AddForm
            addIntegration={addIntegration}
            handleRemoveAddField={() => setIsCreatingIntegration(false)}
          />
        )}
      </IntegrationList>
    </IntegrationDialogContent>
  )
}

AzureAD.metadata = {
  name: 'Azure Active Directory',
  summary: 'Leverage Azure Active Directory for Identity Management',
  enabled: true,
  category: 'IDENTITY PROVIDER',
  slug: 'azure-ad',
  logo,
  isDeprecated: true,
}

export default AzureAD
