import React from "react";
import { FixedSizeList } from "react-window"
import InfiniteLoader from "react-window-infinite-loader"
import { SelectOption } from '@jeeves/new-components'

export default function InfiniteLoad({children=[], hasNextPage, isNextPageLoading, getNextPage = () => {}, ...props}) {
  const itemCount = hasNextPage ? children.length + 1 : children.length

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {} : getNextPage

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = index => index < children.length || !hasNextPage

  // Render an item or a loading indicator.
  const Item = ({ index, style }) => {
    return isItemLoaded(index) ? React.cloneElement(children[index], { component: 'div', style }) : <SelectOption label={'Loading...'} selectSize="medium" style={style}/>
  }

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
    >
      {({ onItemsRendered, ref }) => (
          <div {...props}>
            <FixedSizeList
              className="List"
              height={itemCount > 4 ? 150 : (32 * itemCount)}
              itemCount={itemCount}
              itemSize={30}
              onItemsRendered={onItemsRendered}
              ref={ref}
              width='100%'
              >
                {Item}
            </FixedSizeList>
          </div>
      )}
    </InfiniteLoader>
  );
}
