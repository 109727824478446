import { useFormContext } from 'react-hook-form'

import { FormControl, Stack } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { Input, InputLabel, Tooltip } from '@jeeves/new-components'

const CyralUser = () => {
  const { register } = useFormContext()

  return (
    <FormControl variant="standard">
      <Stack spacing={0.5} direction="row" sx={{ alignItems: 'center' }}>
        <InputLabel htmlFor="cyral-user">User</InputLabel>
        <Tooltip
          title="Enter an email, client ID, username, first name, or last name to filter the audit logs by user"
          placement="right"
        >
          <InfoOutlinedIcon
            sx={{
              color: 'text.secondary',
              fontSize: 'medium',
              '&:hover': {
                color: 'primary.main',
                cursor: 'pointer',
              },
            }}
          />
        </Tooltip>
      </Stack>
      <Input
        id="cyral-user"
        inputProps={{
          ...register('cyralUser'),
        }}
      />
    </FormControl>
  )
}

export default CyralUser
