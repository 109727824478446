import { Button } from '@jeeves/components/Primitives'

export default function DownloadKibanaDashboards() {
  const KIBANA_DASHBOARD_INSTRUCTIONS_URL =
    'https://github.com/cyral-quickstart/quickstart-dashboards-kibana'

  const handleClick = () => {
    window.open(KIBANA_DASHBOARD_INSTRUCTIONS_URL, '_blank')
  }

  return (
    <Button variant="contained" onClick={handleClick}>
      Install Kibana Dashboards
    </Button>
  )
}
