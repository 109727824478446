/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Fragment } from 'react'
import { IconButton } from '@material-ui/core/'
import EditIcon from '@material-ui/icons/Edit'
import { useAuth } from '@jeeves/components/Auth'
import {
  DeleteIntegrationButton,
  IntegrationHeader,
  IntegrationTitle,
  Label,
  LabelValuePair,
  Value,
} from '../../../primitives'

const Info = ({ label, children }) => (
  <LabelValuePair>
    <Label css={{ marginRight: '24px' }}>{label}</Label>
    <Value>{children}</Value>
  </LabelValuePair>
)

export const ViewForm = ({ integration, setEditing, onDelete }) => {
  const { hasPermission } = useAuth()

  if (!integration) {
    return null
  }

  const { name, accessToken, hecPort, host, useTLS, index } = integration.integration

  return (
    <Fragment>
      <IntegrationHeader>
        <IntegrationTitle gutterBottom component="h2">
          {name}
        </IntegrationTitle>
        <div css={{ flexGrow: 1 }} />
        <IconButton
          onClick={() => setEditing(true)}
          disabled={!hasPermission('integrations:update')}
        >
          <EditIcon />
        </IconButton>
        <DeleteIntegrationButton
          onDelete={onDelete}
          disabled={!hasPermission('integrations:delete')}
        />
      </IntegrationHeader>
      <Info label="Host">{host}</Info>
      <Info label="Access Token">{accessToken}</Info>
      <Info label="HTTP Event Collector Port">{hecPort}</Info>
      <Info label="TLS">{useTLS ? 'On' : 'Off'}</Info>
      <Info label="Index">{index}</Info>
    </Fragment>
  )
}
