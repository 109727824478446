import React, { useState, useEffect } from 'react'
import { Box, Stack, FormControlLabel, Switch, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'

import { v5Theme } from '@jeeves/theme'
import { Button, LearnMore } from '@jeeves/new-components'
import { useAuth } from '@jeeves/components/Auth'
import ExpressClient from '@jeeves/clients/express'
import { usePopup } from '@jeeves/hooks'

import { CertificateManagement } from './components/CertificateManagement'
import SmartPorts from './components/SmartPorts'

import _ from 'lodash'

const Advanced = ({ sidecar, onSave }) => {
  const initialPassthrough = sidecar.services?.dispatcher?.bypass === 'always'
  const initialFailover =
    sidecar.services?.dispatcher?.bypass === 'failover' ||
    sidecar.services?.dispatcher?.bypass === 'always'
  const [passthrough, setPassthrough] = useState(initialPassthrough)
  const [failover, setFailover] = useState(initialFailover)

  const [loading, setLoading] = useState(false)
  const [updated, setUpdated] = useState(true)
  const { getTokenSilently } = useAuth()
  const { setPopup, popupTypes } = usePopup()
  const ec = new ExpressClient(getTokenSilently)
  const hasCustomCertificateBundleSecrets = Boolean(sidecar?.certificateBundleSecrets?.sidecar)

  const handleSave = async () => {
    setLoading(true)
    try {
      await ec.put(`sidecars/${sidecar.id}`, {
        bypass: passthrough ? 'always' : failover ? 'failover' : 'never',
      })
      await onSave()
    } catch (e) {
      const popupMessage = _.get(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
    setLoading(false)
  }

  const handleReset = () => {
    setPassthrough(initialPassthrough)
    setFailover(initialFailover)
  }

  useEffect(() => {
    setUpdated(passthrough === initialPassthrough && failover === initialFailover)
  }, [passthrough, failover, sidecar, initialPassthrough, initialFailover])

  return (
    <ThemeProvider theme={v5Theme}>
      <Stack sx={{ px: 6, py: 1.5 }} spacing={4}>
        <Stack
          spacing={7}
          sx={{
            border: '1px solid',
            borderColor: 'cyralColors.grey.200',
            p: 4,
            borderRadius: theme => theme.radii.base,
          }}
        >
          <Stack spacing={4}>
            <Stack spacing={1}>
              <FormControlLabel
                control={
                  <Switch
                    checked={passthrough}
                    onChange={e => setPassthrough(e.target.checked)}
                    name="passthrough"
                    color="primary"
                  />
                }
                label={
                  <Typography variant="h4" sx={{ color: 'text.primary' }}>
                    Switch to passthrough mode for sidecar
                  </Typography>
                }
              />

              <Box>
                <Typography variant="body2" sx={{ color: 'text.secondary', ml: 6 }}>
                  <li>All sidecar services are off</li>
                  <li>Users must use native credentials to log into the repo</li>
                </Typography>
              </Box>
            </Stack>
            <Stack spacing={1}>
              <FormControlLabel
                control={
                  <Switch
                    checked={failover || passthrough}
                    onChange={e => setFailover(e.target.checked)}
                    name="failover"
                    color="primary"
                    disabled={passthrough}
                  />
                }
                label={
                  <Typography variant="h4" sx={{ color: 'text.primary' }}>
                    Enable resiliency to sidecar errors
                  </Typography>
                }
              />

              <Box>
                <Typography variant="body2" sx={{ color: 'text.secondary', ml: 6 }}>
                  <li>
                    In the event of an error, existing client sessions will continue uninterrupted
                  </li>
                  <li>
                    Once an error happens, all subsequent inline authorization checks in existing
                    sessions are ignored
                  </li>
                  <li>
                    All new sessions will be established normally, and all checks will be enforced
                  </li>
                </Typography>
              </Box>
            </Stack>
            {hasCustomCertificateBundleSecrets && (
              <CertificateManagement
                customCertificateBundleSecret={sidecar.certificateBundleSecrets.sidecar}
              />
            )}
          </Stack>

          <Stack direction="row" sx={{ justifyContent: 'flex-end' }} spacing={4}>
            <Button variant="text" onClick={handleReset} disabled={loading || updated}>
              Cancel
            </Button>
            <Button loading={loading} onClick={handleSave} disabled={updated}>
              Save
            </Button>
          </Stack>
        </Stack>
        <SmartPorts />
      </Stack>
    </ThemeProvider>
  )
}

export default Advanced
