import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  Button,
  Dialog,
  Select,
  SelectOption,
  InputLabel,
  Input,
  DatePicker,
  ServiceIcon,
} from '@jeeves/new-components'

import {
  Stack,
  Typography,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  FormControl,
  Switch,
  Box,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import useRequestableRepos from '../hooks/useRequestableRepos'
import useRequestAccess from '../hooks/useRequestAccess'
import InfiniteLoad from './InfiniteLoad'
import { ALL_REPO_TYPES_WITH_DISPLAY_NAME } from '@jeeves/constants'
import { Tooltip } from '@jeeves/new-components'

import { RequestAccessFeedbackDialog } from './RequestAccessFeedbackDialog'

const supportedRepoTypes = ALL_REPO_TYPES_WITH_DISPLAY_NAME.filter(
  type => type.typeName !== 'snowflake' && type.typeName !== 'rest'
)

export const RequestAccess = ({ setIsRequestable = () => {}, tooltipPlacement = "bottom" }) => {
  const [open, setOpen] = useState(false)
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const [requestButtonEnabled, setRequestButtonEnabled] = useState(false)
  const { loading, error, data, getNextPage, refetch, firstLoading } = useRequestableRepos({
    variables: { first: 10 },
    notifyOnNetworkStatusChange: true,
  })
  const { requestAccess, loading: requestLoading } = useRequestAccess()
  const [requestableRepos, setRequestableRepos] = useState([])

  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
    reset,
    watch,
    setValue,
    register,
  } = useForm({
    defaultValues: {
      repoType: '',
      repo: '',
      userAccountId: '',
      duration: {
        type: 'always',
      },
      validUntil: '',
      reason: '',
    },
  })
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }
  const handleOnClose = () => {
    reset()
    refetch({ filters: null })
    setOpen(false)
  }
  const handleOnFeedbackClose = () => {
    handleOnClose()
    setFeedbackOpen(false)
  }

  const onSubmit = async ({ repo, userAccountId, duration, validUntil, reason }) => {
    try {
      await requestAccess({
        variables: {
          repoId: repo.id,
          request: {
            validFrom: new Date().toISOString(),
            userAccountId,
            comments: reason,
            validUntil: validUntil || null,
          },
        },
      })
      setFeedbackOpen(true)
    } catch (e) {
      console.error(e)
    }
  }

  const { repoType, repo, userAccountId, duration } = watch()
  const durationType = watch('duration.type')

  useEffect(() => {
    if (repoType) {
      refetch({ filters: { repoTypes: [repoType] } })
    }
  }, [repoType])

  useEffect(() => {
    if (!requestButtonEnabled) {
      setRequestButtonEnabled(
        data?.hasAccessRequestIntegration && data?.repoEdgesUserCanRequest?.length > 0
      )
    }
  }, [data, requestButtonEnabled])

  useEffect(() => {
    setIsRequestable(requestButtonEnabled)
  }, [requestButtonEnabled, setIsRequestable])

  useEffect(() => {
    const repoEdges = data?.repoEdgesUserCanRequest
    if (repoEdges) {
      setRequestableRepos(repoEdges)
    }
  }, [data])

  useEffect(() => {
    const repoEdges = data?.repoEdgesUserCanRequest
    if (repo?.id && repoEdges && !repoEdges.some(repoEdge => repoEdge.node.id === repo.id)) {
      setValue('repo', '')
      setValue('userAccountId', '')
    }
  }, [data, repo])

  const RequestAccessButtonWithMessaging = () => {
    const RequestAccessButton = (...props) => (
      <Button
        onClick={handleToggle}
        type="button"
        loading={firstLoading}
        disabled={!requestButtonEnabled}
        {...props}
      >
        Request Access
      </Button>
    )
    return !firstLoading && !requestButtonEnabled ? (
      <Tooltip placement={tooltipPlacement} title={<Typography variant="caption">Your admin has not configured Access Portal access requests.</Typography>}>
        <Box><RequestAccessButton /></Box>
      </Tooltip>
    ) : (
      <RequestAccessButton />
    )
  }

  return (
    <React.Fragment>
      <RequestAccessButtonWithMessaging />
      <Dialog
        open={open}
        onClose={handleOnClose}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            position: 'absolute',
            right: 0,
            margin: 0,
            top: 0,
            height: '100vh',
            minHeight: '100vh',
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            Request Access
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack
            sx={{ justifyContent: 'space-between', height: '100%' }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack spacing={4}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Request access to a data repository.
              </Typography>
              <Stack spacing={3}>
                <Box>
                  <FormControl variant="standard" sx={{ display: 'flex' }}>
                    <InputLabel id="repoType-select-label" htmlFor="repoType-select">
                      Repository Type
                    </InputLabel>

                    <Controller
                      name="repoType"
                      control={control}
                      render={({ field }) => (
                        <Select
                          labelId="repoType-select-label"
                          id="repoType-select"
                          options={supportedRepoTypes.map(repoType => {
                            return {
                              icon: <ServiceIcon type={repoType.typeName} />,
                              label: repoType.displayName,
                              value: repoType.typeName,
                            }
                          })}
                          {...field}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl variant="standard" sx={{ display: 'flex' }}>
                    <InputLabel id="repoName-select-label" htmlFor="repoName-select" required>
                      Repository Name
                    </InputLabel>

                    <Controller
                      onChange={([, data]) => data}
                      name="repo"
                      control={control}
                      render={({ field: { onChange, value, ...rest } }) => (
                        <Autocomplete
                          sx={{ mt: '26px' }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          labelId="repoName-select-label"
                          id="repoName-select"
                          ListboxComponent={InfiniteLoad}
                          ListboxProps={{
                            hasNextPage: data?.hasNextPage,
                            isNextPageLoading: loading,
                            getNextPage,
                            sx: {
                              '& li': {
                                typography: 'body2',
                              },
                            },
                          }}
                          options={requestableRepos.map(repoEdge => ({
                            icon: <ServiceIcon type={repoEdge.node.type} />,
                            label: repoEdge.node.name,
                            id: repoEdge.node.id,
                            repoEdge: repoEdge,
                          }))}
                          value={value}
                          onChange={(e, data) => {
                            setValue('userAccountId', '')
                            onChange(data)
                          }}
                          renderOption={(props, option) => (
                            <SelectOption
                              value={option.id}
                              icon={option.icon}
                              label={option.label}
                              selectSize="medium"
                              {...props}
                            />
                          )}
                          renderInput={params => {
                            return (
                              <Input
                                inputRef={params.InputProps.ref}
                                inputProps={{ ...params.inputProps, ...params.InputProps }}
                                endAdornment={params.InputProps.endAdornment}
                                {...params}
                                margin="normal"
                              />
                            )
                          }}
                          {...rest}
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl
                    variant="standard"
                    sx={{ display: 'flex' }}
                    disabled={!repo?.repoEdge}
                  >
                    <InputLabel id="dbAccount-select-label" htmlFor="dbAccount-select" required>
                      Database Account
                    </InputLabel>

                    <Controller
                      name="userAccountId"
                      control={control}
                      render={({ field }) => (
                        <Select
                          labelId="dbAccount-select-label"
                          id="dbAccount-select"
                          options={
                            repo?.repoEdge
                              ? repo.repoEdge.accessibleUserAccounts.edges.map(userAccountEdge => ({
                                  label: userAccountEdge.node.name,
                                  value: userAccountEdge.node.id,
                                }))
                              : []
                          }
                          {...field}
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                    />
                  </FormControl>
                </Box>
                <Stack spacing={1}>
                  <Box>
                    <FormControl component="fieldset" variant="standard">
                      <InputLabel component="legend" required>
                        How long do you need access?
                      </InputLabel>
                      <Controller
                        name="duration.type"
                        control={control}
                        render={({ field }) => (
                          <RadioGroup
                            row
                            aria-label="duration"
                            {...field}
                            sx={{
                              'legend + &': {
                                marginTop: 3,
                              },
                              gap: 8,
                            }}
                          >
                            <FormControlLabel
                              value="always"
                              control={<Radio size="small" disableRipple />}
                              label="Always"
                            />
                            <FormControlLabel
                              value="until"
                              control={<Radio size="small" disableRipple />}
                              label="Until:"
                            />
                          </RadioGroup>
                        )}
                        rules={{
                          required: true,
                        }}
                      />
                    </FormControl>
                  </Box>

                  {durationType === 'until' && (
                    <Stack>
                      <Controller
                        name="validUntil"
                        control={control}
                        render={({ field: { onChange, onBlur, value, name, ref } }) => {
                          return (
                            <DatePicker
                              onChange={onChange}
                              value={value}
                              sx={{
                                flex: 1,
                              }}
                              selectTime
                            />
                          )
                        }}
                      />
                    </Stack>
                  )}
                </Stack>

                <Box>
                  <FormControl component="fieldset" variant="standard" sx={{ display: 'flex' }}>
                    <InputLabel
                      sx={{
                        typography: 'h6',
                      }}
                      shrink
                      disableAnimation
                    >
                      Reason
                    </InputLabel>
                    <Input
                      id="description-input"
                      inputProps={{
                        ...register('reason'),
                      }}
                      multiline
                      rows={5}
                      placeholder="e.g. Approval for contract period."
                    />
                  </FormControl>
                </Box>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
              <Button variant="text" onClick={handleOnClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                loading={isSubmitting || requestLoading}
                disabled={!repo || !userAccountId || !duration}
              >
                Request Access
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      <RequestAccessFeedbackDialog open={feedbackOpen} handleOnClose={handleOnFeedbackClose} />
    </React.Fragment>
  )
}
