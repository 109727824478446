/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useState, useEffect, useRef, Fragment } from 'react'
import {
  withStyles,
  Tab,
  Tabs,
  Switch,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  DialogContentText,
  Typography,
  TextField,
  MenuItem,
} from '@material-ui/core'
import { useAuth } from '@jeeves/hooks'
import ExpressClient from '@jeeves/clients/express'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import { DockerGenContents } from '@jeeves/pages/Wrappers/Instructions/components/DockerGen'
import { CloudFormGenContents } from '@jeeves/pages/Wrappers/Instructions/components/CloudFormGen'
import { Helm3GenContents } from '@jeeves/pages/Wrappers/Instructions/components/Helm3Gen'
import { HelmGenContents } from '@jeeves/pages/Wrappers/Instructions/components/HelmGen'
import { BinariesGenContents } from '@jeeves/pages/Wrappers/Instructions/components/BinariesGen'
import { SingleContainerGenContents } from '@jeeves/pages/Wrappers/Instructions/components/SingleContainerGen'
import { TerraformGenContents } from '@jeeves/pages/Wrappers/Instructions/components/TerraformGen'
import { CustomGenContents } from '@jeeves/pages/Wrappers/Instructions/components/CustomGen'
import { ExpressInstallerGenContents } from '@jeeves/pages/Wrappers/Instructions/components/ExpressInstallerGen'
import { grey } from '@material-ui/core/colors'
import Loading from '@jeeves/components/Loading'

import * as lodash from 'lodash'

const styles = theme => ({
  formControl: {
    margin: '12px 0',
  },
  first: {
    marginTop: '0',
  },
  last: {
    marginBottom: '0',
  },
  formControlInput: {
    marginTop: '12px',
  },
  descriptionFormLabel: {
    fontSize: '1em',
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  deleteColor: {
    color: theme.palette.error.main,
  },
})

export const CloneDialog = withStyles(styles)(({ open, handleClose: onClose, wrapper, onSave }) => {
  const { id: sidecarId, name: sidecarName, repos } = wrapper
  const { deploymentMethod, ...sidecarProperties } = { ...wrapper.properties }
  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const { showError } = usePopup()
  const [logsOptions, setLogsOptions] = useState([])
  const [metricsOptions, setMetricsOptions] = useState([])
  const [sidecarNames, setSidecarNames] = useState(null)
  const [suggestedSidecarName, setSuggestedSidecarName] = useState(null)
  const [newDeploymentMethod, setNewDeploymentMethod] = useState('')
  const [finished, setFinished] = useState(false)
  const [cloned, setCloned] = useState(false)
  const [isGenerated, setIsGenerated] = useState(false)

  const handleClose = () => {
    onClose()
  }

  const isValidDeploymentMethod = deploymentMethod => deploymentMethod in DEPLOYMENT_METHODS

  const DEPLOYMENT_METHODS = lodash.pick(
    {
      'docker-compose': {
        label: 'Deploy a sidecar using Docker Compose',
        component: (
          <DockerGenContents
            newTemplate={!deploymentMethod}
            alreadyCreated
            saveProperties={!deploymentMethod}
            onSave={onSave}
            clone
            setCloned={setCloned}
            setFinished={setFinished}
            open={open}
            onClose={handleClose}
            logsOptions={[
              ...logsOptions,
              {
                id: 'default',
                label: 'None',
                type: 'docker_default',
                value: 'docker_default',
              },
            ]}
            metricsOptions={metricsOptions}
            sidecarName={sidecarName}
            sidecarId={sidecarId}
            repos={repos}
            suggestedSidecarName={suggestedSidecarName}
            {...sidecarProperties}
          ></DockerGenContents>
        ),
      },
      'cft-ec2': {
        label: 'Deploy a sidecar to AWS EC2 using CloudFormation',
        component: (
          <CloudFormGenContents
            newTemplate={!deploymentMethod}
            alreadyCreated
            saveProperties={!deploymentMethod}
            onSave={onSave}
            clone
            setCloned={setCloned}
            setFinished={setFinished}
            open={open}
            onClose={handleClose}
            logsOptions={[
              ...logsOptions,
              {
                id: 'cloudwatch',
                label: 'CloudWatch',
                value: 'cloudwatch',
                type: 'cloudwatch',
              },
            ]}
            metricsOptions={metricsOptions}
            sidecarName={sidecarName}
            sidecarId={sidecarId}
            repos={repos}
            suggestedSidecarName={suggestedSidecarName}
            {...sidecarProperties}
          ></CloudFormGenContents>
        ),
      },
      'tf-aws-ec2': {
        label: 'Deploy a sidecar to AWS EC2 using Terraform',
        component: (
          <TerraformGenContents
            newTemplate={!deploymentMethod}
            alreadyCreated
            saveProperties={!deploymentMethod}
            onSave={onSave}
            clone
            setCloned={setCloned}
            setFinished={setFinished}
            open={open}
            onClose={handleClose}
            logsOptions={[
              ...logsOptions,
              {
                id: 'cloudwatch',
                label: 'CloudWatch',
                value: 'cloudwatch',
                type: 'cloudwatch',
              },
            ]}
            metricsOptions={metricsOptions}
            sidecarName={sidecarName}
            sidecarId={sidecarId}
            repos={repos}
            suggestedSidecarName={suggestedSidecarName}
            {...sidecarProperties}
          ></TerraformGenContents>
        ),
      },
      helm: {
        label: 'Deploy a sidecar to Kubernetes cluster using Helm',
        component: (
          <Helm3GenContents
            newTemplate={!deploymentMethod}
            alreadyCreated
            saveProperties={!deploymentMethod}
            onSave={onSave}
            clone
            setCloned={setCloned}
            setFinished={setFinished}
            open={open}
            onClose={handleClose}
            logsOptions={[...logsOptions, { id: 'default', label: 'None', type: '', value: '' }]}
            metricsOptions={metricsOptions}
            sidecarName={sidecarName}
            sidecarId={sidecarId}
            repos={repos}
            suggestedSidecarName={suggestedSidecarName}
            {...sidecarProperties}
          ></Helm3GenContents>
        ),
      },
      linux: {
        label: 'Deploy a sidecar using a Linux package',
        component: (
          <BinariesGenContents
            newTemplate={!deploymentMethod}
            alreadyCreated
            saveProperties={!deploymentMethod}
            onSave={onSave}
            clone
            setCloned={setCloned}
            setFinished={setFinished}
            setIsGenerated={setIsGenerated}
            open={open}
            onClose={handleClose}
            logsOptions={[...logsOptions, { id: 'default', label: 'None', type: '', value: '' }]}
            metricsOptions={metricsOptions}
            sidecarName={sidecarName}
            sidecarId={sidecarId}
            repos={repos}
            suggestedSidecarName={suggestedSidecarName}
            {...sidecarProperties}
          ></BinariesGenContents>
        ),
      },
      'single-container': {
        label: 'Deploy a sidecar using a single container',
        component: (
          <SingleContainerGenContents
            newTemplate={!deploymentMethod}
            alreadyCreated
            saveProperties={!deploymentMethod}
            onSave={onSave}
            clone
            setCloned={setCloned}
            setFinished={setFinished}
            setIsGenerated={setIsGenerated}
            open={open}
            onClose={handleClose}
            logsOptions={[...logsOptions, { id: 'default', label: 'None', type: '', value: '' }]}
            metricsOptions={metricsOptions}
            sidecarName={sidecarName}
            sidecarId={sidecarId}
            repos={repos}
            suggestedSidecarName={suggestedSidecarName}
            {...sidecarProperties}
          ></SingleContainerGenContents>
        ),
      },
      'express-installer': {
        label: 'Deploy a sidecar using an express installer',
        component: (
          <ExpressInstallerGenContents
            newTemplate={!deploymentMethod}
            alreadyCreated
            saveProperties={!deploymentMethod}
            onSave={onSave}
            clone
            setCloned={setCloned}
            setFinished={setFinished}
            setIsGenerated={setIsGenerated}
            open={open}
            onClose={handleClose}
            logsOptions={[...logsOptions, { id: 'default', label: 'None', type: '', value: '' }]}
            metricsOptions={metricsOptions}
            sidecarName={sidecarName}
            sidecarId={sidecarId}
            repos={repos}
            suggestedSidecarName={suggestedSidecarName}
            {...sidecarProperties}
          ></ExpressInstallerGenContents>
        ),
      },
      custom: {
        label: 'Deploy a sidecar using a custom template',
        component: (
          <CustomGenContents
            newTemplate={!deploymentMethod}
            alreadyCreated
            saveProperties={!deploymentMethod}
            onSave={onSave}
            clone
            setCloned={setCloned}
            setFinished={setFinished}
            setIsGenerated={setIsGenerated}
            open={open}
            onClose={handleClose}
            logsOptions={[...logsOptions, { id: 'default', label: 'None', type: '', value: '' }]}
            metricsOptions={metricsOptions}
            sidecarName={sidecarName}
            sidecarId={sidecarId}
            repos={repos}
            suggestedSidecarName={suggestedSidecarName}
            {...sidecarProperties}
          ></CustomGenContents>
        ),
      },
    },
    window._env_.sidecar_deployment_options.split(',')
  )

  const getSidecarNames = async () => {
    try {
      const sidecars = await ec.get('/sidecars/unaltered').then(res => res.data)
      setSidecarNames(sidecars.map(sidecar => sidecar.sidecar.name))
    } catch (e) {
      showError('Error getting sidecars')
    }
  }

  useEffect(() => {
    const fetchIntegrationOptions = () => {
      try {
        ec.get('/integrations/logging').then(res => setLogsOptions(res.data))
        ec.get('/integrations/metrics').then(res => setMetricsOptions(res.data))
      } catch (e) {
        showError('Error while fetching integrations')
      }
    }
    fetchIntegrationOptions()
    getSidecarNames()
  }, [])

  useEffect(() => {
    if (sidecarNames) {
      let newName = sidecarName ? `${sidecarName}-clone` : ''
      let counter = 1
      while (sidecarNames.includes(newName)) {
        if (counter === 1) {
          newName = `${newName}1`
          counter = counter + 1
        } else {
          newName = `${newName.slice(0, newName.length - Math.ceil(Math.log10(counter)))}${counter}`
          counter = counter + 1
        }
      }
      setSuggestedSidecarName(newName)
    }
  }, [sidecarNames])

  useEffect(() => {
    if (open) setNewDeploymentMethod('')
  }, [open])

  useEffect(() => {
    if (cloned) {
      setSuggestedSidecarName(null)
      getSidecarNames()
      setCloned(false)
    }
  }, [cloned])

  useEffect(() => {
    if (finished) setFinished(false)
  }, [finished])

  return (
    <Dialog
      fullWidth
      maxWidth={
        ['linux', 'single-container', 'custom', 'express-installer'].includes(
          isValidDeploymentMethod(deploymentMethod) ? deploymentMethod : newDeploymentMethod
        ) && isGenerated
          ? 'md'
          : 'sm'
      }
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        <Typography variant="h6">Clone Sidecar</Typography>
        <Typography css={{ color: grey[600] }}>
          Clone this sidecar with its data repository bindings and the settings below.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div css={{ position: 'relative', minHeight: suggestedSidecarName ? '0' : '100px' }}>
          {!suggestedSidecarName ? (
            <Loading></Loading>
          ) : !isValidDeploymentMethod(deploymentMethod) && !newDeploymentMethod ? (
            <TextField
              select
              value={newDeploymentMethod}
              onChange={e => setNewDeploymentMethod(e.target.value)}
              label="Select a deployment method"
              fullWidth
              css={{ marginTop: '8px' }}
              variant="outlined"
            >
              {Object.entries(DEPLOYMENT_METHODS).map(([method, obj]) => (
                <MenuItem value={method}>{obj.label}</MenuItem>
              ))}
            </TextField>
          ) : (
            <Fragment>
              {!['linux', 'single-container', 'custom', 'express-installer'].includes(
                isValidDeploymentMethod(deploymentMethod) ? deploymentMethod : newDeploymentMethod
              ) &&
                !isValidDeploymentMethod(deploymentMethod) && (
                  <Typography css={{ marginBottom: '12px' }}>
                    {finished ? 'These settings have been saved.' : 'These settings will be saved.'}
                  </Typography>
                )}
              {
                DEPLOYMENT_METHODS[
                  isValidDeploymentMethod(deploymentMethod) ? deploymentMethod : newDeploymentMethod
                ].component
              }
            </Fragment>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
})
