/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@material-ui/core'
import { Edit as EditIcon } from '@material-ui/icons'
import { ActionButton, FlexGrow } from '@jeeves/components/Primitives'
import { ServiceIcon } from '@jeeves/components/ServiceIcon'

const DetailHeader = ({
  service,
  status,
  name,
  onEditClick,
  children,
  isSidecar,
  wrapper,
  actions,
  ...props
}) => (
  <div
    css={() => css`
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0 24px;
      margin: 16px 0;

      h1 {
        font-size: 24px;
      }
    `}
    {...props}
  >
    {service && (
      <ServiceIcon
        css={() => css`
          max-width: 50px;
          max-height: 30px;
          margin: 16px 16px 16px 0;
        `}
        type={service}
      />
    )}
    <FlexGrow>
      <div
        css={() => css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
        `}
      >
        <Typography css={{ marginRight: '8px' }} variant="h1">
          {name}
        </Typography>
        <FlexGrow />
        {actions
          ? actions
          : onEditClick && (
              <ActionButton onClick={onEditClick}>
                <EditIcon />
              </ActionButton>
            )}
      </div>
      {children}
    </FlexGrow>
  </div>
)

export default DetailHeader
