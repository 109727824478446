import { useState } from 'react'

const useFilters = () => {
  const [cloudFilter, setCloudFilter] = useState([])
  const [tagsFilter, setTagsFilter] = useState([])
  const [nameFilter, setNameFilter] = useState([])

  const filterName = sidecar => {
    if (!nameFilter || nameFilter.length === 0) return true
    return sidecar.sidecar.name.toLowerCase().includes(nameFilter.toLowerCase())
  }
  const filterCloud = sidecar => {
    // console.log('filter cloud: ', sidecar)
    if (!cloudFilter || cloudFilter.length === 0) return true
    return cloudFilter
      .map(cloud => cloud.toLowerCase())
      .includes(sidecar.sidecar.cloud.toLowerCase()) // labels[0] b/c express is returning array
  }

  const filterTags = sidecar => {
    if (!tagsFilter || tagsFilter.length === 0) return true // No filter set
    if (!sidecar.tags || sidecar.tags.length === 0) return false // No tags on repo

    // Iterate through the tags and check if any are applied to the repo.
    for (const tag of tagsFilter) {
      if (sidecar.tags.includes(tag)) return true
    }
    return false
  }

  return {
    filterName,
    filterCloud,
    filterTags,
    filterState: {
      cloudFilter,
      setCloudFilter,
      tagsFilter,
      setTagsFilter,
      nameFilter,
      setNameFilter,
    },
  }
}

export default useFilters
