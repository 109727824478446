import { isEqual } from 'lodash'
import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'

import { Box, ClickAwayListener, Fade, Popper, Stack } from '@mui/material'
import { FilterAlt } from '@mui/icons-material'

import { Button } from '@jeeves/new-components'
import { CyralUser, DateRange, EventTypeSelect } from './components'

import { useAuditFilter } from './useAuditFilter'

const AuditFilter = ({ filter, filterInitialState, handleFilter }) => {
  const { methods, anchorEl, open, handleOpen, handleClose, handleReset, onSubmit } =
    useAuditFilter(filterInitialState, handleFilter)

  const isFilterApplied = !isEqual(filter, filterInitialState)

  useEffect(() => {
    if (open) {
      methods.reset(filter)
    }
  }, [filter, methods, open])

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleOpen}
        startIcon={<FilterAlt />}
        sx={{
          '&.MuiButton-outlinedSecondary': theme => {
            if (isFilterApplied) {
              return {
                color: `${theme.palette.primary.main} !important`,
                borderColor: `${theme.palette.primary.main} !important`,
              }
            }
            return {}
          },
          '& .MuiButton-startIcon': {
            color: isFilterApplied ? 'primary.main' : 'cyralColors.grey.300',
          },
          '&:hover .MuiButton-startIcon': {
            color: 'primary.main',
          },
        }}
      >
        Filter
      </Button>
      <FormProvider {...methods}>
        <Popper open={open} anchorEl={anchorEl} placement="bottom-start" transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Box component="form" onSubmit={onSubmit}>
                <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseUp">
                  <Stack
                    spacing={6}
                    sx={{
                      backgroundColor: theme => theme.palette.common.white,
                      border: 1,
                      borderColor: 'cyralColors.grey.200',
                      borderRadius: theme => theme.radii.base,
                      boxShadow: theme => theme.cyralShadows['1dp'],
                      marginTop: 1,
                      padding: theme => theme.spacing(4, 4, 3, 4),
                      width: '502px',
                    }}
                  >
                    <Stack spacing={2}>
                      <EventTypeSelect />
                      <CyralUser />
                      <DateRange />
                    </Stack>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        borderTop: 1,
                        borderColor: 'cyralColors.grey.200',
                        paddingTop: 3,
                      }}
                    >
                      <Button
                        variant="text"
                        sx={{ color: 'cyralColors.grey.400' }}
                        onClick={handleReset}
                      >
                        Reset all filters
                      </Button>
                      <Stack direction="row" spacing={4}>
                        <Button
                          variant="text"
                          sx={{ color: 'cyralColors.grey.400' }}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button type="submit">Filter</Button>
                      </Stack>
                    </Box>
                  </Stack>
                </ClickAwayListener>
              </Box>
            </Fade>
          )}
        </Popper>
      </FormProvider>
    </>
  )
}
export default AuditFilter
