/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useEffect, useState, Fragment } from 'react'

import {
  IntegrationDialogContent,
  IntegrationList,
  IntegrationLoading,
  ZeroState,
} from '../../primitives'
import { useAuth } from '@jeeves/hooks'

import Integration from './components/Integration'
import IntegrationDraft from './components/IntegrationDraft'
import AddForm from './create/AddForm'

import NameDialog from './NameDialog'

import usePingOne from './hooks/usePingOne'
import logo from './assets/pingone.png'

const PingOne = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  const [isCreatingIntegration, setIsCreatingIntegration] = useState(false)
  const [openIntegration, setOpenIntegration] = useState({})
  const [newIntegrationId, setNewIntegrationId] = useState()
  const {
    updateIntegrations,
    updateDraftIntegrations,
    integrations,
    draftIntegrations,
    addIntegration,
    addDraftIntegration,
    putIntegration,
    deleteIntegration,
    deleteDraftIntegration,
  } = usePingOne({ refresh: async () => await fetchData() })
  const { hasPermission } = useAuth()

  const fetchData = async () => {
    setIsLoading(true)
    await updateIntegrations()
    await Promise.all([updateIntegrations(), updateDraftIntegrations()])
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Fragment>
      <IntegrationDialogContent>
        <IntegrationList css={{ marginBottom: '0' }}>
          {isLoading && <IntegrationLoading />}
          {!isLoading && !isCreatingIntegration && integrations.length === 0 && <ZeroState />}
          {!isLoading &&
            integrations.length > 0 &&
            integrations.map((integration, index) => (
              <Integration
                key={integration.id}
                integration={integration}
                putIntegration={putIntegration}
                deleteIntegration={deleteIntegration}
                last={draftIntegrations.length === 0 && index === integrations.length - 1}
              />
            ))}
          {!isLoading &&
            draftIntegrations.length > 0 &&
            draftIntegrations.map((integration, index) => (
              <IntegrationDraft
                setOpenIntegration={setOpenIntegration}
                openIntegration={openIntegration}
                addIntegration={addIntegration}
                setIsCreatingIntegration={setIsCreatingIntegration}
                key={integration.id}
                integration={integration}
                putIntegration={putIntegration}
                deleteIntegration={deleteDraftIntegration}
                isCreatingIntegration={isCreatingIntegration}
                last={index === draftIntegrations.length - 1}
                scrollToAndOpen={integration.id === newIntegrationId}
              />
            ))}
          {isCreatingIntegration && (
            <AddForm
              addDraftIntegration={addDraftIntegration}
              setOpenIntegration={setOpenIntegration}
              openIntegration={openIntegration}
              addIntegration={addIntegration}
              handleRemoveAddField={() => setIsCreatingIntegration(false)}
            />
          )}
        </IntegrationList>

        <NameDialog
          isOpen={open}
          value={name}
          setValue={setName}
          handleClose={() => {
            setOpen(false)
            setName('')
          }}
          handleAdd={async () => {
            const newIntegration = await addDraftIntegration({
              displayName: name,
              identityProvider: 'pingone',
            })
            setNewIntegrationId(newIntegration.draft?.alias)
          }}
        />
      </IntegrationDialogContent>
    </Fragment>
  )
}

PingOne.metadata = {
  name: 'PingOne',
  summary: 'Set up PingOne single sign-on to authenticate Cyral and data source users.',
  enabled: true,
  category: 'IDENTITY PROVIDER',
  slug: 'pingone',
  logo,
  isDeprecated: true,
}

export default PingOne
