import { get } from 'lodash'
import axios from 'axios'

export const getWrappers = async () => {
  // getWrapperMetrics()
  return axios
    .get('/api/wrappers')
    .then(res => res.data.Wrappers)
    .then(async wrapperArr => {
      console.log('wrapper array :' + JSON.stringify(wrapperArr))
      const newArr = wrapperArr.map(async wrapper => {
        // console.log('wrapper repos: ' + JSON.stringify(wrapper.Repos))
        const repoDetailsPromise = wrapper.Repos.sort(function(a, b) {
          var x = a.Name.toLowerCase()
          var y = b.Name.toLowerCase()
          if (x < y) {
            return -1
          }
          if (x > y) {
            return 1
          }
          return 0
        }).map(async repo => {
          console.log('repo Name: ' + repo.Name)
          return axios.get('/api/repo/' + repo.Name).then(res => res.data.Repo)
        })

        // console.log(repoDetailsPromise)
        const repoDetails = await axios.all(repoDetailsPromise)
        console.log('wrappers.js getWrappers repoDetails: ' + JSON.stringify(repoDetails))

        const newWrapper = { ...wrapper }

        repoDetails.map((repoDetail, i) => {
          try {
            newWrapper.Repos[i].RepoPort = repoDetail.RepoPort || null
            newWrapper.Repos[i].RepoHost = repoDetail.RepoHost || ''
            newWrapper.Repos[i].RepoType = repoDetail.Type || ''
          } catch (e) {
            console.log('repoDetails error ' + e)
          }

          // newWrapper.Repos[i].WrapperHost = wrapper.Listener.WrapperHost
          // newWrapper.Repos[i].WrapperPort = wrapper.Listener.WrapperPort
        })

        newWrapper.numOfRepos = wrapper.Repos.length
        newWrapper.status = 'online'
        // newWrapper.QPS = Math.floor(512 + Math.random() * 512)

        const wrapperRepoArray = wrapper.Repos
        newWrapper.anyRepoEnabled = getWrapperStatus(wrapperRepoArray)

        newWrapper.QPS = newWrapper.anyRepoEnabled ? await getWrapperMetrics(wrapper.Name) : 0

        return newWrapper
      })
      const data = await axios.all(newArr)
      // console.log('data: ' + JSON.stringify(data))
      return data
    })
}

const getWrapperStatus = wrapperRepoArray => {
  let wrapperStatus = false
  wrapperRepoArray.forEach(wrapperRepo => {
    if (wrapperRepo.Enabled === true) {
      wrapperStatus = true
      return wrapperStatus
    }
  })
  return wrapperStatus
}

const getWrapperMetrics = async wrapperName => {
  const grafanaLink = await axios.get('/api/grafana').then(res => res.data)
  const newLink = grafanaLink.slice(0, 40) + '9090/api/v1/query'
  console.log('Prometheus link: ' + newLink)

  const config = {
    headers: { 'Content-Type': 'application/json' },
    params: {
      query: `sum(rate(cyral_query_duration_count{job=~"${wrapperName.toLowerCase()}.*"}[1m])) by (job)`,
    },
    method: 'GET',
  }

  const wrapperMetrics = await axios
    .get(newLink, config)
    .then(res => res.data)
    .then(data => {
      console.log('metric data:' + JSON.stringify(data))
      return data
    })
    .then(data => data.data.result[0].value[1])
    .then(value => parseFloat(value))
    .catch(e => {
      console.log('getWrapperMetrics() error: ' + e)
      return 0
    })

  return wrapperMetrics
}

const getProperties = sidecar => {
  const propertyObject = get(sidecar, 'sidecar.properties') || {}

  return Object.entries(propertyObject).map(entry => entry.join(':'))
}

const getSidecarTags = sidecar => {
  const properties = getProperties(sidecar)
  const tags = get(sidecar, 'sidecar.labels') || []

  return [...tags, ...properties].sort().join(' ')
}

const desc = (a, b) => {
  const v1 = getSidecarTags(a)
  const v2 = getSidecarTags(b)

  if (v1 === undefined || v2 === undefined) {
    return 0
  }
  if (v2 < v1) {
    return -1
  }
  if (v2 > v1) {
    return 1
  }
  return 0
}

export const sortSidecarTags = order => (a, b) => (order === 'asc' ? -desc(a, b) : desc(a, b))
