import { css } from '@emotion/react'
import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import MenuList from '@material-ui/core/MenuList'
import NotificationsIcon from '@material-ui/icons/Notifications'
import Barchart from '@material-ui/icons/BarChart'
import VisibilityIcon from '@material-ui/icons/Visibility'

import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/hooks'
import { MenuItem } from './components/MenuItem'
import LogsMenu from './components/LogsMenu'

const FourthSection = ({ iconOnly }) => {
  const { hasPermission, getTokenSilently } = useAuth()
  const [metricsLink, setMetricsLink] = useState('')

  const ec = new ExpressClient(getTokenSilently)

  const generateLink = path => {
    if (!path) return null
    if (path.startsWith('http://') || path.startsWith('https://')) {
      return path
    }
    if (path.startsWith(':')) {
      return `${window.location.protocol}//${window.location.hostname}${path}`
    }
    if (path.startsWith('/')) {
      return `${window.location.origin}${path}`
    }
    return `${window.location.href}/${path}`
  }

  const fetchMetricsLink = async () => {
    try {
      const metricsLink = await ec.get('/api/grafana').then(result => result.data)
      setMetricsLink(generateLink(metricsLink))
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchMetricsLink()
  }, [])

  return (
    <MenuList>
      <MenuItem
        component={NavLink}
        to="/alerts"
        disabled={!hasPermission('policies:read')}
        iconOnly={iconOnly}
        primaryText="Alerts"
        listItemIcon={<NotificationsIcon fontSize="small" />}
      />

      <a
        target="_blank"
        rel="noopener noreferrer"
        href={!hasPermission('metrics:read') ? null : metricsLink}
        css={() => css`
          text-decoration: none;
        `}
      >
        <MenuItem
          disabled={!hasPermission('metrics:read') || metricsLink == null}
          onClick={() => document.activeElement.blur()}
          iconOnly={iconOnly}
          primaryText="Metrics"
          listItemIcon={<Barchart fontSize="small" />}
        />
      </a>

      <MenuItem
        component={NavLink}
        to="/cyralActivityLogs"
        disabled={!hasPermission('users:read')}
        iconOnly={iconOnly}
        primaryText="Audit"
        listItemIcon={<VisibilityIcon fontSize="small" />}
      />

      <LogsMenu iconOnly={iconOnly} primaryText="Query Logs"></LogsMenu>
    </MenuList>
  )
}

export default FourthSection
