/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useEffect, useState } from 'react'

// import AddForm from './AddForm'
import IntegrationForm from './IntegrationForm'
import NameDialog from './NameDialog'
import AddForm from './AddForm'
import IntegrationPanel from './IntegrationPanel'
import {
  AddIntegrationButton,
  IntegrationDialogContent,
  IntegrationList,
  IntegrationLoading,
  ZeroState,
} from '../../primitives'

import useForgeRock from './hooks/useForgeRock'
import logo from './forgerock.png'

const ForgeRock = () => {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(true)
  const [adding, setAdding] = useState(false)
  const {
    updateIntegrations,
    integrations,
    addIntegration,
    putIntegration,
    deleteIntegration,
    lodashGet,
    user,
  } = useForgeRock()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await updateIntegrations()
      setLoading(false)
    }

    fetchData()
  }, [])

  const hasPermissions = () => {
    return lodashGet(user, 'app_metadata.authorization.permissions', []).includes(
      'integrations:create'
    )
  }

  const getTenantName = () => {
    const getDomainSuffix = windowDomain => {
      const REGIONS = ['.us.auth0', '.eu.auth0', '.au.auth0'] // all possible regions per auth0 docs
      for (const region of REGIONS) {
        if (windowDomain.includes(region)) {
          return region
        }
      }
      return '.auth0' // No regions match, so assume default suffix
    }

    // Set default value to '<tenant>' if we cannot find the correct value
    const windowDomain = lodashGet(window, '_env_.domain', '<tenant>.auth0')
    const domainSuffix = getDomainSuffix(windowDomain)
    const tenantName = windowDomain.split(`${domainSuffix}`)[0]
    return tenantName
  }

  const getTenantNameWithRegion = () => {
    const windowDomain = lodashGet(window, '_env_.domain', '<tenant>.auth0')
    return windowDomain.split('.auth0')[0]
  }

  useEffect(() => {
    if (!loading && integrations.length === 0) {
      setOpen(true)
    }
  }, [loading])

  return (
    <IntegrationDialogContent>
      <IntegrationList>
        {loading && <IntegrationLoading />}
        {!loading && !adding && integrations.length === 0 && <ZeroState />}
        {!loading &&
          integrations.length > 0 &&
          integrations.map(integration => (
            <IntegrationForm
              key={integration.id}
              integration={integration}
              putIntegration={putIntegration}
              deleteIntegration={deleteIntegration}
              tenantName={getTenantName()}
              tenantNameWithRegion={getTenantNameWithRegion()}
            />
          ))}
        {adding && (
          <IntegrationPanel title={name} expanded={true}>
            <AddForm
              name={name}
              tenantNameWithRegion={getTenantNameWithRegion()}
              addIntegration={addIntegration}
              handleRemoveAddField={() => setAdding(false)}
              tenantName={getTenantName()}
            />
          </IntegrationPanel>
        )}
      </IntegrationList>

      <AddIntegrationButton
        isOpen={open}
        disabled={!hasPermissions() || adding}
        setOpen={() => setOpen(true)}
      />

      <NameDialog
        isOpen={open}
        value={name}
        setValue={setName}
        handleClose={() => setOpen(false)}
        handleAdd={() => {
          setAdding(true)
          setOpen(false)
        }}
      />
    </IntegrationDialogContent>
  )
}

ForgeRock.metadata = {
  name: 'Forgerock',
  summary: 'Set up Forgerock single sign-on to authenticate Cyral and data source users.',
  enabled: true,
  category: 'IDENTITY PROVIDER',
  slug: 'forgerock',
  logo,
}

export default ForgeRock
