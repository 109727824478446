/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState, useEffect } from 'react'
import { IconButton, Fade, Checkbox, FormControlLabel, Grid } from '@material-ui/core/'
import EditIcon from '@material-ui/icons/Edit'

import { ButtonLoading } from '@jeeves/components/Loading'
import { useAuth } from '@jeeves/components/Auth'
import { Button } from '@jeeves/components/Primitives'
import {
  DeleteIntegrationButton,
  IntegrationCard,
  IntegrationEditActions,
  IntegrationFieldList,
  IntegrationHeader,
  IntegrationTitle,
  Label,
  LabelValuePair,
  TextField,
  Value,
} from '../../../primitives'

const AddForm = ({ integration = null, onSave, onDelete, onCancel, creating = false }) => {
  const [name, setName] = useState('')
  const [URL, setURL] = useState('')
  const [options, setOptions] = useState({
    useTLS: false,
    useMutualAuthentication: false,
    usePrivateCertificateChain: false,
  })
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)
  const { hasPermission } = useAuth()

  const configureIntegration = () => {
    if (integration) {
      setName(integration.integration.name)
      setURL(integration.integration.endpoint)
      setOptions({
        useTLS: integration.integration.useTLS,
        useMutualAuthentication: integration.integration.useMutualAuthentication,
        usePrivateCertificateChain: integration.integration.usePrivateCertificateChain,
      })
    } else {
      setName('')
      setURL('')
      setOptions({
        useTLS: false,
        useMutualAuthentication: false,
        usePrivateCertificateChain: false,
      })
    }
  }

  const configureEditing = () => {
    if (creating) {
      setEditing(true)
    }
  }

  useEffect(() => {
    configureIntegration()
    configureEditing()
  }, []) // eslint-disable-line

  const handleCancelEditing = () => {
    setEditing(false)

    if (onCancel) {
      onCancel()
    }
  }

  const handleCheck = (option, checked) => {
    setOptions(prevObj => ({ ...prevObj, [option]: checked }))
  }

  const handleOnSave = async () => {
    setSaving(true)
    try {
      await onSave({
        name: name,
        endpoint: URL,
        ...options,
      })
    } catch (e) {
      console.log({ e })
    } finally {
      setSaving(false)
      setEditing(false)
    }
  }

  return (
    <IntegrationCard>
      {!editing && (
        <Fade in={!editing}>
          <div>
            <IntegrationHeader>
              <IntegrationTitle gutterBottom component="h2">
                {name}
              </IntegrationTitle>
              <div css={{ flexGrow: 1 }} />
              <IconButton
                onClick={() => setEditing(true)}
                disabled={!hasPermission('integrations:update')}
              >
                <EditIcon />
              </IconButton>
              <DeleteIntegrationButton
                onDelete={onDelete}
                disabled={!hasPermission('integrations:delete')}
              />
            </IntegrationHeader>
            <LabelValuePair>
              <Label>Endpoint</Label>
              <Value>{URL}</Value>
            </LabelValuePair>
            <LabelValuePair>
              <Label>TLS</Label>
              <Value>{options.useTLS ? 'Enabled' : 'Disabled'}</Value>
            </LabelValuePair>
            <LabelValuePair>
              <Label>Mutual Authentication</Label>
              <Value>{options.useMutualAuthentication ? 'Enabled' : 'Disabled'}</Value>
            </LabelValuePair>
            <LabelValuePair>
              <Label>Private Certificate Chain</Label>
              <Value>{options.usePrivateCertificateChain ? 'Enabled' : 'Disabled'}</Value>
            </LabelValuePair>
          </div>
        </Fade>
      )}
      {editing && (
        <Fade in={editing}>
          <div>
            <Grid container spacing={24} direction="row" justify="space-between">
              <Grid container item direction="column" xs={8}>
                <Grid item>
                  <TextField
                    value={name}
                    label="Integration Name"
                    onChange={e => setName(e.target.value)}
                    autofocus
                  />
                </Grid>
                <Grid item>
                  <TextField value={URL} label="Endpoint" onChange={e => setURL(e.target.value)} />
                </Grid>
              </Grid>
              <Grid container item direction="column" xs={4}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedB"
                        color="primary"
                        checked={options.useTLS}
                        onChange={e => handleCheck('useTLS', e.target.checked)}
                      />
                    }
                    label="TLS"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedB"
                        color="primary"
                        checked={options.useMutualAuthentication}
                        onChange={e => handleCheck('useMutualAuthentication', e.target.checked)}
                      />
                    }
                    label="Mutual Authentication"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedB"
                        color="primary"
                        checked={options.usePrivateCertificateChain}
                        onChange={e => handleCheck('usePrivateCertificateChain', e.target.checked)}
                      />
                    }
                    label="Private Certificate Chain"
                  />
                </Grid>
              </Grid>
            </Grid>
            <IntegrationEditActions>
              <Button onClick={handleCancelEditing} color="primary" disabled={saving}>
                Cancel
              </Button>
              <div css={theme => ({ position: 'relative', marginLeft: theme.spacing[5] })}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={saving}
                  onClick={handleOnSave}
                >
                  Save
                </Button>
                {saving && <ButtonLoading />}
              </div>
            </IntegrationEditActions>
          </div>
        </Fade>
      )}
    </IntegrationCard>
  )
}

export default AddForm
