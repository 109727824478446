/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState } from 'react'
import { Fade, FormControlLabel, Switch } from '@material-ui/core/'

import { useMount } from '@jeeves/hooks'
import { Button } from '@jeeves/components/Primitives'
import { ButtonLoading } from '@jeeves/components/Loading'
import {
  IntegrationCard,
  IntegrationEditActions,
  IntegrationFieldList,
  TextField,
} from '../../../primitives'
import { ViewForm } from '.'
import { useFormik } from 'formik'
import { get } from 'lodash'

export const IntegrationForm = props => {
  const { integration = {}, onSave, onDelete, onCancel, creating = false } = props
  const [editing, setEditing] = useState(false)

  const { handleSubmit, isSubmitting, getFieldProps, setFieldValue, values, ...formik } = useFormik(
    {
      initialValues: {
        name: get(integration, 'integration.name', ''),
        accessToken: get(integration, 'integration.accessToken', ''),
        hecPort: get(integration, 'integration.hecPort', ''),
        host: get(integration, 'integration.host', ''),
        index: get(integration, 'integration.index', ''),
        useTLS: get(integration, 'integration.useTLS', false),
      },
      onSubmit: async values => {
        if (creating) {
          await onSave(values)
        } else {
          await onSave(integration.id, values)
        }

        if (integration) {
          setEditing(false)
        }
      },
    }
  )

  useMount(() => {
    if (creating) {
      setEditing(true)
    }
  })

  const handleCancelEditing = () => {
    formik.resetForm()

    if (integration) {
      setEditing(false)
    }

    if (onCancel) {
      onCancel()
    }
  }

  const handleDelete = () => {
    onDelete(integration.id)
  }

  return (
    <IntegrationCard>
      {!editing && !creating && (
        <Fade in={!editing}>
          <div>
            <ViewForm
              integration={integration}
              editing={editing}
              setEditing={setEditing}
              onDelete={handleDelete}
            />
          </div>
        </Fade>
      )}

      {editing && (
        <Fade in={editing}>
          <div>
            <IntegrationFieldList>
              <TextField fullWidth label="Name" {...getFieldProps('name')} />
              <TextField fullWidth label="Host" {...getFieldProps('host')} />
              <TextField
                fullWidth
                label="HTTP Event Collector Port"
                {...getFieldProps('hecPort')}
              />
              <TextField fullWidth label="Access Token" {...getFieldProps('accessToken')} />
              <TextField fullWidth label="Index" {...getFieldProps('index')} />
              <FormControlLabel
                label="TLS"
                control={
                  <Switch
                    {...getFieldProps('useTLS')}
                    onChange={() => setFieldValue('useTLS', !values.useTLS)}
                    color="primary"
                    checked={values.useTLS}
                  />
                }
              />
            </IntegrationFieldList>
            <IntegrationEditActions>
              <Button onClick={handleCancelEditing} color="primary" disabled={isSubmitting}>
                Cancel
              </Button>
              <div css={theme => ({ position: 'relative', marginLeft: theme.spacing[5] })}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
                {isSubmitting && <ButtonLoading />}
              </div>
            </IntegrationEditActions>
          </div>
        </Fade>
      )}
    </IntegrationCard>
  )
}
