import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

import { InputLabel, Input, LearnMore } from '@jeeves/new-components'

import { MYSQL_FAMILY_DEFAULT_ADVERTISED_VERSIONS } from '../../../utils'

const MySQLFamilyMultiplexedListenerConfig = ({ listenerRepoType }) => {
  const {
    setValue,
    watch,
    register,
    formState: { errors },
  } = useFormContext()

  const selectedListener = watch('port')

  React.useEffect(() => {
    // Resets the advertised version when the selected port changes
    setValue(
      'mysqlFamilyConfig.advertisedVersion',
      MYSQL_FAMILY_DEFAULT_ADVERTISED_VERSIONS[listenerRepoType] || ''
    )
  }, [selectedListener.port, setValue, listenerRepoType])

  const error = errors?.mysqlFamilyConfig?.advertisedVersion

  return (
    <Box
      sx={{
        border: 1,
        borderColor: 'cyralColors.grey.200',
        borderRadius: theme => theme.radii.base,
        px: 3,
        py: 2,
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h4" sx={{ color: 'text.secondary' }}>
          Smart Port Configuration
        </Typography>

        <Box>
          <FormControl variant="standard" error={Boolean(error)} fullWidth>
            <InputLabel htmlFor="advertised-version-input">Advertised Version</InputLabel>
            <Input
              id="advertised-version-input"
              inputProps={{
                ...register('mysqlFamilyConfig.advertisedVersion', {
                  required: 'This field is required.',
                  shouldUnregister: true,
                }),
              }}
            />
            <FormHelperText
              sx={{
                typography: 'body2',
              }}
            >
              {error?.message ?? (
                <React.Fragment>
                  The sidecar will advertise this server version to clients.{' '}
                  <LearnMore docsPath="sidecars/sidecar-bind-repo#ports-for-mysql-and-mariadb" />
                </React.Fragment>
              )}
            </FormHelperText>
          </FormControl>
        </Box>
      </Stack>
    </Box>
  )
}

export default MySQLFamilyMultiplexedListenerConfig
