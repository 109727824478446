
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AccessRestriction": [
      "DuoIntegration",
      "PagerdutyIntegration"
    ],
    "Approval": [
      "GrantedApproval",
      "PendingAmendment",
      "PendingApproval",
      "RejectedApproval",
      "RevokedApproval"
    ],
    "Binding": [
      "ClusterBinding",
      "S3Binding",
      "SingleListenerBinding"
    ],
    "ClusterRepo": [
      "MongoDBReplicaSetRepo",
      "MongoDBShardedCluster"
    ],
    "ConfExtensionInstance": [
      "ConnectionDriverInstance",
      "OtherConfExtensionInstance"
    ],
    "DatabaseAccount": [
      "NormalDiscoveredAccount",
      "OracleDiscoveredAccount",
      "SQLServerLoginDiscoveredAccount",
      "SQLServerUserDiscoveredAccount",
      "StandardDatabaseAccount"
    ],
    "DiscoveredAccount": [
      "NormalDiscoveredAccount",
      "OracleDiscoveredAccount",
      "SQLServerLoginDiscoveredAccount",
      "SQLServerUserDiscoveredAccount"
    ],
    "EnhancedAuditLogSubject": [
      "APIClient",
      "EnhancedAuditLogUser"
    ],
    "Field": [
      "Attribute",
      "RESTEndpoint"
    ],
    "GenericSAMLIntegration": [
      "GenericSAMLIntegrationConfigured",
      "GenericSAMLIntegrationDraft"
    ],
    "Identity": [
      "ControlPlaneUser",
      "SSOGroup",
      "UnregisteredUser"
    ],
    "Listener": [
      "MySQLFamilyMultiplexedListener",
      "NormalMultiplexedListener",
      "NormalNonMultiplexedListener",
      "S3Listener"
    ],
    "MongoDBCluster": [
      "MongoDBReplicaSetRepo",
      "MongoDBShardedCluster"
    ],
    "MongoDBClusterSpecification": [
      "SRVRecordSpecification",
      "StaticNodeSpecification"
    ],
    "MongoDBRepo": [
      "MongoDBReplicaSetRepo",
      "MongoDBShardedCluster",
      "MongoDBStandaloneRepo"
    ],
    "MultiplexedListener": [
      "MySQLFamilyMultiplexedListener",
      "NormalMultiplexedListener"
    ],
    "NonChildApproval": [
      "GrantedApproval",
      "PendingApproval",
      "RejectedApproval",
      "RevokedApproval"
    ],
    "NonMultiplexedListener": [
      "NormalNonMultiplexedListener",
      "S3Listener"
    ],
    "Policy": [
      "DataFirewallPolicy",
      "DataMaskingPolicy",
      "DataProtectionPolicy",
      "RateLimitPolicy",
      "ReadLimitPolicy",
      "RepositoryProtectionPolicy",
      "ServiceAccountAbusePolicy",
      "UserSegmentationPolicy"
    ],
    "PolicyIdentity": [
      "PolicyEmailIdentity",
      "PolicyGroupIdentity",
      "PolicyUsernameIdentity"
    ],
    "PolicyModifiedScope": [
      "PolicyDatabaseAccountModifiedScope",
      "PolicyIdentityModifiedScope"
    ],
    "PolicyParameters": [
      "DataFirewallPolicyParameters",
      "DataMaskingPolicyParameters",
      "DataProtectionPolicyParameters",
      "RateLimitPolicyParameters",
      "ReadLimitPolicyParameters",
      "RepositoryProtectionPolicyParameters",
      "ServiceAccountAbusePolicyParameters",
      "UserSegmentationPolicyParameters"
    ],
    "PolicyParametersWithAction": [
      "DataProtectionPolicyParameters",
      "RateLimitPolicyParameters",
      "ReadLimitPolicyParameters",
      "ServiceAccountAbusePolicyParameters"
    ],
    "PolicyParametersWithModifiedScope": [
      "DataMaskingPolicyParameters",
      "DataProtectionPolicyParameters",
      "RateLimitPolicyParameters",
      "ReadLimitPolicyParameters",
      "RepositoryProtectionPolicyParameters"
    ],
    "Repo": [
      "DenodoRepo",
      "MongoDBReplicaSetRepo",
      "MongoDBShardedCluster",
      "MongoDBStandaloneRepo",
      "MySQLRepo",
      "NormalRepo",
      "OracleRepo",
      "PostgreSQLRepo",
      "RESTRepo",
      "RedshiftRepo",
      "SQLServerRepo",
      "SnowflakeRepo"
    ],
    "RepoAccountCredentials": [
      "AWSIAMRepoAccountCredentials",
      "AWSSecretsManagerRepoAccountCredentials",
      "CyralStorageRepoAccountCredentials",
      "EnvironmentVariableRepoAccountCredentials",
      "GCPSecretManagerRepoAccountCredentials",
      "HashicorpVaultRepoAccountCredentials",
      "KubernetesSecretRepoAccountCredentials"
    ],
    "RepoWithNetworkShieldField": [
      "OracleRepo",
      "SQLServerRepo"
    ],
    "RepoWithNormalSSOFields": [
      "DenodoRepo",
      "MySQLRepo",
      "NormalRepo",
      "OracleRepo",
      "PostgreSQLRepo",
      "RedshiftRepo",
      "SQLServerRepo"
    ],
    "RepoWithSSOFields": [
      "DenodoRepo",
      "MongoDBReplicaSetRepo",
      "MongoDBShardedCluster",
      "MongoDBStandaloneRepo",
      "MySQLRepo",
      "NormalRepo",
      "OracleRepo",
      "PostgreSQLRepo",
      "RedshiftRepo",
      "SQLServerRepo"
    ],
    "RepoWithServiceAccountsField": [
      "DenodoRepo",
      "MySQLRepo",
      "PostgreSQLRepo",
      "RedshiftRepo",
      "SQLServerRepo",
      "SnowflakeRepo"
    ],
    "SCIMIntegration": [
      "SCIMIntegrationWithClientSecret",
      "SCIMIntegrationWithoutClientSecret"
    ],
    "SIEMIntegration": [
      "DatadogIntegration",
      "ELKIntegration",
      "SplunkIntegration",
      "SumologicIntegration"
    ],
    "SQLServerDiscoveredAccount": [
      "SQLServerLoginDiscoveredAccount",
      "SQLServerUserDiscoveredAccount"
    ],
    "StandaloneRepo": [
      "DenodoRepo",
      "MongoDBStandaloneRepo",
      "MySQLRepo",
      "NormalRepo",
      "OracleRepo",
      "PostgreSQLRepo",
      "RESTRepo",
      "RedshiftRepo",
      "SQLServerRepo",
      "SnowflakeRepo"
    ],
    "User": [
      "ControlPlaneUser",
      "UnregisteredUser"
    ],
    "UserAccount": [
      "MongoDBUserAccount",
      "NormalUserAccount"
    ],
    "UserAccountCredentials": [
      "AWSIAMUserAccountCredentials",
      "AWSSecretsManagerUserAccountCredentials",
      "CyralStorageUserAccountCredentials",
      "EnvironmentVariableUserAccountCredentials",
      "GCPSecretManagerUserAccountCredentials",
      "HashicorpVaultUserAccountCredentials",
      "KubernetesSecretUserAccountCredentials"
    ],
    "UserSegmentationScope": [
      "UserSegmentationDatabaseAccountScope",
      "UserSegmentationIdentityScope"
    ],
    "Watch": [
      "CassandraWatch",
      "MongoDBWatch",
      "S3Watch",
      "SQLWatch"
    ]
  }
};
      export default result;
    