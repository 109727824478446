import { useParams } from 'react-router-dom'
import { useMutation, useFragment_experimental, gql } from '@apollo/client'

import { repoSupportsPortMultiplexing } from '../../../BindDataRepo/BindDataRepoDialog/utils'

import { useRepositoriesContext } from '../../../../contexts/RepositoriesContext'

import { useBindingsTableContext } from '../../BindingsTable/BindingsTableContext'
import { isClusterRepo } from '@jeeves/graphql/utils'

const CONFIGURE_MULTIPLEXED_LISTENER_SETTINGS = gql`
  mutation ConfigureMultiplexedListenerSettings(
    $sidecarId: ID!
    $listenerId: ID!
    $input: UpsertMultiplexListenerSettingsInput!
  ) {
    upsertMultiplexedListenerConfig(sidecarId: $sidecarId, listenerId: $listenerId, input: $input) {
      listener {
        id
        ... on MySQLFamilyMultiplexedListener {
          config {
            advertisedVersion
          }
        }
      }
    }
  }
`

const UPDATE_BINDING_PORTS = gql`
  mutation UpdateBindingPorts($sidecarId: ID!, $bindingId: ID!, $input: BindRepoToSidecarInput!) {
    updateBindingPorts(sidecarId: $sidecarId, bindingId: $bindingId, input: $input) {
      binding {
        id
        ... on SingleListenerBinding {
          listener {
            id
            port
          }
        }
        ... on S3Binding {
          listenerSet {
            proxyListener {
              id
              port
            }
            browserListener {
              id
              port
            }
          }
        }
        ... on ClusterBinding {
          boundListenersRelationship {
            edges {
              node {
                id
                port
              }
            }
          }
        }
      }
    }
  }
`

const TOGGLE_BINDING = gql`
  mutation ToggleBinding($sidecarId: ID!, $bindingId: ID!, $enabled: Boolean!) {
    toggleBinding(sidecarId: $sidecarId, bindingId: $bindingId, enabled: $enabled) {
      binding {
        id
        enabled
      }
    }
  }
`

const useEditBindingDialog_bindingFragment = gql`
  fragment useEditBindingDialog_binding on Binding {
    repo {
      id
      type
    }
  }
`

const useEditBindingDialog = (binding, dirtyFields) => {
  const { id: sidecarId } = useParams()
  const {
    data: { repo: selectedRepo },
  } = useFragment_experimental({
    fragment: useEditBindingDialog_bindingFragment,
    fragmentName: 'useEditBindingDialog_binding',
    from: binding,
  })
  const { refetch } = useRepositoriesContext()
  const { paginate } = useBindingsTableContext()
  const [updateBindingPortsMutation, { loading: loadingUpdateBindingPorts }] =
    useMutation(UPDATE_BINDING_PORTS)
  const [toggleBindingMutation, { loading: loadingToggleBinding }] = useMutation(TOGGLE_BINDING)
  const [
    configureMultiplexedListenerSettings,
    { loading: loadingConfigureMultiplexedListenerSettings },
  ] = useMutation(CONFIGURE_MULTIPLEXED_LISTENER_SETTINGS)

  const getInput = submittedData => {
    const isSelectClusterRepo = isClusterRepo(selectedRepo.__typename)

    if (selectedRepo.type === 's3') {
      const port = Number(submittedData.proxyPort)
      const browserPort = submittedData?.browserPort
      const enableS3Browser = submittedData?.enableS3Browser

      return {
        s3Repo: {
          port,
          ...(browserPort && enableS3Browser && { browserPort: Number(browserPort) }),
        },
      }
    } else if (isSelectClusterRepo) {
      const sidecarPorts = submittedData.ports.map(port => Number(port))

      return {
        clusterRepo: {
          sidecarPorts,
        },
      }
    } else {
      const port = Number(
        repoSupportsPortMultiplexing(selectedRepo.type)
          ? submittedData.port.port
          : submittedData.port
      )

      return {
        standaloneRepo: {
          port,
        },
      }
    }
  }

  const updateBindingPort = async submittedData => {
    const variables = {
      sidecarId,
      bindingId: submittedData.bindingId,
      input: getInput(submittedData),
    }

    await updateBindingPortsMutation({
      variables,
    })
  }

  const toggleBinding = async submittedData => {
    const variables = {
      sidecarId,
      bindingId: submittedData.bindingId,
      enabled: submittedData.enabled,
    }

    await toggleBindingMutation({
      variables,
    })
  }

  const onSubmit = async submittedData => {
    const updatePortFields = ['proxyPort', 'browserPort', 'ports', 'port', 'enableS3Browser']
    const updatePortFieldsAreDirty = Object.entries(dirtyFields)
      .filter(([key, _]) => updatePortFields.includes(key))
      .some(([_, val]) => val)

    if (updatePortFieldsAreDirty) {
      await updateBindingPort(submittedData)
    }
    if (dirtyFields.enabled) {
      await toggleBinding(submittedData)
    }

    if (dirtyFields.mysqlFamilyConfig) {
      await configureMultiplexedListenerSettings({
        variables: {
          sidecarId,
          listenerId: submittedData.port.id,
          input: {
            mysqlFamilyConfig: {
              ...submittedData.mysqlFamilyConfig,
            },
          },
        },
      })
    }

    refetch()
    paginate({ refetchCurrent: true })
  }

  return {
    onSubmit,
    loading:
      loadingToggleBinding ||
      loadingUpdateBindingPorts ||
      loadingConfigureMultiplexedListenerSettings,
  }
}

useEditBindingDialog.fragments = {
  useEditBindingDialog_bindingFragment,
}

export default useEditBindingDialog
