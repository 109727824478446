import Slack from './Slack'
import SlackBot from './SlackBot'
import ELK from './ELK'
import Logstash from './Logstash'
import Teams from './Teams'
import Datadog from './Datadog'
import Okta from './Okta'
import HashiCorpVault from './HashiCorpVault'
import Hive from './Hive'
import Splunk from './Splunk'
import AzureAD from './AzureAD'
import GSuite from './GSuite'
import Pagerduty from './Pagerduty'
import SumoLogic from './SumoLogic'
import Forgerock from './Forgerock'
import ADFS from './ADFS'
import PingOne from './PingOne'
import GenericSAML from './GenericSAML'
import Duo from './Duo'

export const rawServices = [
  Slack,
  SlackBot,
  ELK,
  Logstash,
  Teams,
  Datadog,
  Okta,
  HashiCorpVault,
  Hive,
  Splunk,
  AzureAD,
  GSuite,
  Pagerduty,
  SumoLogic,
  Forgerock,
  ADFS,
  PingOne,
  GenericSAML,
  Duo,
]

// These services are intended to be hidden from Free Trial customers
const nonFreeTrialServices = [Hive, SumoLogic, AzureAD, ADFS]

const enabledServices = rawServices.filter(service => service.metadata.enabled)

export const Services = enabledServices

export const FreeTrialServices = enabledServices.filter(service => {
  const nonFTServiceNames = nonFreeTrialServices.map(service => service.metadata.name)
  return !nonFTServiceNames.includes(service.metadata.name)
})
