/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'

import { Tag } from '@jeeves/components/Tag'

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
`

const Value = styled(Typography)`
  color: #000022;
`

export const WrapperInfo = ({ wrapper }) => {
  const { labels, properties } = wrapper

  const propertiesList = properties
    ? Object.entries(properties)
        .filter(([k, v]) => v)
        .map(([k, v], i) => `${k}:${v}`)
    : []
  const labelsList = labels || []
  let tags = [...propertiesList.sort(), ...labelsList.sort()]
  tags = tags.filter(
    tag =>
      tag.charAt(0) !== '_' &&
      tag.charAt(0) !== '_' &&
      // !tag.includes('deploymentMethod:') &&
      !tag.includes('logIntegrationID:') &&
      !tag.includes('metricsIntegrationID:') &&
      !tag.includes('awsRegion:') &&
      !tag.includes('vpc:') &&
      !tag.includes('publiclyAccessible:') &&
      !tag.includes('publicSubnets:') &&
      !tag.includes('keyName:')
  ) //TODO: fix how we filter _cyral-sandbox and cyral-set properties

  return (
    <Flex
      css={() => css`
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding-bottom: 40px;
      `}
    >
      {tags.length > 0 ? (
        tags.map(tag => (
          <Tag css={{ margin: '0 5px 0 0' }} key={`${tag}-row-tag`}>
            {tag}
          </Tag>
        ))
      ) : (
        <Value css={{ fontStyle: 'italic' }}>No tags</Value>
      )}
    </Flex>
  )
}
