/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useState, useEffect } from 'react'
import { useLocalStorage } from 'react-use'
import { ThemeProvider } from '@mui/material/styles'
import { Paper } from '@material-ui/core'
import { useQuery, gql } from '@apollo/client'

import EventTimeline from '@jeeves/components/EventTimeline'
import Toolbar from '@jeeves/components/Toolbar'
import TimeSpanSelector from './TimeSpanSelector'
import Downloader from './Downloader'
import useAuditLogs from './hooks/useAuditLogs'
import { v5Theme } from '@jeeves/theme'
import SIEM from './SIEM/SIEM'
import { Button } from '@jeeves/components/Primitives'

const SIEM_QUERY = gql`
  query SIEMQuery {
    SIEMIntegrations {
      cyralActivityLogsEnabled
      id
      name
      type
    }
  }
`

const AuditLogs = () => {
  const { fetchAuditLogs, fetchUserConnections } = useAuditLogs()
  const [auditLogs, setAuditLogs] = useState([])
  const [userConnections, setUserConnections] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState()
  const [timeSpan, setTimeSpan] = useLocalStorage('auditLogTimeSpan', '15_MINS')

  const [page, setPage] = useState(0)
  const [nextPageExists, setNextPageExists] = useState(false)

  const [SIEMOpen, setSIEMOpen] = useState(false)

  const { loading: SIEMLoading, error: SIEMError, data: SIEMData } = useQuery(SIEM_QUERY)

  const onClickForward = () => {
    setPage(oldPage => oldPage + 1)
  }

  const onClickBackward = () => {
    setPage(oldPage => oldPage - 1)
  }

  useEffect(() => {
    try {
      fetchUserConnections().then(res => {
        setUserConnections(res)
      })
    } catch (e) {}
  }, [])

  useEffect(() => {
    setIsLoading(true)

    fetchAuditLogs(timeSpan, page)
      .then(res => {
        setNextPageExists(!!res?.nextPageToken)
        setAuditLogs(res?.logs ?? [])
      })
      .catch(e => {
        setError(e)
        console.error(e)
      })

    setIsLoading(false)
  }, [timeSpan, page])

  if (isLoading) return null

  return (
    <Paper css={{ paddingBottom: '2rem' }}>
      <Toolbar
        title="Audit"
        subtitle="These audit logs track activity on the Cyral Management Console."
        css={{ marginLeft: '1rem', marginBottom: '1rem' }}
      >
        <div css={{ display: 'flex', alignItems: 'flex-end' }}>
          <Button variant="contained" onClick={() => setSIEMOpen(true)}>
            Configure Log Forwarding
          </Button>

          <div css={{ marginRight: '12px', marginLeft: '12px' }}>
            <Downloader auditLogs={auditLogs} />
          </div>
          <TimeSpanSelector timeSpan={timeSpan} setTimeSpan={setTimeSpan} />
        </div>
      </Toolbar>
      {SIEMData && (
        <ThemeProvider theme={v5Theme}>
          <SIEM
            open={SIEMOpen}
            setOpen={setSIEMOpen}
            SIEMIntegrations={SIEMData.SIEMIntegrations}
          />
        </ThemeProvider>
      )}

      <EventTimeline
        page={page}
        setPage={setPage}
        onClickBackward={onClickBackward}
        onClickForward={onClickForward}
        nextPageExists={nextPageExists}
        rowsPerPage={25}
      >
        {error ? (
          <EventTimeline.ErrorRow>
            There was a problem loading the audit logs.
          </EventTimeline.ErrorRow>
        ) : auditLogs.length === 0 ? (
          <EventTimeline.EmptyEventsRow>
            There are no audit logs for this time period.
          </EventTimeline.EmptyEventsRow>
        ) : (
          auditLogs.map(log => {
            return (
              <EventTimeline.EventRow
                key={log.timestamp}
                eventType="general"
                event={log}
                userConnections={userConnections}
              />
            )
          })
        )}
      </EventTimeline>
    </Paper>
  )
}

export default AuditLogs
